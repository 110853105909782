import React from 'react';
import Box from '@mui/material/Box';
import Fab from '@mui/material/Fab';
import { setLayerSelectorOpened, useLayerStore } from '../../stores/layers';
import { useLayers } from '../../hooks';
import { getViewExtent } from '../../stores/view';
import * as features from '../../features';
import can from '../../utils/duckTyping';
import { isMobile } from 'react-device-detect';
import LayersIcon from '@mui/icons-material/Layers';
import { Add } from '@mui/icons-material';

export default function Layers() {
  const { vectorLayers } = useLayers();
  const stateLayerSelectorOpened = useLayerStore((state) => state.selectorOpened);
  const keys = Object.keys(features);

  return (
    <Box
      sx={{
        zIndex: 2,
        '& > :not(style)': { marginLeft: '10px' },
        position: 'absolute',
        top: 'calc(env(safe-area-inset-top, 0px) + 16px)',
        marginLeft: !isMobile ? '320px' : '0px',
        right: 'calc(env(safe-area-inset-right, 0px) + 16px)',
        textAlign: 'end',
        display: !isMobile ? 'inline-block' : 'flex',
        flexDirection: !isMobile ? 'unset' : 'column',
        alignItems: !isMobile ? 'center' : 'flex-end',
      }}
    >
      {!isMobile &&
        vectorLayers.map((l) => {
          return keys.map((key) => {
            if (
              features[key].id === l.get('id') &&
              (features[key].props.quickAccess || l.get('visible'))
            ) {
              return (
                <Fab
                  sx={{ textTransform: 'none' }}
                  key={l.get('id')}
                  variant="extended"
                  size="small"
                  color={l.get('visible') ? 'secondary' : 'primary'}
                  onClick={() => {
                    if (l.get('visible') && can(features[key], 'hide')) {
                      features[key].hide();
                    } else if (!l.get('visible') && can(features[key], 'show')) {
                      features[key].show(getViewExtent());
                    }
                  }}
                >
                  {l.get('name')}
                </Fab>
              );
            }
          });
        })}
      <Fab
        size={isMobile ? 'medium' : 'small'}
        sx={{ position: 'relative' }}
        color="primary"
        aria-label="add"
        onClick={() => setLayerSelectorOpened(!stateLayerSelectorOpened)}
      >
        {isMobile ? <LayersIcon /> : <Add />}
      </Fab>
    </Box>
  );
}
