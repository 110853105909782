import React from 'react';
import { nanoid } from 'nanoid';
import { disableAreaSearch, enableAreaSearch } from '../../stores/areaSearch';
import { GeoJSON } from 'ol/format';
import VectorSource from 'ol/source/Vector';
import { setVectorLayer } from '../../stores/layers';
import { getViewExtent, getViewZoom } from '../../stores/view';
import { addNotification, closeNotification } from '../../stores/notifications';
import { style } from './style';
import {
  getZoneContournementApi,
  sendZoneContournementApi,
  deleteZoneContournementApi,
  updateZoneContournementApi,
} from './api';
import { addItems } from '../../stores/menuItems';
import { highlightFeature } from '../../utils/mapView';
import { Avatar } from '@mui/material';
import hex2rgba from '../../utils/hex2rgba';
import { addInteraction, removeInteraction, useInteractionsStore } from '../../stores/interactions';
import DrawForm from './components/DrawForm';
import EditForm from './components/EditForm';
import {
  createGeometry as createGeometryEntryZoneContournement,
  updateGeometry as updateGeometryEntryZoneContournement,
  updateGeometryProperties as updateGeometryPropertiesEntryZoneContournement,
} from './bindings/geometry-entry';
import { Collection } from 'ol';
import { typezone } from './enums';
import { validateDefaultSchedule } from './rules/validate-schedule';
import { displayDefaultSchedule, displaySchedule } from './rules/display-schedule';
import { parse } from 'date-fns';
import { fr } from 'date-fns/locale';

const id = nanoid();
const props = {
  title: 'Zone de contournement',
  type: 'Vector',
  visible: false,
  defaultGeomColor: '#ee9900',
  AVideGeomColor: '#FF0000',
  AChargeGeomColor: '#008000',
  highlightColor: '#005ca9',
  group: 'Navigation',
};

let typeZoneCourtenementSelected = '';

const show = async (extent) => {
  enableAreaSearch(id, 'Rechercher les zones de contournement dans cette zone', show);

  if (getViewZoom() > 10) {
    const notifId = addNotification({
      message: props.title + ' - ' + 'Chargement en cours',
      variant: 'loading',
      persist: true,
    });
    try {
      const response = await getZoneContournementApi({
        extent,
        type: typeZoneCourtenementSelected,
      });
      const source = new VectorSource();
      const features = new GeoJSON().readFeatures(response);

      source.addFeatures(features);
      addNotification({ message: props.title + ' - ' + 'Chargement terminé', variant: 'success' });
      setVectorLayer({ id, style, source, visible: true });
    } finally {
      closeNotification(notifId);
    }
  } else {
    addNotification({
      message: props.title + ' - ' + 'Zoomer pour afficher des données',
      variant: 'warning',
    });    
    setVectorLayer({ id, style, source: new VectorSource(), visible: true });
  }
};

const hide = () => {
  disableAreaSearch(id);

  const source = new VectorSource();
  setVectorLayer({ id, source, visible: false });
};

const popup = (feature) => {
  const typeZone = typezone.find((i) => i.value === feature.type_zone);
  return `${props.title} - (${typeZone?.label || ''})`;
};

const mapItem = (feature) => {
  feature.set('nanoid', nanoid(), true);
  return feature;
};

const listItem = (feature) => {
  const fProps = feature.getProperties();
  const intModifyId = nanoid();

  const actions = [
    {
      icon: 'CenterFocusStrong',
      title: 'Centrer',
      handleClick: () => {
        highlightFeature(id, feature);
      },
    },
    {
      icon: 'EditLocation',
      title: 'Modifier la zone de contournement',
      handleClick: () => {
        const interaction = useInteractionsStore
          .getState()
          .interactions.find((item) => item.id === intModifyId);
        if (!interaction) {
          const notifActions = [
            {
              title: 'Confirmer',
              handleClick: async () => {
                removeInteraction({ id: intModifyId });
                try {
                  await edit(feature);
                  addNotification({
                    message: 'Zone de contournement modifiée',
                    variant: 'success',
                  });
                  show(getViewExtent());
                } catch (error) {
                  console.log(error);
                  addNotification({
                    message: 'Impossible de modifier la zone de contournement',
                    variant: 'error',
                  });
                }
              },
            },
            {
              title: 'Annuler',
              handleClick: () => {
                removeInteraction({ id: intModifyId });
                show(getViewExtent());
              },
            },
          ];

          addNotification({
            message: props.title + ' - ' + 'Modification de la zone de contournement',
            variant: 'info',
            persist: true,
            actions: notifActions,
          });

          addInteraction({
            id: intModifyId,
            type: 'Modify',
            features: new Collection([feature]),
            handleModifyend: () => {},
          });
        }
      },
    },
    {
      icon: 'Edit',
      title: 'Modifier les infos',
      handleClick: async () => {
        addItems({
          component: EditForm,
          props: {
            onSubmit: async (data) => {
              try {
                await edit(feature, data);
                addNotification({
                  message: 'Infos de la zone de contournement modifiées',
                  variant: 'success',
                });
                show(getViewExtent());
                return Promise.resolve();
              } catch (err) {
                addNotification({
                  message: 'Impossible de modifier les infos de la zone de contournement',
                  variant: 'error',
                });
                return Promise.reject(err);
              }
            },
            zoneProps: fProps,
          },
          children: null,
        });
      },
    },
    {
      icon: 'Delete',
      title: 'Supprimer',
      handleClick: async () => {
        try {
          await deleteZoneContournementApi(feature.getId());
          addNotification({
            message: 'Zone de contournement supprimée',
            variant: 'success',
          });
          show(getViewExtent());
        } catch (error) {
          addNotification({
            message: 'Impossible de supprimer la Zone de contournement',
            variant: 'error',
          });
        }
      },
    },
  ];

  let color = props.defaultGeomColor;
  if (fProps.type_zone == 'AVI') color = props.AVideGeomColor;
  if (fProps.type_zone == 'ACH') color = props.AChargeGeomColor;
  if (fProps.extras && fProps.extras.color) color = fProps.extras.color;

  let avatar = (
    <Avatar
      sx={{
        backgroundColor: hex2rgba(color, 0.5),
        border: '2px solid ' + hex2rgba(color, 1.0),
        color: '#111',
      }}
      variant="rounded"
    >
      {' '}
    </Avatar>
  );

  const typeZone = typezone.find((i) => i.value === fProps.type_zone);
  const dateFin = fProps.date_fin
    ? parse(fProps.date_fin, 'yyyy-MM-dd', new Date(), { locale: fr })
    : null;
  const dateDebut = fProps.date_debut
    ? parse(fProps.date_debut, 'yyyy-MM-dd', new Date(), { locale: fr })
    : null;
  const heureDebut = fProps.heure_debut
    ? parse(fProps.heure_debut, 'HH:mm:ss', new Date(), { locale: fr })
    : null;
  const heureFin = fProps.heure_fin
    ? parse(fProps.heure_fin, 'HH:mm:ss', new Date(), { locale: fr })
    : null;
  const periodeAffichage = validateDefaultSchedule(
    dateDebut,
    dateFin,
    heureDebut,
    heureFin,
    fProps.jours_affichage
  )
    ? displayDefaultSchedule()
    : displaySchedule(dateDebut, dateFin, heureDebut, heureFin, fProps.jours_affichage);
  const commentaire = fProps.commentaire ? fProps.commentaire : '';

  return {
    itemId: fProps.nanoid,
    titleHeader: props.title,
    subHeader: '',
    avatar: avatar,
    content: [
      'Type de zone: ' + typeZone?.label,
      "Période d'affichage: " + periodeAffichage,
      'Commentaire: ' + commentaire,
    ],
    actions,
  };
};

const menuItems = () => {
  const menuItems = [
    {
      key: nanoid(),
      text: 'Voir toutes les zones  de contournement',
      icon: 'Search',
      selected: typeZoneCourtenementSelected == '',
      handleClick: () => {
        typeZoneCourtenementSelected = '';
        show(getViewExtent());
      },
    },
  ];

  typezone.forEach((t) => {
    menuItems.push({
      key: nanoid(),
      text: 'Voir les zones de contournement ' + t.label.toLowerCase(),
      icon: 'Search',
      selected: typeZoneCourtenementSelected == t.value,
      handleClick: () => {
        typeZoneCourtenementSelected = t.value;
        show(getViewExtent());
      },
    });
  });

  menuItems.push({
    key: nanoid(),
    text: 'Dessiner une zone de contournement',
    icon: 'LocationSearching',
    handleClick: () =>
      addItems({
        component: DrawForm,
        props: {
          onSubmit: (data) => {
            addNotification({
              message:
                'Dessinez la zone de contournement sur la carte puis double-cliquez pour finaliser la création',
              variant: 'default',
            });
            return new Promise((resolve, reject) => {
              const intId = nanoid();
              addInteraction({
                id: intId,
                type: 'Draw',
                layerId: id,
                geomType: 'Polygon',
                handleDrawend: async (e) => {
                  removeInteraction({ id: intId });
                  const feature = e.feature;
                  try {
                    const response = await create(feature, data);
                    if (response.gid) {
                      addNotification({
                        message: props.title + ' - ' + ' créé',
                        variant: 'success',
                      });
                      show(getViewExtent());
                      resolve();
                    } else {
                      addNotification({
                        message: props.title + ' - ' + 'NON créé',
                        variant: 'error',
                      });
                      reject('something wrong');
                    }
                  } catch (err) {
                    show(getViewExtent());
                    reject(err);
                  }
                },
              });
            });
          },
        },
        children: null,
      }),
  });

  return menuItems;
};

const create = async (feature, data) => {
  const createGeometryEntry = await createGeometryEntryZoneContournement({
    feature,
    data,
  });
  const response = await sendZoneContournementApi(createGeometryEntry);
  return response;
};

const edit = async (feature, data) => {
  if (data) {
    const updateGeometryPropertiesZoneContournement =
      await updateGeometryPropertiesEntryZoneContournement({
        feature,
        data,
      });
    const response = await updateZoneContournementApi(updateGeometryPropertiesZoneContournement);
    return response;
  } else {
    const updateGeomeZoneContournement = await updateGeometryEntryZoneContournement({
      feature,
    });
    const response = await updateZoneContournementApi(updateGeomeZoneContournement);
    return response;
  }
};

export { id, props, show, hide, popup, mapItem, listItem, menuItems };
