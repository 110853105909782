import { Stroke, Fill, Style, Text } from 'ol/style';
import { props } from '../index';
import hex2rgba from '../../../utils/hex2rgba';

const font = 'bold' + ' ' + '15px' + '/' + '1' + ' ' + 'Arial';

const labelStyle = new Style({
  text: new Text({
    font: font,
    overflow: true,
    fill: new Fill({
      color: '#000',
    }),
    stroke: new Stroke({
      color: '#fff',
      width: 3,
    }),
  }),
});

const getColor = (feature) => {
  let color = props.defaultGeomColor;
  if(feature.get('type_zone') == 'AVI') color = props.AVideGeomColor;
  if(feature.get('type_zone') == 'ACH') color = props.AChargeGeomColor;
  if(feature.get('extras') && feature.get('extras').color) color = feature.get('extras').color;
  return color;
}

const zoneStyle = new Style({});

const style = (feature) => {

  // simple feature
  const styles = [labelStyle];

  let color = getColor(feature)

  const rgba = hex2rgba(
    feature.get('highlight') ? props.highlightColor : color,
    0.5
  );

  const colorStroke = feature.get('highlight')
    ? props.highlightColor
    : color;
  zoneStyle.setFill(
    new Fill({
      color: rgba,
    })
  );
  zoneStyle.setStroke(
    new Stroke({
      color: colorStroke,
      width: 2,
    })
  );

  styles.push(zoneStyle);


  return styles;
};

export { style };
