import { Fill, Stroke, Style, Text } from 'ol/style';
import hex2rgba from '../../../utils/hex2rgba';
import { props } from '../index';

const font = 'bold' + ' ' + '15px' + '/' + '1' + ' ' + 'Arial';

const labelStyle = new Style({
  text: new Text({
    font: font,
    overflow: true,
    fill: new Fill({
      color: '#000',
    }),
    stroke: new Stroke({
      color: '#fff',
      width: 3,
    }),
  }),
});

const commStyle = new Style({});

const style = (feature) => {
  // simple feature
  const styles = [labelStyle];

  const nomComm = feature.get('nom') ? `${feature.get('nom')}` : '';
  let label = '';
  let rgba = hex2rgba(props.defaultGeomColor, 0.5);
  let colorStroke = props.defaultGeomColor;

  if (
    feature.get('nature') &&
    (feature.get('nature') == 'COMD' || feature.get('nature') == 'COMA') &&
    !feature.get('lieudit')
  ) {
    label = `${nomComm}\n(commune associée)`;
    rgba = hex2rgba(props.toAssociateColor, 0.5);
    colorStroke = props.toAssociateColor;
  } else if (feature.get('lieudit')) {
    const secteur = feature.get('secteur') ? `(${feature.get('secteur')})` : '';
    label = `${nomComm} - ${feature.get('lieudit')} ${secteur}`;
    rgba = hex2rgba(props.associeColor, 0.5);
    colorStroke = props.associeColor;
  } else {
    label = `${nomComm}`;
  }

  if (feature.get('highlight')) {
    rgba = hex2rgba(props.highlightColor, 0.5);
    colorStroke = props.highlightColor;
  }

  labelStyle.getText().setText(label ? label : '');

  commStyle.setFill(
    new Fill({
      color: rgba,
    })
  );
  commStyle.setStroke(
    new Stroke({
      color: colorStroke,
      width: 2,
    })
  );

  styles.push(commStyle);
  return styles;
};

export { style };
