import { React, useEffect } from 'react';
import { nanoid } from 'nanoid';
import { Button, styled } from '@mui/material';
import {
  useNotificationStore,
  removeNotification,
  clearNotifications,
  closeNotification,
} from '../stores/notifications';
import { toast } from 'react-toastify';

const useNotifications = () => {
  const stateNotifications = useNotificationStore((state) => state.notifications);
  const stateClearNotifications = useNotificationStore((state) => state.clear);
  const stateCloseNotification = useNotificationStore((state) => state.close);

  useEffect(() => {
    stateNotifications.map((n) => {
      const { id, message, variant, actions, toastId, persist } = n;

      if (toastId) return; // don't add a notification that had already been enqueued

      const toastOptions = {
        data: {
          content: message,
          actions,
        },
        autoClose: persist ? false : variant == 'error' ? 10000 : 3000,
        pauseOnHover: !persist,
        pauseOnFocusLoss: !persist,
        theme: variant == 'default' || variant == 'loading' ? 'dark' : 'colored',
        type: variant,
        closeButton: variant == 'error',
        onClose: (removedByUser) => {
          if (removedByUser) {
            removeNotification(id);
          }
        },
      };

      const toastContent = actions ? CustomNotification : message;

      const newToastId =
        variant == 'loading'
          ? toast.loading(toastContent, toastOptions)
          : toast(toastContent, toastOptions);
      n.toastId = newToastId;
    });
  }, [stateNotifications]);

  useEffect(() => {
    if (stateClearNotifications) {
      toast.dismiss();
      clearNotifications();
    }
  }, [stateClearNotifications]);

  useEffect(() => {
    stateCloseNotification.map((notif) => {
      toast.dismiss(notif.toastId);
    });
    closeNotification(null);
  }, [stateCloseNotification]);

  return null;
};

const CustomNotification = ({ closeToast, data }) => {
  return (
    <>
      <div style={{ margin: '10px 10px 10px 0', display: 'inline-block' }}>{data.content}</div>
      <div style={{ textAlign: 'right' }}>
        {data.actions.map((i) => (
          <ActionButton
            size="small"
            key={nanoid()}
            variant="outlined"
            onClick={() => {
              i.handleClick();
              closeToast();
            }}
          >
            {i.title}
          </ActionButton>
        ))}
      </div>
    </>
  );
};

const ActionButton = styled(Button)({
  margin: '5px 5px',
  color: '#fff',
  borderColor: '#fff',
  '&:hover': {
    backgroundColor: 'rgba(255, 255, 255, 0.3)',
  },
});

export default useNotifications;
