import React, { useState, useEffect } from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import { usePrefTechStore } from '../../../../stores/prefTech';
import FormInputText from '../../../../components/FormInputText';
import FormInputAutocomplete from '../../../../components/FormInputAutocomplete';
import FormSelect from '../../../../components/FormSelect';
import FormInputMultiCheckbox from '../../../../components/FormInputMultiCheckbox';
import {
  Button,
  Paper,
  Typography,
  Divider,
  MenuItem,
  CircularProgress,
  Box,
  Tabs,
  Tab,
} from '@mui/material';
import {
  getSecteur,
  getUsines,
  getTechType,
  getTechList,
  getCommunes,
  getCommunesTech,
  getCommunesUsine,
} from '../../api';

const defaultValues = {
  codesilo: '',
  codeplanteur: '',
  commune: '',
  secteur: '',
  techtype: '',
  technom: '',
  techusine: '',
  techcommunes: [],
  passage: [],
  setExtent: true,
};

const SearchForm = ({ onSubmit }) => {
  const statePrefTech = usePrefTechStore((state) => state.prefs);
  const { CODESIA } = statePrefTech;
  const methods = useForm({ defaultValues: defaultValues });
  const {
    handleSubmit,
    formState: { isSubmitting, errors },
    getValues,
    setValue,
    setError,
    clearErrors,
    watch,
    reset,
    control,
  } = methods;

  const watchTechnom = watch('technom');
  const watchTechtype = watch('techtype');
  const watchTechusine = watch('techusine');

  const [usines, setUsines] = useState([]);
  const [types, setTechType] = useState([]);
  const [noms, setTechNom] = useState([]);
  const [communes, setCommunes] = useState([]);
  const [selectedTab, setSelectedTab] = React.useState(0);

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      },
    },
  };

  // init usines list
  useEffect(() => {
    (async () => {
      const data = await getUsines();
      const mapUsines = data.map((u) => ({ label: u.nom, value: u.code_usine }));
      setUsines(mapUsines);
    })();
  }, [setValue]);

  // init techniciens type list
  useEffect(() => {
    (async () => {
      const data = await getTechType();
      const mapTechType = data.map((t) => ({ label: t, value: t }));
      setTechType(mapTechType);
      setValue('techtype', 'campagne', true); // init value
    })();
  }, [setValue]);

  // init techniciens nom list
  useEffect(() => {
    (async () => {
      const data = await getTechList();
      const mapTechList = data
        .sort((a, b) => a.nomprofil.localeCompare(b.nomprofil)) // sort ascending alphabetically
        .map((t) => ({ label: t.nomprofil, value: t.profilsia }));
      setTechNom(mapTechList);
      setValue('technom', CODESIA, true);
    })();
  }, [setValue, CODESIA]);

  // update technicien communes list on select technicien or type change
  useEffect(() => {
    if (watchTechnom != '' && watchTechtype != '') {
      (async () => {
        const data = await getCommunesTech({ nom: watchTechnom, type: watchTechtype });
        const mapCommuneList = data.map((t) => ({ label: t.nom, value: t.codeInsee }));
        setCommunes(mapCommuneList);
      })();
    }
  }, [watchTechnom, watchTechtype]);

  // update technicien communes list on select usine change
  useEffect(() => {
    if (watchTechusine != '') {
      (async () => {
        const data = await getCommunesUsine({ usine: watchTechusine });
        const mapCommuneList = data.map((t) => ({ label: t.nom, value: t.codeInsee }));
        setCommunes(mapCommuneList);
      })();
    }
  }, [watchTechusine]);

  const filterCommunesTech = async ({ text }) => {
    const filteredCommunes = communes.filter(
      (commune) =>
        commune.label.toLowerCase().indexOf(text.toLowerCase()) >= 0 ||
        commune.value.toLowerCase().indexOf(text.toLowerCase()) >= 0
    );
    const uniqueCommunes = [
      ...new Map(filteredCommunes.map((item) => [item['value'], item])).values(),
    ];
    return uniqueCommunes.sort((a, b) => a.label.localeCompare(b.label));
  };

  return (
    <Paper
      style={{
        display: 'grid',
        gridRowGap: '15px',
        padding: '10px 20px 20px 20px',
      }}
    >
      <FormProvider {...methods}>
        <Typography variant="h6">Recherche avancée de silos</Typography>
        <Divider />
        <Typography variant="subtitle1">Code(s)</Typography>
        <FormInputText
          name="codesilo"
          control={control}
          label="Code silo"
          rules={{
            pattern: {
              value: /^[0-9]{7}$/,
              message: 'Veuillez entrer un code silo valide (7 chiffres)',
            },
          }}
        />
        <FormInputText
          name="codeplanteur"
          control={control}
          label="Code planteur"
          number={true}
          inputProps={{ maxLength: 7 }}
        />
        <FormInputAutocomplete
          name="secteur"
          control={control}
          label="Secteur géographique"
          placeholder="Saisir un secteur"
          displayProp="secteurLibelle"
          equalityProp="secteurGrue"
          source={getSecteur}
        />
        <Divider sx={{ margin: '10px 0' }} />
        <Typography variant="subtitle1">Commune(s)</Typography>

        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <Tabs
            value={selectedTab}
            onChange={(event, newTabIndex) => {
              setValue('techcommunes', []);
              setValue('commune', '');
              setSelectedTab(newTabIndex);
            }}
            aria-label="Rechercher une commune"
          >
            <Tab label="Par nom" />
            <Tab label="Par technicien" />
          </Tabs>
        </Box>
        <div
          role="tabpanel"
          style={{
            display: selectedTab === 0 ? 'grid' : 'none',
            gridRowGap: '15px',
          }}
        >
          <FormInputAutocomplete
            name="commune"
            control={control}
            label="Commune"
            placeholder="Saisir une commune"
            displayProp="nom"
            equalityProp="codeInsee"
            source={getCommunes}
          />
        </div>
        <div
          role="tabpanel"
          style={{
            display: selectedTab === 1 ? 'grid' : 'none',
            gridRowGap: '15px',
          }}
        >
          <FormSelect name="techtype" control={control} label="Type" MenuProps={MenuProps}>
            {types.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </FormSelect>
          <FormSelect name="technom" control={control} label="Nom" MenuProps={MenuProps}>
            {noms.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </FormSelect>
          <FormSelect
            name="techusine"
            control={control}
            label="Usine de livraison"
            MenuProps={MenuProps}
          >
            {usines.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </FormSelect>
          <FormInputAutocomplete
            multiple={true}
            filterSelectedOptions={true}
            name="techcommunes"
            control={control}
            label="Commune(s)"
            placeholder="Saisir une commune"
            displayProp="label"
            equalityProp="value"
            source={filterCommunesTech}
            isLocalSource={true}
          />
        </div>
        <Divider sx={{ margin: '10px 0' }} />
        <Typography variant="subtitle1">Passage(s)</Typography>
        <FormInputMultiCheckbox
          control={control}
          setValue={setValue}
          name="passage"
          options={[
            { label: 'Passage 0', value: '0' },
            { label: 'Passage 1', value: '1' },
            { label: 'Passage 2', value: '2' },
            { label: 'Passage 3', value: '3' },
            { label: 'Passage 4', value: '4' },
          ]}
        />
        <Divider />
        {errors?.form?.message && (
          <Typography variant="inherit" color="red">
            {errors?.form?.message}
          </Typography>
        )}
        <Button
          onClick={() => {
            // data validation before submit
            const atLeastOneMandatoryValues =
              getValues(['codesilo', 'codeplanteur', 'secteur']).join('').length > 0;
            const commune = getValues('commune');
            const techcommunes = getValues('techcommunes');
            if (!atLeastOneMandatoryValues && commune === '' && techcommunes.length === 0) {
              setError('form', {
                type: 'manual',
                message:
                  'Veuillez renseigner au moins un champ (code silo, code planteur, commune, ou secteur)',
              });
            } else {
              clearErrors();
              return handleSubmit(onSubmit)();
            }
          }}
          // onClick={handleSubmit(onSubmit)}
          variant="contained"
          disabled={isSubmitting}
        >
          {isSubmitting ? <CircularProgress color="inherit" size={20} /> : 'OK'}
        </Button>
        <Button onClick={() => reset()} variant="outlined" disabled={isSubmitting}>
          Effacer
        </Button>
      </FormProvider>
    </Paper>
  );
};

export default SearchForm;
