import { Button, CircularProgress, Divider, MenuItem, Paper, Typography } from '@mui/material';
import { nanoid } from 'nanoid';
import React, { useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import FormSelect from '../../../../components/FormSelect';
import { getLieuDit } from '../../api';

const defaultValues = {
  commune: '',
  insee: '',
  ancieninsee: '',
};

const LinkForm = ({ onSubmit, communeProps, features = [] }) => {
  const { nom, insee_com, insee_cad = '' } = communeProps;
  const methods = useForm({ defaultValues });
  const {
    handleSubmit,
    formState: { isSubmitting, errors },
    getValues,
    setValue,
    clearErrors,
    setError,
    control,
  } = methods;

  const [loading, setLoading] = useState(false);
  const [lieuxDits, setLieuxDits] = useState([]);
  const [prevInseeCom, setPrevInseeCom] = useState('');

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;

  setValue('commune', nom);
  setValue('insee', insee_com);
  setValue('ancieninsee', insee_cad);

  if (insee_com !== prevInseeCom) {
    (async () => {
      setLoading(true);
      const notAllowedLieuxDits = features
        .filter((f) => f.get('insee_com') == insee_com && f.get('lieudit'))
        .map((f) => f.get('lieudit'));
      const ds = await getLieuDit({ code: insee_com });
      const mapLieuxDits = ds
        .filter((e) => !notAllowedLieuxDits.includes(e.lieudit))
        .map((e) => ({
          label: `${e.nom} - ${e.lieudit} - ${e.secteur}`,
          value: e,
        }));

      setLieuxDits(mapLieuxDits);
      setLoading(false);
    })();

    setPrevInseeCom(insee_com);
  }

  return (
    <Paper
      style={{
        display: 'grid',
        gridRowGap: '20px',
        padding: '20px',
      }}
    >
      <FormProvider {...methods}>
        <Typography variant="h6">Associer un lieu-dit</Typography>
        <Divider />
        <Typography variant="subtitle1">
          Lieu(x)-dit(s) existant(s) sur{' '}
          {loading ? <CircularProgress color="inherit" size={20} /> : `${nom}`}:
        </Typography>
        <FormSelect
          name="lieudit"
          control={control}
          label="Choisissez..."
          renderValue={(selected) => `${selected.nom} - ${selected.lieudit} - ${selected.secteur}`}
          MenuProps={{
            PaperProps: {
              style: {
                maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
              },
            },
          }}
        >
          {lieuxDits.map((option) => (
            <MenuItem key={nanoid()} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </FormSelect>
        <Typography variant="inherit" color="red">
          {errors?.form?.message}
        </Typography>
        <Button
          onClick={() => {
            const lieuDit = getValues('lieudit');

            if (lieuDit !== '') {
              clearErrors();
              return handleSubmit(onSubmit)();
            } else {
              setError('form', {
                type: 'manual',
                message: 'Veuillez choisir un lieu-dit avant de valider',
              });
            }
          }}
          variant="contained"
          disabled={isSubmitting || loading}
        >
          {isSubmitting || loading ? <CircularProgress color="inherit" size={20} /> : 'Valider'}
        </Button>
      </FormProvider>
    </Paper>
  );
};

export default LinkForm;
