import chroma from 'chroma-js';
import {
  SURFACE_DONNEE_SECTEUR,
  SURFACE_DONNEE_USINE,
  SURFACE_ENQUETE_BETTERAVE,
  SURFACE_ENQUETE_LUZERNE,
  SURFACE_PLANNING_BETTERAVE,
} from '../../../stores/surface';

const zeroColor = '#A8B4A6';

const colors = [
  zeroColor,
  '#9DF9F6',
  '#87A9FD',
  '#7B95F9',
  '#3496FE',
  '#3586DD',
  '#35AC9F',
  '#35D14D',
  '#BEFF35',
  '#E1FF5D',
  '#FFBD35',
  '#FF8134',
  '#FD6335',
  '#FC4335',
];

const betteraveScale = chroma
  .scale(colors)
  .domain([0, 50, 100, 150, 200, 250, 300, 350, 400, 450, 500, 650, 700, 750]);

const luzerneScale = chroma
  .scale(colors)
  .domain([0, 10, 20, 30, 40, 50, 60, 70, 80, 90, 100, 110, 120, 130]);

const getScale = (surfaceState) => {
  let max = null;
  if (
    surfaceState.donnee == SURFACE_DONNEE_SECTEUR &&
    (surfaceState.type == SURFACE_ENQUETE_BETTERAVE ||
      surfaceState.type == SURFACE_PLANNING_BETTERAVE)
  ) {
    max = surfaceState.maxCumulBetteraveSecteur;
  }
  if (
    surfaceState.donnee == SURFACE_DONNEE_USINE &&
    (surfaceState.type == SURFACE_ENQUETE_BETTERAVE ||
      surfaceState.type == SURFACE_PLANNING_BETTERAVE)
  ) {
    max = surfaceState.maxCumulBetteraveUsine;
  }
  if (surfaceState.donnee == SURFACE_DONNEE_USINE && surfaceState.type == SURFACE_ENQUETE_LUZERNE) {
    max = surfaceState.maxCumulLuzerneUsine;
  }

  if (max) {
    let values = [];
    for (let i = 1; i <= 14; i++) {
      values.push(Math.round((i == 1 ? 0 : i) * (max / 14)));
    }
    return chroma.scale(colors).domain(values);
  }

  if (surfaceState.type == SURFACE_ENQUETE_LUZERNE) {
    return luzerneScale;
  }
  return betteraveScale;
};

export { zeroColor, getScale };
