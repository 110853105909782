import React from 'react';
import { nanoid } from 'nanoid';
import { setVectorLayer, useLayerStore } from '../../stores/layers';
import VectorSource from 'ol/source/Vector';
import { style, textStyle } from './style';
import { addNotification, closeNotification } from '../../stores/notifications';
import { getStatistique } from './api';
import { useMapStore } from '../../stores/map';
import { vectorTileSourceFromGeojsonVt } from '../../utils/vectortile';
import geojsonvt from 'geojson-vt';
import { GeoJSON } from 'ol/format';
import { useItemsStore } from '../../stores/menuItems';
import { selectionStyle } from './style';
import {
  STATISTIQUE_DONNEE_COMMUNE,
  STATISTIQUE_DONNEE_SECTEUR,
  STATISTIQUE_DONNEE_USINE,
  STATISTIQUE_TYPE_RENDEMENT_A_16,
  STATISTIQUE_TYPE_RENDEMENT_UTILE,
  STATISTIQUE_TYPE_RICHESSE,
  STATISTIQUE_TYPE_TARE_DECHET,
  getStatistiqueValue,
  setStatistiqueDonnee,
  setStatistiqueMinMax,
  setStatistiqueType,
  useStatistiqueStore,
} from '../../stores/statistique';
import { MenuItem, Select, Slider, ToggleButton, ToggleButtonGroup } from '@mui/material';
import { setStatistiquePassage } from '../../stores/statistique';
import CommuneInfo from './components/CommuneInfo';

const id = nanoid();
const props = {
  title: 'Statistique',
  type: 'VectorTile',
  visible: false,
  color: '#696969',
  opacity: 0.85,
  background: 'rgba(0, 0, 0, 0.35)',
  group: 'Commune',
};

const idSelection = nanoid();
const propsSelection = {
  title: 'Statistique Sélection',
  type: 'VectorTile',
  visible: false,
  parentId: id,
};

const idText = nanoid();
const propsText = {
  title: 'Statistique Texte',
  type: 'Vector',
  visible: false,
  parentId: id,
};

let geojsonVtTileIndex = null;
let statistiquesObserver = null;
let highlightItemObserver = null;
let minMaxTimer = null;
const tileSize = 1024;

const show = async () => {
  const notif = addNotification({
    message: props.title + ' - ' + 'Chargement en cours',
    variant: 'loading',
    persist: true,
  });
  try {
    let data = await getStatistique();
    let usines = data.usines;
    let secteurs = data.secteurs;

    for (let feature of data.features) {
      const codeSecteur = feature.properties.secteur;
      if (codeSecteur && codeSecteur in secteurs) {
        feature.properties['secteurData'] = secteurs[codeSecteur];
      }
      const codeUsine = feature.properties.usl;
      if (codeUsine && codeUsine in usines) {
        feature.properties['usineData'] = usines[codeUsine];
      }
    }

    // Generate tiles
    geojsonVtTileIndex = geojsonvt(data, {
      extent: tileSize,
      debug: 0,
      tolerance: 2, // default 3 (2 pour avoir une meilleure définition des contours quand on dezoom)
      buffer: 16, // default 64 (64 pour tuile de 4096 donc 16 pour tuile de 1024)
      promoteId: 'commune',
    });

    // Update layer
    const projection = useMapStore.getState().map.getView().getProjection();
    const source = vectorTileSourceFromGeojsonVt(geojsonVtTileIndex, projection, tileSize);
    setVectorLayer({ id, style, source: source, visible: true });
    setVectorLayer({
      id: idSelection,
      style: selectionStyle,
      source: source,
      visible: false,
    });
    const textFeatures = new GeoJSON({ featureProjection: projection }).readFeatures(data);
    setVectorLayer({
      id: idText,
      style: textStyle,
      source: new VectorSource({
        features: textFeatures,
      }),
      visible: true,
    });

    // Register meteo type/date observer
    if (!statistiquesObserver) {
      statistiquesObserver = useStatistiqueStore.subscribe(
        (state) => [state.type, state.passage],
        () => {
          if (useLayerStore.getState().activeLayer.get('id') == id) {
            updateLayer();
          }
        }
      );
    }

    // Setup highlightItem observer to display selection
    if (!highlightItemObserver) {
      highlightItemObserver = useItemsStore.subscribe(
        (state) => state.highlightItem,
        (highlightItem) => {
          const selectedFeature = highlightItem?.feature;
          const layer = highlightItem?.layer;
          setVectorLayer({
            id: idSelection,
            visible: selectedFeature && layer && layer.get('id') == id,
          });
        }
      );
    }

    addNotification({ message: props.title + ' - ' + 'Chargement terminé', variant: 'success' });
  } finally {
    closeNotification(notif);
  }
};

const hide = () => {
  if (statistiquesObserver) {
    statistiquesObserver();
    statistiquesObserver = null;
  }

  if (highlightItemObserver) {
    highlightItemObserver();
    highlightItemObserver = null;
  }

  const source = new VectorSource();
  setVectorLayer({ id, source, visible: false });
  setVectorLayer({ id: idSelection, visible: false });
  setVectorLayer({ id: idText, visible: false });
};

const updateLayer = () => {
  setVectorLayer({ id });
  setVectorLayer({ id: idSelection, visible: false });
  setVectorLayer({ id: idText });
};

const popup = (feature) => {
  const type = useStatistiqueStore.getState().type;
  let value = getStatistiqueValue(feature);

  const donnee = useStatistiqueStore.getState().donnee;
  let name = `${feature.commune} - ${feature.nomcommune}`;
  if (donnee == STATISTIQUE_DONNEE_SECTEUR) {
    name = feature.secteur;
  }
  if (donnee == STATISTIQUE_DONNEE_USINE) {
    name = `${feature.usl} - ${feature.nomusl}`;
  }

  return (
    <div>
      {name} -{' '}
      <strong>
        {value}
        {type == STATISTIQUE_TYPE_RENDEMENT_UTILE || type == STATISTIQUE_TYPE_RENDEMENT_A_16
          ? 'T/Ha'
          : '%'}
      </strong>
    </div>
  );
};

const highlightedItemComponent = (feature) => {
  return <CommuneInfo feature={feature} />;
};

const menuItems = () => {
  const type = useStatistiqueStore.getState().type;
  const donnee = useStatistiqueStore.getState().donnee;
  const passage = useStatistiqueStore.getState().passage;
  const currentMin = useStatistiqueStore.getState().min;
  const currentMax = useStatistiqueStore.getState().max;
  const min = 0;
  const max = type == STATISTIQUE_TYPE_RICHESSE || type == STATISTIQUE_TYPE_TARE_DECHET ? 20 : 200;
  const step = type == STATISTIQUE_TYPE_RICHESSE || type == STATISTIQUE_TYPE_TARE_DECHET ? 1 : 10;

  const slider = {
    key: nanoid(),
    text: '',
    selected: true,
    selectable: false,
    avatar: (
      <Slider
        min={min}
        max={max}
        step={step}
        defaultValue={[currentMin, currentMax == -1 ? max : currentMax]}
        valueLabelDisplay="on"
        marks
        onChange={(event, newValue) => {
          if (minMaxTimer) {
            clearTimeout(minMaxTimer);
          }
          minMaxTimer = setTimeout(() => {
            setStatistiqueMinMax(newValue[0], newValue[1] == max ? -1 : newValue[1]);
          }, 500);
        }}
        sx={{
          margin: '18px 10px 0 10px',
        }}
      />
    ),
  };

  const actions = [
    {
      key: nanoid(),
      text: '',
      selectable: false,
      avatar: (
        <Select
          fullWidth={true}
          value={passage}
          sx={{
            height: '35px',
          }}
          onChange={(event) => {
            setStatistiquePassage(event.target.value);
          }}
        >
          <MenuItem value="all" selected={true}>
            Tous les passages
          </MenuItem>
          <MenuItem value="0">Passage 0</MenuItem>
          <MenuItem value="1">Passage 1</MenuItem>
          <MenuItem value="2">Passage 2</MenuItem>
          <MenuItem value="3">Passage 3</MenuItem>
          <MenuItem value="4">Passage 4</MenuItem>
          <MenuItem value="5">Passage 5</MenuItem>
        </Select>
      ),
    },
    {
      key: nanoid(),
      text: '',
      selectable: false,
      avatar: (
        <ToggleButtonGroup
          value={donnee}
          exclusive
          fullWidth
          sx={{ height: '35px' }}
          onChange={(event, value) => {
            setStatistiqueDonnee(value);
          }}
        >
          <ToggleButton value={STATISTIQUE_DONNEE_COMMUNE}>Commune</ToggleButton>
          <ToggleButton value={STATISTIQUE_DONNEE_SECTEUR}>Secteur</ToggleButton>
          <ToggleButton value={STATISTIQUE_DONNEE_USINE}>Usine</ToggleButton>
        </ToggleButtonGroup>
      ),
    },
    {
      key: nanoid(),
      text: 'Voir la richesse',
      icon: 'Percent',
      selected: type == STATISTIQUE_TYPE_RICHESSE,
      handleClick: () => {
        setStatistiqueType(STATISTIQUE_TYPE_RICHESSE);
      },
    },
  ];

  if (type == STATISTIQUE_TYPE_RICHESSE) actions.push(slider);

  actions.push({
    key: nanoid(),
    text: 'Voir la tare déchet',
    icon: 'Percent',
    selected: type == STATISTIQUE_TYPE_TARE_DECHET,
    handleClick: () => {
      setStatistiqueType(STATISTIQUE_TYPE_TARE_DECHET);
    },
  });

  if (type == STATISTIQUE_TYPE_TARE_DECHET) actions.push(slider);

  actions.push({
    key: nanoid(),
    text: 'Voir le rendement utile',
    icon: 'Scale',
    selected: type == STATISTIQUE_TYPE_RENDEMENT_UTILE,
    handleClick: () => {
      setStatistiqueType(STATISTIQUE_TYPE_RENDEMENT_UTILE);
    },
  });

  if (type == STATISTIQUE_TYPE_RENDEMENT_UTILE) actions.push(slider);

  actions.push({
    key: nanoid(),
    text: 'Voir le rendement à 16',
    icon: 'Scale',
    selected: type == STATISTIQUE_TYPE_RENDEMENT_A_16,
    handleClick: () => {
      setStatistiqueType(STATISTIQUE_TYPE_RENDEMENT_A_16);
    },
  });

  if (type == STATISTIQUE_TYPE_RENDEMENT_A_16) actions.push(slider);

  return actions;
};

export {
  id,
  props,
  idSelection,
  propsSelection,
  idText,
  propsText,
  show,
  hide,
  popup,
  highlightedItemComponent,
  menuItems,
};
