import { create } from 'zustand';
import { subscribeWithSelector } from 'zustand/middleware';

const STATISTIQUE_TYPE_RICHESSE = 'richesse';
const STATISTIQUE_TYPE_RENDEMENT_UTILE = 'rendementutile';
const STATISTIQUE_TYPE_RENDEMENT_A_16 = 'rendement16';
const STATISTIQUE_TYPE_TARE_DECHET = 'taredechet';

const STATISTIQUE_DONNEE_COMMUNE = 'commune';
const STATISTIQUE_DONNEE_SECTEUR = 'secteur';
const STATISTIQUE_DONNEE_USINE = 'usine';

const getStatistiqueValue = (featureProperties, typeStat, passageStat, donneeStat) => {
  const type = typeStat ?? useStatistiqueStore.getState().type;
  const passage = passageStat ?? useStatistiqueStore.getState().passage;
  const donnee = donneeStat ?? useStatistiqueStore.getState().donnee;

  let value;

  if (donnee == STATISTIQUE_DONNEE_SECTEUR) {
    const secteurData = featureProperties['secteurData'];
    if (!secteurData) return null;
    value = parseFloat(secteurData[type + (passage == 'all' ? '' : passage)]) / 100.0;
  } else if (donnee == STATISTIQUE_DONNEE_USINE) {
    const usineData = featureProperties['usineData'];
    if (!usineData) return null;
    value = parseFloat(usineData[type + (passage == 'all' ? '' : passage)]) / 100.0;
  } else {
    value = parseFloat(featureProperties[type + (passage == 'all' ? '' : passage)]) / 100.0;
  }
  return value;
};

const useStatistiqueStore = create(
  subscribeWithSelector(() => ({
    type: STATISTIQUE_TYPE_RICHESSE,
    passage: 'all',
    donnee: STATISTIQUE_DONNEE_COMMUNE,
    min: -1,
    max: -1,
  }))
);

const setStatistiqueType = (type) => {
  useStatistiqueStore.setState(() => ({ type, min: -1, max: -1 }));
};

const setStatistiqueDonnee = (donnee) => {
  useStatistiqueStore.setState(() => ({ donnee }));
};

const setStatistiquePassage = (passage) => {
  useStatistiqueStore.setState(() => ({ passage }));
};

const setStatistiqueMinMax = (min, max) => {
  useStatistiqueStore.setState(() => ({ min, max }));
};

export {
  getStatistiqueValue,
  useStatistiqueStore,
  setStatistiqueDonnee,
  setStatistiqueType,
  setStatistiquePassage,
  setStatistiqueMinMax,
  STATISTIQUE_TYPE_RICHESSE,
  STATISTIQUE_TYPE_RENDEMENT_UTILE,
  STATISTIQUE_TYPE_RENDEMENT_A_16,
  STATISTIQUE_TYPE_TARE_DECHET,
  STATISTIQUE_DONNEE_COMMUNE,
  STATISTIQUE_DONNEE_SECTEUR,
  STATISTIQUE_DONNEE_USINE,
};
