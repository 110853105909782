/*eslint no-undef: "off"*/
import * as React from 'react';
import _isEqual from 'lodash/isEqual';
import { ArrayTwoOrMore } from './types';

import OlMap from 'ol/Map';
import OlLayerGroup from 'ol/layer/Group';
import OlLayerTile from 'ol/layer/Tile';
import OlTileSource from 'ol/source/Tile';

import MapComponent from '../MapComponent/MapComponent';

import './LayerSwitcher.css';

import { setRasterLayer } from '../../../stores/layers';
import TileLayer from 'ol/layer/Tile';
import { Stack, styled } from '@mui/material';
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';
import View from 'ol/View';

/**
 * @export
 * @interface LayerSwitcherProps
 * @extends {React.HTMLAttributes<HTMLDivElement>}
 */
export interface BaseProps {
  /**
   * An optional CSS class which will be added to the wrapping div Element.
   */
  className?: string;
  /**
   * The layers to be available in the switcher.
   */
  layers: ArrayTwoOrMore<OlLayerTile<OlTileSource> | OlLayerGroup>;
  /**
   * The main map the layers should be synced with.
   */
  map: OlMap;
}

interface LayerSwitcherState {
  previewLayer: OlLayerTile<OlTileSource> | OlLayerGroup;
}

export type LayerSwitcherProps = BaseProps & React.HTMLAttributes<HTMLDivElement>;

/**
 * Class representing the LayerSwitcher.
 * A basic component to switch between the passed layers.
 * This is most likely to be used for the backgroundlayer.
 *
 * @class LayerSwitcher
 * @extends React.Component
 */
export class LayerSwitcher extends React.Component<LayerSwitcherProps, LayerSwitcherState> {
  /**
   * The internal map of the LayerSwitcher
   * @private
   */
  _map: OlMap = null;

  _maps: OlMap[] = [];
  _openTooltips: boolean = false;
  /**
   *
   *
   * @private
   */
  _visibleLayerIndex: number = null;

  /**
   *
   *
   * @private
   */
  _layerClones: Array<OlLayerTile<OlTileSource> | OlLayerGroup> = [];

  /**
   * The className added to this component.
   * @private
   */
  _className = `react-geo-layer-switcher`;

  /**
   * Creates the LayerSwitcher.
   *
   * @constructs LayerSwitcher
   */
  constructor(props: LayerSwitcherProps) {
    super(props);
    this._map = this.getMap();
    this.state = {
      previewLayer: null,
    };
  }

  /**
   * A react lifecycle method called when the component did mount.
   */
  componentDidMount() {
    this.setMapLayers();
    this.updateLayerVisibility();
  }

  setMapLayersToolTip() {
    const { layers } = this.props;
    //ADD layer to _Maps
    layers.forEach((layer) => {
      const newMap = new OlMap({
        view: new View({
          center: [264224.7884753537, 6249257.101934968],
          zoom: 12,
        }),
        controls: [],
      });
      newMap.addLayer(layer);
      this._maps.push(newMap);
    });

    //Set layer visible as true for all layers in _Maps
    this._maps.forEach((layer) => {
      layer.getLayers().forEach((l) => {
        l.setVisible(true);
      });
    });
  }

  /**
   * Destroy all map specific stuff when umounting the component.
   *
   * @memberof LayerSwitcher
   */
  componentWillUnMount() {
    this._map.getLayers().clear();
    this._map.setTarget(null);
    this._map = null;
  }

  /**
   * Invoked immediately after updating occurs. This method is not called for
   * the initial render.
   *
   * @param prevProps The previous props.
   */
  componentDidUpdate(prevProps: LayerSwitcherProps) {
    if (!_isEqual(this.props.layers, prevProps.layers)) {
      this.setMapLayers();
      this.updateLayerVisibility();
    }
    if (this._openTooltips) {
      this._maps = [];
      this._openTooltips = false;
      this.setMapLayers();
      this.updateLayerVisibility();
    }
  }

  /**
   * Clones a layer
   *
   * @param layer The layer to clone.
   * @returns The cloned layer.
   */
  cloneLayer = (layer: any) => {
    let layerClone: OlLayerTile<OlTileSource> | OlLayerGroup;
    if (layer instanceof OlLayerGroup) {
      layerClone = new OlLayerGroup({
        layers: layer.getLayers().getArray().map(this.cloneLayer),
        properties: {
          originalLayer: layer,
        },
        ...layer.getProperties(),
      });
    } else {
      layerClone = new OlLayerTile({
        source: layer.getSource(),
        properties: {
          originalLayer: layer,
        },
        ...layer.getProperties(),
      });
    }
    return layerClone;
  };

  /**
   * (Re-)adds the layers to the preview map and sets the visibleLayerIndex.
   *
   */
  setMapLayers = () => {
    const { layers } = this.props;
    this._map.getLayers().clear();
    this._layerClones = layers.map((layer, index) => {
      const layerClone = this.cloneLayer(layer);
      if (layerClone.getVisible()) {
        this._visibleLayerIndex = index;
      }
      this._map.addLayer(layerClone);
      return layerClone;
    });
  };

  /**
   * Sets the visiblity of the layers in the props.map and this._map.
   * Also sets the previewLayer in the state.
   *
   */
  updateLayerVisibility = () => {
    const { layers } = this.props;
    layers.forEach((l, i) => {
      if (this._visibleLayerIndex === i) {
        l.setVisible(true);
      } else {
        l.setVisible(false);
      }
    });
    this._layerClones.forEach((l, i) => {
      if (this._visibleLayerIndex === this._layerClones.length - 1 && i === 0) {
        l.setVisible(true);
        this.setState({ previewLayer: l });
      } else if (this._visibleLayerIndex + 1 === i) {
        l.setVisible(true);
        this.setState({ previewLayer: l });
      } else {
        l.setVisible(false);
      }
    });
  };

  /**
   * Constructs this._map
   */
  getMap = (): OlMap => {
    const { map } = this.props;
    return new OlMap({
      view: map.getView(),
      controls: [],
    });
  };

  /**
   * Clickhandler for the overview switch.
   *
   */
  onSwitcherClick = (evt: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    evt.stopPropagation();
    this._map
      .getLayers()
      .getArray()
      .filter((l) => l instanceof TileLayer)
      .forEach((layer, index: number) => {
        if (layer.getVisible()) {
          this._visibleLayerIndex = index;
          setRasterLayer({ id: layer.get('id'), visible: true });
        } else {
          setRasterLayer({ id: layer.get('id'), visible: false });
        }
      });
    this.updateLayerVisibility();
  };

  onTooltipClick = (evt: React.MouseEvent<HTMLDivElement>, id?: string) => {
    evt.stopPropagation();
    this._map
      .getLayers()
      .getArray()
      .filter((l) => l instanceof TileLayer)
      .forEach((layer, index: number) => {
        if (layer.get('id') === id) {
          this._visibleLayerIndex = index;
          setRasterLayer({ id: layer.get('id'), visible: true });
        } else {
          setRasterLayer({ id: layer.get('id'), visible: false });
        }
      });
    this.updateLayerVisibility();
  };
  /**
   * The render function.
   */
  render() {
    const { className, layers, ...passThroughProps } = this.props;

    const finalClassName = className ? `${className} ${this._className}` : this._className;

    const handleClose = () => {
      this._maps = [];
      this._openTooltips = false;
    };

    const handleOpen = () => {
      this.setMapLayersToolTip();
      this._openTooltips = true;
      setTimeout(() => {
        this._maps.map((m, i) => {
          m.setTarget('map' + i);
        });
      }, 500);
    };

    const LayersPreviewTooltip = styled<React.ComponentType<TooltipProps>>(
      ({ className, ...props }: TooltipProps) => (
        <Tooltip {...props} classes={{ popper: className }} />
      )
    )(({ theme }) => ({
      [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: '#ffffff',
        color: 'rgb(0, 0, 0)',
        maxWidth: layers.length * 75,
        fontSize: theme.typography.pxToRem(12),
        border: '1px solid #ffffff',
      },
    }));

    return (
      <LayersPreviewTooltip
        onClose={handleClose}
        onOpen={handleOpen}
        enterDelay={100}
        title={
          <Stack direction="row" spacing={2}>
            {layers.map((l, i) => {
              return (
                <Stack
                  direction="column"
                  justifyContent="center"
                  alignItems="center"
                  spacing={0.5}
                  maxWidth={75}
                  height={80}
                  key={i}
                  onClick={(e) => this.onTooltipClick(e, l.get('id'))}
                  className="map-viewer-tooltip-container"
                >
                  <div className="clip" id={'map' + i}></div>
                  <div className="label">{l.get('name')}</div>
                </Stack>
              );
            })}
          </Stack>
        }
        placement="left"
      >
        <div className={finalClassName} {...passThroughProps}>
          <div className="clip" onClick={this.onSwitcherClick} role="button">
            <MapComponent mapDivId="layer-switcher-map" map={this._map} role="img" />
            {this.state.previewLayer && (
              <span className="layer-title">{this.state.previewLayer.get('name')}</span>
            )}
          </div>
        </div>
      </LayersPreviewTooltip>
    );
  }
}

export default LayerSwitcher;
