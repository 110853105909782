import React from 'react';
import { Controller } from 'react-hook-form';
import TextField from '@mui/material/TextField';
import { NumberFormatBase } from 'react-number-format';

const FormInputText = ({ name, control, label, rules, number = false, ...props }) => {
  return (
    <Controller
      name={name}
      control={control}
      rules={rules}
      render={({ field: { onChange, value }, fieldState: { error } }) => {
        if (number) {
          return (
            <NumberFormatBase
              {...props}
              customInput={TextField}
              helperText={error ? error.message : null}
              size="small"
              error={!!error}
              onChange={onChange}
              value={value}
              fullWidth
              label={label}
              variant="outlined"
            />
          );
        } else {
          return (
            <TextField
              {...props}
              helperText={error ? error.message : null}
              size="small"
              error={!!error}
              onChange={onChange}
              value={value}
              fullWidth
              label={label}
              variant="outlined"
            />
          );
        }
      }}
    />
  );
};

export default FormInputText;
