import { extend } from 'ol/extent';
import { useMapStore } from '../stores/map';
import { highlightItem, useItemsStore } from '../stores/menuItems';
import { useViewStore } from '../stores/view';
import { checkExtentInFrance } from './postionCheck';

/**
 * Fit map view to displayed features
 * @param {import("ol/Feature").default<import("ol/geom/Geometry").default>} feature
 */
const fitToFeature = (feature) => {
  fitToFeatures([feature]);
};

/**
 * Fit map view to displayed features
 * @param {Array<import("ol/Feature").default<import("ol/geom/Geometry").default>>} features
 */
const fitToFeatures = (features) => {
  if (features.length == 0) return;

  // Get extent from features
  let extent = [...features[0].getGeometry().getExtent()];
  features.map((f) => {
    if (checkExtentInFrance(f.getGeometry().getExtent())) {
      extend(extent, f.getGeometry().getExtent());
    }
  });

  // Make the extent bigger to not zoom in too much
  const margin = features.length == 1 ? 500 : 300;

  let newExtent = [...extent];
  newExtent[0] -= margin;
  newExtent[1] -= margin;
  newExtent[2] += margin;
  newExtent[3] += margin;

  fitToExtent(newExtent);
};

/**
 * Fit map view to given extent
 * @param {import("ol/extent").Extent} extent
 */
const fitToExtent = (extent) => {
  // Marge arbitraire pour s'asurer que la carte se recentre sur l'extent si on passe le même extent que précédement
  const margin = Math.random() / 1000;
  let newExtent = [...extent];
  newExtent[0] -= margin;
  newExtent[1] -= margin;
  newExtent[2] += margin;
  newExtent[3] += margin;
  useViewStore.getState().setExtent(newExtent);
};

/**
 * Highlight given feature
 * @param {Number} layerId
 * @param {import("ol/Feature").default<import("ol/geom/Geometry").default>} feature
 * @param {Boolean} center
 */
const highlightFeature = (
  layerId,
  feature,
  center = true,
  scrollToCard = false,
  showPopup = true
) => {
  clearHighlight();

  const layer = useMapStore
    .getState()
    .map.getLayers()
    .getArray()
    .find((l) => l.get('id') === layerId);

  feature.set('highlight', true);
  highlightItem({
    key: feature.get('nanoid'),
    layer,
    feature,
    scrollToCard,
    showPopup,
  });
  if (center) {
    fitToFeature(feature);
  }
};

const highlighLayer = (layerId) => {
  const layer = useMapStore
    .getState()
    .map.getLayers()
    .getArray()
    .find((l) => l.get('id') === layerId);

  highlightItem({ layer });
};

const highlightCard = (layerId, itemId) => {
  const layer = useMapStore
    .getState()
    .map.getLayers()
    .getArray()
    .find((l) => l.get('id') === layerId);

  highlightItem({
    key: itemId,
    layer: layer,
    feature: null,
    scrollToCard: true,
  });
};

const clearHighlight = () => {
  const stateHighlightItem = useItemsStore.getState().highlightItem;
  if (stateHighlightItem && stateHighlightItem.feature && stateHighlightItem.feature != null) {
    stateHighlightItem.feature.unset('highlight');
  }
  highlightItem({});
};

export {
  fitToFeature,
  fitToFeatures,
  fitToExtent,
  highlightFeature,
  highlightCard,
  clearHighlight,
  highlighLayer,
};
