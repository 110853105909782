import chroma from 'chroma-js';
import {
  STATISTIQUE_TYPE_RENDEMENT_A_16,
  STATISTIQUE_TYPE_RENDEMENT_UTILE,
} from '../../../stores/statistique';

const zeroColor = '#A8B4A6';

const richesseScale = chroma
  .scale([
    zeroColor,
    '#9DF9F6',
    '#87A9FD',
    '#7B95F9',
    '#3496FE',
    '#3586DD',
    '#35AC9F',
    '#35D14D',
    '#BEFF35',
    '#E1FF5D',
    '#FFBD35',
    '#FF8134',
    '#FD6335',
    '#FC4335',
  ])
  .domain([0, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 25]);

const rendementScale = chroma
  .scale([
    zeroColor,
    '#9DF9F6',
    '#87A9FD',
    '#7B95F9',
    '#3496FE',
    '#3586DD',
    '#35AC9F',
    '#35D14D',
    '#BEFF35',
    '#E1FF5D',
    '#FFBD35',
    '#FF8134',
    '#FD6335',
    '#FC4335',
  ])
  .domain([0, 10, 20, 30, 40, 50, 60, 70, 80, 90, 100, 150, 175, 200]);

/**
 * Returns scale for the given type
 * @param {String} type
 * @returns {chroma}
 */
const getScale = (type) => {
  if (type == STATISTIQUE_TYPE_RENDEMENT_A_16 || type == STATISTIQUE_TYPE_RENDEMENT_UTILE) {
    return rendementScale;
  }
  return richesseScale;
};

export { zeroColor, getScale };
