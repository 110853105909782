import Feature from 'ol/Feature';
import { GeoJSON } from 'ol/format';

const buildFeature = ({ feature, data }) => {
  const { codeinsee = '', lieudit = '', nom = '', secteur = '' } = data?.lieudit || {};
  const insertFeature = new Feature();
  const format = new GeoJSON({ dataProjection: 'EPSG:3857' });

  insertFeature.setGeometry(feature.getGeometry());
  insertFeature.set('lieudit', lieudit);
  insertFeature.set('nom', nom);
  insertFeature.set('insee_com', codeinsee);
  insertFeature.set('secteur', secteur);

  const featureObj = format.writeFeatureObject(insertFeature);

  // add CRS object
  featureObj.geometry.crs = {
    type: 'name',
    properties: { name: 'EPSG:3857' },
  };

  return featureObj;
};

export { buildFeature };
