import { Fill, Stroke, Style, Text } from 'ol/style';
import hex2rgba from '../../../utils/hex2rgba';
import { props } from '../index';
import { useSecteurStore } from '../../../stores/secteur';
import { useMapStore } from '../../../stores/map';
import { LineString } from 'ol/geom';
import { distance } from 'ol/coordinate';
import { useItemsStore } from '../../../stores/menuItems';

const font = 'bold' + ' ' + '15px' + '/' + '1' + ' ' + 'Arial';
const textStyle = new Text({
  font: font,
  overflow: true,
  fill: new Fill({
    color: '#000',
  }),
  stroke: new Stroke({
    color: '#fff',
    width: 3,
  }),
});

const strokeBorderWidth = 2;
const secteurStyle = new Style({});
const secteurCineticStyle = new Style({
  stroke: new Stroke({
    color: '#ffcc33',
    width: 6,
  }),
});

const borderOrdreStyle = new Style({
  stroke: new Stroke({
    color: 'rbga(0,0,0,0.4)',
    width: 8,
  }),
});

const baseSelectionStyle = new Style({
  fill: new Fill({
    color: '#000000',
  }),
  stroke: new Stroke({
    color: '#000000',
    width: strokeBorderWidth,
  }),
});

const style = (feature, resolution) => {
  const styles = [];
  const zoom = useMapStore.getState().map.getView().getZoomForResolution(resolution);

  const color = useSecteurStore.getState().secteursColor[feature.get('secteur')];
  let rgba = hex2rgba(color.hex(), 1);

  secteurStyle.setFill(
    new Fill({
      color: rgba,
    })
  );
  secteurStyle.setStroke(
    new Stroke({
      color: props.color,
      width: strokeBorderWidth,
    })
  );
  secteurStyle.setText(textStyle);

  if (feature.get('secteur') && zoom > 11) {
    let text = `${feature.get('nom')}`;

    if (zoom > 13) {
      text += `\n${feature.get('groupe')}/${feature.get('ordre')}`;
    }

    secteurStyle.getText().setText(text); // style -> get text style -> set text property
  } else {
    secteurStyle.getText().setText('');
  }
  styles.push(secteurStyle);
  return styles;
};

const selectionStyle = (feature) => {
  const selectedFeature = useItemsStore.getState().highlightItem?.feature;

  if (
    selectedFeature &&
    selectedFeature &&
    feature.get('insee_com') == selectedFeature.get('insee_com') &&
    ((!feature.get('lieudit') && !selectedFeature.get('lieudit')) ||
      feature.get('lieudit') == selectedFeature.get('lieudit'))
  ) {
    return baseSelectionStyle;
  }
  return null;
};

const styleCineticOrdre = (feature, resolution) => {
  const geometry = feature.getGeometry();
  const styles = [borderOrdreStyle, secteurCineticStyle];
  let lastArrowCoordinates = null;

  geometry.forEachSegment(function (start, end) {
    const dx = end[0] - start[0];
    const dy = end[1] - start[1];
    const tan2 = Math.atan2(dy, dx);
    const rotation = tan2 < 0 ? Math.abs(tan2) : Math.PI + (Math.PI - tan2);

    const arrowSize = Math.max(Math.min(25 * resolution, 900), 30); // Taille de la flèche dépendant de la résolution

    // Branche 1 de la flèche
    const arrowSx = arrowSize * Math.cos(rotation + (2 * Math.PI) / 12); // flèche inclinée à 30°
    const arrowSy = arrowSize * Math.sin(rotation + (2 * Math.PI) / 12); // flèche inclinée à 30°
    // Branche 2 de la flèche
    const arrowEx = arrowSize * Math.cos(rotation - (2 * Math.PI) / 12); // flèche inclinée à 30°
    const arrowEy = arrowSize * Math.sin(rotation - (2 * Math.PI) / 12); // flèche inclinée à 30°
    // Pointe de la flèche
    const line = new LineString([start, end]);
    const middle = line.getCoordinateAt(0.5);

    if (lastArrowCoordinates) {
      const dist = distance(middle, lastArrowCoordinates);
      const distWithResolution = (dist * 1.0) / resolution;
      if (distWithResolution < 70) {
        return; // Point trop proche du précédent, on n'affiche pas la flèche
      }
    }

    lastArrowCoordinates = middle;

    // arrows
    styles.push(
      new Style({
        geometry: new LineString([
          [middle[0] - arrowSx, middle[1] + arrowSy],
          middle,
          [middle[0] - arrowEx, middle[1] + arrowEy],
        ]),
        stroke: new Stroke({
          color: 'rbga(0,0,0,0.4)',
          width: 6,
        }),
        zIndex: 0,
      })
    );
  });

  return styles;
};

export { style, selectionStyle, styleCineticOrdre };
