import { isBefore, isValid, isEqual } from "date-fns";

const validateSchedule = (
    dateDebut,
    dateFin,
    heureDebut,
    heureFin,
    joursAffichage
) => {

    // Vérification de la validité des dates et heures.
    if (dateDebut && !isValid(dateDebut)) {
        return "La date de début est invalide.";
    }

    if (dateFin && !isValid(dateFin)) {
        return "La date de fin est invalide.";
    }

    if (heureDebut && !isValid(heureDebut)) {
        return "L'heure de début est invalide.";
    }
    if (heureFin && !isValid(heureFin)) {
        return "L'heure de fin est invalide.";
    }


    // La date de debut doit être <= à la date de fin
    if (dateDebut && dateFin && isValid(dateDebut) && isValid(dateFin)) {
        if (!isBefore(dateDebut, dateFin) && !isEqual(dateDebut, dateFin)) {
            return "La date de début doit être antérieure ou égale à la date de fin.";
        }
    }

    // Si date debut = date fin heure debut < a date de fin
    if(joursAffichage == "_______" && dateDebut && dateFin && heureDebut && heureFin) {
        if (dateDebut && dateFin && isEqual(dateDebut, dateFin)) {
            if (heureDebut && heureFin && !isBefore(heureDebut, heureFin)) {
                return "L'heure de début doit être inférieure à l'heure de fin.";
            }
        }
    }

    // Si date debut = date fin et que heure de fin est le lendemain pas possible
    if(joursAffichage.includes("X") && dateDebut && dateFin && heureDebut && heureFin) {
        if (dateDebut && dateFin && isEqual(dateDebut, dateFin)) {
            if (heureDebut && heureFin && (isBefore(heureFin, heureDebut) || isEqual(heureDebut, heureFin))) {
                return "La date de début et de fin est identique, on ne peut pas avoir une heure de fin le lendemain"
            }
        }
    }


    // Dans mon cas si l'heure de fin est avant l'heure de début cela correspond au lendemain.
    // Si heure début 20h et heure fin 5h on affichera la zone de 20h a 5h du matin le lendemain. 

    // // Si un ou plusieur jours séléctionner heure début < a date de fin
    // if (dateDebut == null && dateFin == null && joursAffichage.includes("X")) {
    //     if (heureDebut && heureFin && !isBefore(heureDebut, heureFin)) {
    //         return "L'heure de début doit être inférieure à l'heure de fin.";
    //     }
    // }


    return "";
};

const validateDefaultSchedule = (
    dateDebut,
    dateFin,
    heureDebut,
    heureFin,
    joursAffichage
) => {

    if (dateDebut === null &&
        dateFin === null &&
        heureDebut === null &&
        heureFin === null &&
        joursAffichage === '_______'
    ) {
        return true;
    }

    return false;
}

export { validateSchedule, validateDefaultSchedule }