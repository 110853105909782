import { Fill, Stroke, Style, Text } from 'ol/style';
import { getScale, zeroColor } from '../scale';
import { useItemsStore } from '../../../stores/menuItems';
import {
  getStatistiqueValue,
  STATISTIQUE_DONNEE_COMMUNE,
  STATISTIQUE_DONNEE_SECTEUR,
  STATISTIQUE_DONNEE_USINE,
  STATISTIQUE_TYPE_RENDEMENT_A_16,
  STATISTIQUE_TYPE_RENDEMENT_UTILE,
  useStatistiqueStore,
} from '../../../stores/statistique';
import { useMapStore } from '../../../stores/map';

const strokeBorderWidth = 1;
const font = 'bold' + ' ' + '12px' + '/' + '1.2' + ' ' + 'Arial';

const baseStyle = new Style({
  fill: new Fill({
    color: zeroColor,
  }),
  stroke: new Stroke({
    color: '#696969',
    width: strokeBorderWidth, // bordure épaisse pour que les contours simplifiés se touchent dans la majorité des cas
  }),
});

const style = (feature) => {
  const type = useStatistiqueStore.getState().type;
  const min = useStatistiqueStore.getState().min;
  const max = useStatistiqueStore.getState().max;
  const value = getStatistiqueValue(feature.getProperties());

  if (value < min || (max != -1 && value > max)) return null;

  const scale = getScale(type);
  baseStyle.getFill().setColor(scale(value));
  //baseStyle.getStroke().setColor(scale(value));

  return baseStyle;
};

const baseSelectionStyle = new Style({
  fill: new Fill({
    color: 'rgba(0, 0, 0, 0.1)',
  }),
  stroke: new Stroke({
    color: 'rgba(0, 0, 0, 1.0)',
    width: strokeBorderWidth,
  }),
});

const selectionStyle = (feature) => {
  const donnee = useStatistiqueStore.getState().donnee;
  const selectedFeature = useItemsStore.getState().highlightItem?.feature;
  let key = 'commune';
  if (donnee == STATISTIQUE_DONNEE_SECTEUR) key = 'secteur';
  if (donnee == STATISTIQUE_DONNEE_USINE) key = 'usl';

  if (selectedFeature && feature.get(key) == selectedFeature.get(key)) {
    return baseSelectionStyle;
  }
  return null;
};

const baseTextStyle = new Style({
  text: new Text({
    font: font,
    overflow: true,
    fill: new Fill({
      color: '#000',
    }),
    stroke: new Stroke({
      color: '#fff',
      width: 3,
    }),
  }),
});

const textStyle = (feature, resolution) => {
  const zoom = useMapStore.getState().map.getView().getZoomForResolution(resolution);
  const type = useStatistiqueStore.getState().type;
  const donnee = useStatistiqueStore.getState().donnee;
  const min = useStatistiqueStore.getState().min;
  const max = useStatistiqueStore.getState().max;
  const value = getStatistiqueValue(feature.getProperties());

  if (value < min || (max != -1 && value > max)) return null;

  if (donnee != STATISTIQUE_DONNEE_COMMUNE || !value) {
    return null;
  }

  const unit =
    type == STATISTIQUE_TYPE_RENDEMENT_UTILE || type == STATISTIQUE_TYPE_RENDEMENT_A_16
      ? 'T/Ha'
      : '%';

  if (zoom > 11) {
    const secteur = feature.get('secteur') ? ` (${feature.get('secteur')})` : '';
    baseTextStyle.getText().setText(`${feature.get('nomcommune')}${secteur}\n${value}${unit}`);
    return baseTextStyle;
  } else {
    return null;
  }
};

export { style, selectionStyle, textStyle };
