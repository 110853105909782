import { Circle as CircleStyle, Fill, Stroke, Style, Text, Icon } from 'ol/style';
import LineString from 'ol/geom/LineString';
import { parse, format } from 'date-fns';
import { props } from '../../../features/silo';
import { statutBach, statutNiv } from '../enums';
//import { toSize } from 'ol/size';
import { getViewZoom } from '../../../stores/view';
import { imageBasePath } from '../../../utils/image';
import { siloImageSrc } from '../icon';
import { distance } from 'ol/coordinate';

const font = 'bold' + ' ' + '16px' + '/' + '1' + ' ' + 'Arial';

const circuitLineStyleBorder = new Style({
  zIndex: 1,
  stroke: new Stroke({
    width: 6,
    color: 'rbga(0,0,0,0.4)',
  }),
});

const circuitLineStyle = new Style({
  zIndex: 2,
  stroke: new Stroke({
    width: 5,
    color: '#FBC117',
  }),
});

// default silo style
const style = (feature) => {
  const features = feature.get('features') ? feature.get('features') : [feature];
  const colorSilo = props.color ? props.color : '#3399CC';
  let arrayStyleSilo = [];

  if (features.length > 1) {
    // cluster feature
    let styleCluster = new Style({
      image: new Icon({
        scale: 1,
        src: siloImageSrc(features),
      }),
      text: new Text({
        text: features.length.toString(),
        fill: new Fill({
          color: '#000000',
        }),
        stroke: new Stroke({
          color: '#ffffff',
          width: 3,
        }),
        font: font,
        scale: 1,
        offsetY: 26,
      }),
    });
    //reSizeIcon(styleCluster);
    arrayStyleSilo.push(styleCluster);
  } else {
    const opacity = features[0].get('opacity') ? features[0].get('opacity') : 1.0;

    // single feature
    if (
      parseInt(features[0].get('etiqPosX')) !== 0 &&
      parseInt(features[0].get('etiqPosY')) !== 0
    ) {
      // silo doublon
      // draw linestring between the 2 features by return 2 style in an array (one for the linestring, and one for the feature)
      const startPoint = [parseInt(features[0].get('posX')), parseInt(features[0].get('posY'))];
      const endPoint = [
        parseInt(features[0].get('etiqPosX')),
        parseInt(features[0].get('etiqPosY')),
      ];

      return [
        new Style({
          geometry: new LineString([startPoint, endPoint]),
          stroke: new Stroke({
            color: colorSilo,
            lineDash: [10, 10],
            width: 4,
          }),
        }),
        new Style({
          image: new Icon({
            scale: 1,
            src: siloImageSrc(features),
            opacity: opacity,
          }),
          text: new Text({
            text:
              (features[0].get('ordre') && parseInt(features[0].get('ordre')) > 0
                ? parseInt(features[0].get('ordre')) + ' - '
                : '') + parseInt(features[0].get('codePlanteur').substring(0, 7), 10),
            fill: new Fill({
              color: 'rgba(230, 40, 40, ' + opacity + ')',
            }),
            stroke: new Stroke({
              color: 'rgba(255, 255, 255, ' + opacity + ')',
              width: 3,
            }),
            offsetY: -24,
            font: font,
          }),
        }),
      ];
    } else {
      // default silo feature
      let defaultStyle = new Style({
        image: new Icon({
          scale: 1,
          src: siloImageSrc(features),
          opacity: opacity,
        }),
        text: new Text({
          text:
            (features[0].get('ordre') && parseInt(features[0].get('ordre')) > 0
              ? parseInt(features[0].get('ordre')) + ' - '
              : '') + parseInt(features[0].get('codePlanteur').substring(0, 7), 10),
          fill: new Fill({
            color: 'rgba(230, 40, 40, ' + opacity + ')',
          }),
          stroke: new Stroke({
            color: 'rgba(255, 255, 255, ' + opacity + ')',
            width: 3,
          }),
          offsetY: -24,
          font: font,
        }),
        zIndex: 3,
      });

      arrayStyleSilo.push(defaultStyle);
    }
  }

  return arrayStyleSilo;
};

const styleCircuit = (feature, resolution) => {
  const geometry = feature.getGeometry();
  const styles = [circuitLineStyleBorder, circuitLineStyle];
  let lastArrowCoordinates = null;

  geometry.forEachSegment(function (start, end) {
    const dx = end[0] - start[0];
    const dy = end[1] - start[1];
    const tan2 = Math.atan2(dy, dx);
    const rotation = tan2 < 0 ? Math.abs(tan2) : Math.PI + (Math.PI - tan2);

    const arrowSize = Math.max(Math.min(16 * resolution, 900), 30); // Taille de la flèche dépendant de la résolution

    // Branche 1 de la flèche
    const arrowSx = arrowSize * Math.cos(rotation + (2 * Math.PI) / 10); // flèche inclinée à 30°
    const arrowSy = arrowSize * Math.sin(rotation + (2 * Math.PI) / 10); // flèche inclinée à 30°
    // Branche 2 de la flèche
    const arrowEx = arrowSize * Math.cos(rotation - (2 * Math.PI) / 10); // flèche inclinée à 30°
    const arrowEy = arrowSize * Math.sin(rotation - (2 * Math.PI) / 10); // flèche inclinée à 30°
    // Pointe de la flèche
    const line = new LineString([start, end]);
    const middle = line.getCoordinateAt(0.5);

    if (lastArrowCoordinates) {
      const dist = distance(middle, lastArrowCoordinates);
      const distWithResolution = (dist * 1.0) / resolution;
      if (distWithResolution < 70) {
        return; // Point trop proche du précédent, on n'affiche pas la flèche
      }
    }

    lastArrowCoordinates = middle;

    // arrows
    styles.push(
      new Style({
        geometry: new LineString([
          [middle[0] - arrowSx, middle[1] + arrowSy],
          middle,
          [middle[0] - arrowEx, middle[1] + arrowEy],
        ]),
        stroke: new Stroke({
          color: 'rbga(0,0,0,0.4)',
          width: 5,
        }),
        zIndex: 0,
      }),
      new Style({
        geometry: new LineString([
          [middle[0] - arrowSx, middle[1] + arrowSy],
          middle,
          [middle[0] - arrowEx, middle[1] + arrowEy],
        ]),
        stroke: new Stroke({
          color: '#FBC117',
          width: 4,
        }),
        zIndex: 1,
      })
    );
  });

  return styles;
};

// silo encodage style
const styleEncodage = (feature) => {
  const features = feature.get('features');
  const colorSilo = features[0].get('encodagecolor') ? features[0].get('encodagecolor') : '#FF3030';

  if (features.length > 1) {
    // cluster feature
    let styleCluster = new Style({
      image: new Icon({
        scale: 1,
        src: siloImageSrc(features),
      }),
      text: new Text({
        text: features.length.toString(),
        fill: new Fill({
          color: '#fff',
        }),
        stroke: new Stroke({
          color: '#ffffff',
          width: 3,
        }),
        font: font,
        offsetY: 26,
      }),
    });
    //reSizeIcon(styleCluster);
    return styleCluster;
  } else {
    // single feature
    const htg = format(parse(features[0].get('htg'), 'HHmmss', new Date()), 'HH:mm:ss');
    let defaultStyle = new Style({
      image: new Icon({
        scale: 1,
        src: siloImageSrc(features),
        color: colorSilo,
      }),
      text: new Text({
        text: htg + '-' + features[0].get('ordrechargement'),
        fill: new Fill({
          color: colorSilo,
        }),
        stroke: new Stroke({
          color: '#ffffff',
          width: 3,
        }),
        offsetY: -24,
        font: font,
      }),
    });
    //reSizeIcon(defaultStyle);
    return defaultStyle;
  }
};
const getImageSrc = (feature) => {
  const mapStatut = [...statutBach, ...statutNiv];
  const imageSrc = mapStatut.find((i) => i.code === feature.get('statut'));
  return imageSrc;
};

const styleNivBach = (feature) => {
  const features = feature.get('features');
  let finalStyle = [];

  const featureStyle = (highlight, imageSrc) => {
    return new Style({
      image: imageSrc
        ? new Icon({
            scale: 1,
            src: imageBasePath() + imageSrc.src,
          })
        : new CircleStyle({
            radius: 10,
            stroke: new Stroke({
              color: '#fff',
            }),
            fill: new Fill({
              color: highlight,
            }),
          }),
      text:
        getViewZoom() > 12
          ? new Text({
              text: '' + parseInt(features[0].get('cpcu').substring(0, 7), 10),
              fill: new Fill({
                color: '#D62828',
              }),
              stroke: new Stroke({
                color: '#ffffff',
                width: 3,
              }),
              offsetY: -24,
              font: font,
            })
          : '',
    });
  };

  const clusterStyle = (features, imageSrc) => {
    let colorFill = '#000000';
    return new Style({
      image: imageSrc
        ? new Icon({
            scale: 1,
            src: imageBasePath() + imageSrc.src,
          })
        : new Icon({
            scale: 1,
            src: siloImageSrc(features),
          }),
      text: new Text({
        text: features.length.toString(),
        fill: new Fill({
          color: colorFill,
        }),
        stroke: new Stroke({
          color: '#ffffff',
          width: 3,
        }),
        font: font,
        scale: 1,
        textBaseline: 'middle',
        offsetY: 26,
      }),
    });
  };

  const featureLieeStyle = (colorSilo) => {
    const startPoint = [parseInt(features[0].get('gpslo')), parseInt(features[0].get('gpsla'))];
    const endPoint = [
      parseInt(features[0].get('longitudesilo')),
      parseInt(features[0].get('latitudesilo')),
    ];

    return new Style({
      geometry: new LineString([startPoint, endPoint]),
      stroke: new Stroke({
        color: colorSilo,
        lineDash: [10, 10],
        width: 4,
      }),
    });
  };

  if (features.length > 1) {
    let featuresFiltered = {};
    features.forEach((feature) => {
      const statut = feature.get('statut');
      if (!featuresFiltered[statut]) {
        featuresFiltered[statut] = [];
      }
      featuresFiltered[statut].push(feature);
    });

    Object.values(featuresFiltered).forEach((features) => {
      const imageSrc = getImageSrc(features[0]);
      let style;
      if (Object.values(featuresFiltered).length > 1) {
        style = clusterStyle(Object.values(featuresFiltered), null);
      } else {
        style = clusterStyle(features, imageSrc);
      }
      //reSizeIcon(style);
      finalStyle.push(style);
    });
  } else {
    const imageSrc = getImageSrc(features[0]);
    const colorSilo = props.color ? props.color : '#3399CC';
    const highlight = features[0].get('highlight') ? '#BADA55' : colorSilo;
    if (
      parseInt(features[0].get('longitudesilo')) !== 0 &&
      parseInt(features[0].get('latitudesilo')) !== 0
    ) {
      // silo doublon
      // draw linestring between the 2 features by return 2 style in an array (one for the linestring, and one for the feature)
      const lineStringStyle = featureLieeStyle(colorSilo);
      const nivBachStyle = featureStyle(highlight, imageSrc);
      //reSizeIcon(nivBachStyle);
      finalStyle.push(lineStringStyle, nivBachStyle);
    } else {
      const nivBachStyle = featureStyle(highlight, imageSrc);
      //reSizeIcon(nivBachStyle);
      finalStyle.push(nivBachStyle);
    }
  }

  return finalStyle;
};

export { style, styleCircuit, styleEncodage, styleNivBach };
