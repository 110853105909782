import Feature from 'ol/Feature';
import { GeoJSON } from 'ol/format';
import { format } from 'date-fns';

const createGeometry = async ({ feature, data }) => {
  const insertFeature = new Feature();
  insertFeature.setGeometry(feature.getGeometry());
  insertFeature.set('type_zone', data.typezone ? data.typezone : null);
  insertFeature.set('commentaire', data.comment ? data.comment : null);
  insertFeature.set(
    'date_debut',
    data.datedebut ? format(data.datedebut, 'yyyy-MM-dd') : null
  );
  insertFeature.set(
    'heure_debut',
    data.heuredebut ? format(data.heuredebut, 'HH:mm:ss') : null
  );
  insertFeature.set(
    'date_fin',
    data.datefin ? format(data.datefin, 'yyyy-MM-dd') : null
  );

  insertFeature.set(
    'heure_fin',
    data.heurefin ? format(data.heurefin, 'HH:mm:ss') : null
  );  
  insertFeature.set('jours_affichage', data.period);
  insertFeature.set('extras', data.extras ? data.extras : null);

  // create feature collection GeoJSON
  const featureCollection = new GeoJSON({ dataProjection: 'EPSG:3857' }).writeFeaturesObject([
    insertFeature,
  ]);

  // add CRS object for each feature geometry
  featureCollection.features.forEach((f) => {
    f.geometry.crs = {
      type: 'name',
      properties: { name: 'EPSG:3857' },
    };
  });

  return featureCollection;
};

const updateGeometry = async ({ feature }) => {
  const updateFeature = new Feature();

  updateFeature.setId(feature.getId());
  updateFeature.setGeometry(feature.getGeometry());
  updateFeature.setProperties(feature.getProperties());

  const featureCollection = new GeoJSON({ dataProjection: 'EPSG:3857' }).writeFeaturesObject([
    updateFeature,
  ]);

  // add CRS object for each feature geometry
  featureCollection.features.forEach((f) => {
    f.geometry.crs = {
      type: 'name',
      properties: { name: 'EPSG:3857' },
    };
  });

  return featureCollection;
};

const updateGeometryProperties = async ({ feature, data }) => {
  const updateFeature = new Feature();

  updateFeature.setId(feature.getId());
  updateFeature.setGeometry(feature.getGeometry());
  updateFeature.setProperties(feature.getProperties());
  updateFeature.set('type_zone', data.typezone ? data.typezone : null);
  updateFeature.set('commentaire', data.comment ? data.comment : null);
  updateFeature.set(
    'date_debut',
    data.datedebut ? format(data.datedebut, 'yyyy-MM-dd') : null
  );
  updateFeature.set(
    'heure_debut',
    data.heuredebut ? format(data.heuredebut, 'HH:mm:ss') : null
  );
  updateFeature.set(
    'date_fin',
    data.datefin ? format(data.datefin, 'yyyy-MM-dd') : null
  );

  updateFeature.set(
    'heure_fin',
    data.heurefin ? format(data.heurefin, 'HH:mm:ss') : null
  );  
  updateFeature.set('jours_affichage', data.period);
  updateFeature.set('extras', data.extras ? data.extras : null);
  const featureCollection = new GeoJSON({ dataProjection: 'EPSG:3857' }).writeFeaturesObject([
    updateFeature,
  ]);

  // add CRS object for each feature geometry
  featureCollection.features.forEach((f) => {
    f.geometry.crs = {
      type: 'name',
      properties: { name: 'EPSG:3857' },
    };
  });

  return featureCollection;
};

export { createGeometry, updateGeometry, updateGeometryProperties};
