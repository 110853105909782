import { format, isEqual, isValid } from 'date-fns';
import { isBefore } from 'date-fns';
import { fr } from 'date-fns/locale';

const getDay = (jourAffichage) => {
  if (jourAffichage == 'XXXXXXX' || jourAffichage == '_______') {
    return 'tous les jours';
  } else {
    const weekDay = ['lundi', 'mardi', 'mercredi', 'jeudi', 'vendredi', 'samedi', 'dimanche'];

    const joursSelect = weekDay.filter((_, index) => jourAffichage[index] === 'X').join(', ');

    if (joursSelect != '') {
      return 'tous les ' + joursSelect;
    }

    return '';
  }
};

const formatedHour = (heure) => {
  const isValidHour = isValid(heure);
  if (isValidHour) {
    return format(heure, 'HH:mm', { locale: fr });
  }
  return '';
};

const formatedDate = (date) => {
  const isValidDate = isValid(date);
  if (isValidDate) {
    return format(date, 'dd MMMM yyyy', { locale: fr });
  }
  return '';
};

const getHeure = (heureDebut, heureFin, lendemain) => {
  if (heureDebut != '' && heureFin != '') {
    return `de ${heureDebut} à ${heureFin} ${lendemain ? `(le lendemain)` : ``}`;
  } else if (heureDebut != '' && heureFin == '') {
    return `à partir de ${heureDebut} à minuit`;
  } else if (heureDebut == '' && heureFin != '') {
    return `de minuit à ${heureFin}`;
  }

  return '';
};

const getDate = (dateDebut, dateFin) => {
  if (dateDebut != '' && dateFin != '') {
    return `du ${dateDebut} au ${dateFin}`;
  } else if (dateDebut != '' && dateFin == '') {
    return `à partir du ${dateDebut}`;
  } else if (dateDebut == '' && dateFin != '') {
    return `jusqu'au ${dateFin}`;
  }
  return '';
};

const isLendemain = (heureDebut, heureFin) => {
  if (!isValid(heureDebut) || !isValid(heureFin)) {
    return false;
  }
  return isBefore(heureFin, heureDebut) || isEqual(heureDebut, heureFin);
};

const displaySchedule = (dateDebut, dateFin, heureDebut, heureFin, joursAffichage) => {
  const day = getDay(joursAffichage);
  const formatHeureDebut = formatedHour(heureDebut);
  const formatHeureFin = formatedHour(heureFin);
  const formatDateDebut = formatedDate(dateDebut);
  const formatDateFin = formatedDate(dateFin);

  if( joursAffichage == "_______" && 
    formatDateDebut != "" && 
    formatDateFin != "" && 
    formatHeureDebut != "" && 
    formatHeureFin != "" ) {
        // Pour les durées a partir du date + heure de début et de fin 
        return `La zone de contournement sera active à partir du ${formatDateDebut} à ${formatHeureDebut} au ${formatDateFin} à ${formatHeureFin}`;
  }

  const lendemain = isLendemain(heureDebut, heureFin);
  let message = `La zone de contournement sera active ${day}
  ${getHeure(
    formatHeureDebut,
    formatHeureFin,
    lendemain
  )}
  ${getDate(formatDateDebut, formatDateFin)}`;
  message = message.trim() + '.';
  return message;


};

const displayDefaultSchedule = () => {
  return 'La zone de contournement sera toujours active.';
};

export { displaySchedule, displayDefaultSchedule };
