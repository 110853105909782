import { Fill, Stroke, Style, Text } from 'ol/style';
import { getScale, zeroColor } from '../scale';
import { useItemsStore } from '../../../stores/menuItems';
import {
  getSurfaceValue,
  SURFACE_DONNEE_COMMUNE,
  SURFACE_DONNEE_SECTEUR,
  SURFACE_DONNEE_USINE,
  useSurfaceStore,
} from '../../../stores/surface';
import { useMapStore } from '../../../stores/map';

const strokeBorderWidth = 1;
const font = 'bold' + ' ' + '12px' + '/' + '1.2' + ' ' + 'Arial';

const baseStyle = new Style({
  fill: new Fill({
    color: zeroColor,
  }),
  stroke: new Stroke({
    color: '#696969',
    width: strokeBorderWidth,
  }),
});

const style = (feature) => {
  const surfaceState = useSurfaceStore.getState();
  const value = getSurfaceValue(feature.getProperties());
  const donnee = useSurfaceStore.getState().donnee;

  let key = 'codeinsee';
  if (donnee == SURFACE_DONNEE_SECTEUR) key = 'secteur';
  if (donnee == SURFACE_DONNEE_USINE) key = 'usl';

  if (!feature.get(key)) {
    return null;
  }

  if (value < surfaceState.min || (surfaceState.max != -1 && value > surfaceState.max)) return null;

  const scale = getScale(surfaceState);
  baseStyle.getFill().setColor(scale(value));
  //baseStyle.getStroke().setColor(scale(value));
  baseStyle.setZIndex(feature.get('lieudit') ? 1 : 0);
  return baseStyle;
};

const baseSelectionStyle = new Style({
  fill: new Fill({
    color: 'rgba(0, 0, 0, 0.1)',
  }),
  stroke: new Stroke({
    color: 'rgba(0, 0, 0, 1.0)',
    width: strokeBorderWidth,
  }),
});

const selectionStyle = (feature) => {
  const donnee = useSurfaceStore.getState().donnee;
  const selectedFeature = useItemsStore.getState().highlightItem?.feature;

  if (donnee == SURFACE_DONNEE_COMMUNE) {
    if (
      selectedFeature &&
      feature.get('codeinsee') == selectedFeature.get('codeinsee') &&
      ((!feature.get('lieudit') && !selectedFeature.get('lieudit')) ||
        feature.get('lieudit') == selectedFeature.get('lieudit'))
    ) {
      return baseSelectionStyle;
    }
    return null;
  } else {
    const key = donnee == SURFACE_DONNEE_SECTEUR ? 'secteur' : 'usl';
    if (selectedFeature && feature.get(key) && feature.get(key) == selectedFeature.get(key)) {
      return baseSelectionStyle;
    }
    return null;
  }
};

const baseTextStyle = new Style({
  text: new Text({
    font: font,
    overflow: true,
    fill: new Fill({
      color: '#000',
    }),
    stroke: new Stroke({
      color: '#fff',
      width: 3,
    }),
  }),
});

const textStyle = (feature, resolution) => {
  const zoom = useMapStore.getState().map.getView().getZoomForResolution(resolution);
  const surfaceState = useSurfaceStore.getState();
  const value = getSurfaceValue(feature.getProperties());
  const donnee = useSurfaceStore.getState().donnee;

  if (donnee != SURFACE_DONNEE_COMMUNE) {
    return null;
  }

  if (value < surfaceState.min || (surfaceState.max != -1 && value > surfaceState.max)) return null;

  if (zoom > 11) {
    const lieudit =
      feature.get('lieudit') && feature.get('lieudit') != '00'
        ? ` - ${feature.get('lieudit')}`
        : '';
    const secteur = feature.get('secteur') ? ` (${feature.get('secteur')})` : '';
    baseTextStyle.getText().setText(`${feature.get('nom')}${lieudit}${secteur}\n${value}Has`);
    return baseTextStyle;
  } else {
    return null;
  }
};

export { style, selectionStyle, textStyle };
