import { getArea } from 'ol/sphere';
import Feature from 'ol/Feature';
import { GeoJSON, WKT } from 'ol/format';
import WKTReader from 'jsts/org/locationtech/jts/io/WKTReader';
import Centroid from 'jsts/org/locationtech/jts/algorithm/Centroid';
import { tableName } from '../enums';

const computeCentroid = ({ feature }) => {
  const format = new WKT();
  const reader = new WKTReader();

  const jstsGeom = reader.read(format.writeFeature(feature, { featureProjection: 'EPSG:3857' }));
  const centroid = Centroid.getCentroid(jstsGeom);
  return [centroid.x, centroid.y];
};

const createGeometry = ({ feature, data }) => {
  const insertFeature = new Feature();

  insertFeature.setGeometry(feature.getGeometry());
  insertFeature.set('nom_couvert', data.nomparcelle);
  insertFeature.set('code_planteur', data.codeplanteur.cpcu || '');
  insertFeature.set('code_insee', data.codeinsee);
  insertFeature.set('surf_calc', data.surfcalc);
  insertFeature.set('surf_reelle', parseFloat(data.surfreelle.replace(',', '.')));
  insertFeature.set('code_cultu', data.culture);

  // create feature collection GeoJSON
  const featureCollection = new GeoJSON({
    dataProjection: 'EPSG:3857',
  }).writeFeaturesObject([insertFeature]);

  // add CRS object for each feature geometry
  featureCollection.features.forEach((f) => {
    f.geometry.crs = {
      type: 'name',
      properties: { name: 'EPSG:3857' },
    };
  });

  return featureCollection;
};

const buildInsertFeature = (feature, data, natpro, campagne) => {
  const insertFeature = new Feature();
  const format = new GeoJSON({ dataProjection: 'EPSG:3857' });
  const centroid = computeCentroid({ feature });
  let table = tableName[natpro];

  if (natpro !== 'T') {
    table += '_' + campagne;
  }

  insertFeature.setId(table + '.' + feature.getId().substring(0, 9));
  insertFeature.setGeometry(feature.getGeometry());
  insertFeature.set('culture', data.culture.code || '');
  insertFeature.set('natpro', natpro == 'T' ? '' : data.natpro);
  insertFeature.set('surf_calc', (getArea(feature.getGeometry()) / 10000).toFixed(2));

  if (data.selparcelle) {
    insertFeature.set('planteur', data.selparcelle.cpcu);
    insertFeature.set('codeparc', data.selparcelle.parcelle);
    insertFeature.set('nomparcelle', data.selparcelle.nomparcelle);
    insertFeature.set('numeroilot', data.selparcelle.numilot);
    insertFeature.set('communeparc', data.selparcelle.commparcelle);
  } else {
    insertFeature.set('planteur', data.codeplanteur.cpcu);
    insertFeature.set('nomparcelle', data.nomparcelle);
    insertFeature.set('numeroilot', data.numilot);
    insertFeature.set('communeparc', data.codeinsee);
  }

  const featureObj = format.writeFeatureObject(insertFeature);

  featureObj.properties.longitudeparc = Math.floor(centroid[0]).toString().padStart(8, '0');
  featureObj.properties.latitudeparc = Math.floor(centroid[1]).toString().padStart(8, '0');

  // add CRS object
  featureObj.geometry.crs = {
    type: 'name',
    properties: { name: 'EPSG:3857' },
  };

  return featureObj;
};

const buildUpdateFeature = (feature) => {
  const updateFeature = new Feature();
  const format = new GeoJSON({ dataProjection: 'EPSG:3857' });
  const centroid = computeCentroid({ feature });

  updateFeature.setId(feature.getId());
  updateFeature.setGeometry(feature.getGeometry());
  updateFeature.setProperties(feature.getProperties());
  updateFeature.set('surf_calc', (getArea(feature.getGeometry()) / 10000).toFixed(2));

  const featureObj = format.writeFeatureObject(updateFeature);

  featureObj.properties.longitudeparc = Math.floor(centroid[0]).toString().padStart(8, '0');
  featureObj.properties.latitudeparc = Math.floor(centroid[1]).toString().padStart(8, '0');

  // add CRS object
  featureObj.geometry.crs = {
    type: 'name',
    properties: { name: 'EPSG:3857' },
  };

  return featureObj;
};

export { createGeometry, buildInsertFeature, buildUpdateFeature, computeCentroid };
