import React, { useEffect, useState } from 'react';
import './App.css';
import 'yet-another-react-lightbox/styles.css';
import 'yet-another-react-lightbox/plugins/captions.css';
import 'yet-another-react-lightbox/plugins/thumbnails.css';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import AreaSearchButton from './components/AreaSearchButton';
import Map from './components/Map';
import SearchBox from './components/SearchBox';
import Layers from './components/Layers';
import SideBar from './components/SideBar';
import { Paper } from '@mui/material';
import ImageDialog from './components/ImageDialog';
import WaitDialog from './components/WaitDialog';
import AlertDialog from './components/AlertDialog';
import GeolocationButton from './components/GeolocationButton';
import Timeline from './components/Timeline';
import Scale from './features/meteo/components/Scale';
import ScaleStatistique from './features/statistique/components/Scale';
import LayerSelectorDialog from './components/LayerSelectorDialog';
import ScaleSurface from './features/surface/components/Scale';
import { useRootComponentsStore } from './stores/rootComponents';
import { Slide, ToastContainer } from 'react-toastify';

function App() {
  const components = useRootComponentsStore((state) => state.items);
  const [rootComponents, setRootComponents] = useState([]);

  useEffect(() => {
    if (components) {
      setRootComponents(components);
    }
  }, [components]);

  return (
    <Paper>
      <AreaSearchButton />
      <GeolocationButton />
      <SideBar />
      <Map />
      <SearchBox />
      <Layers />
      <Timeline />
      <Scale />
      <ScaleStatistique />
      <ScaleSurface />
      <ImageDialog />
      <WaitDialog />
      <AlertDialog />
      <LayerSelectorDialog />

      {rootComponents.map(({ Comp, props }, index) => {
        return <Comp key={index} {...props} />;
      })}

      <ToastContainer
        position={'top-right'}
        autoClose={false}
        newestOnTop={false}
        closeOnClick={false}
        closeButton={false}
        theme="colored"
        draggable="false"
        transition={Slide}
      />
    </Paper>
  );
}

export default App;
