import { create } from 'zustand';
import { subscribeWithSelector } from 'zustand/middleware';

const SURFACE_ENQUETE_BETTERAVE = 'surfaceEnqueteBetterave';
const SURFACE_PLANNING_BETTERAVE = 'surfacePlanningBetterave';
const SURFACE_ENQUETE_LUZERNE = 'surfaceEnqueteLuzerne';

const SURFACE_DONNEE_COMMUNE = 'commune';
const SURFACE_DONNEE_SECTEUR = 'secteur';
const SURFACE_DONNEE_USINE = 'usine';

const getSurfaceValue = (featureProperties, typeSurface, donneeStat) => {
  const type = typeSurface ?? useSurfaceStore.getState().type;
  const donnee = donneeStat ?? useSurfaceStore.getState().donnee;

  if (donnee == SURFACE_DONNEE_SECTEUR) {
    const secteurData = featureProperties['secteurData'];
    if (!secteurData) return null;
    return Math.round(secteurData[type]); // les données sont calculées dans la feature et déjà en numérique
  }

  if (donnee == SURFACE_DONNEE_USINE) {
    const secteurData = featureProperties['usineData'];
    if (!secteurData) return null;
    return Math.round(secteurData[type]); // les données sont calculées dans la feature et déjà en numérique
  }

  return parseFloat(featureProperties[type]) / 100.0;
};

const useSurfaceStore = create(
  subscribeWithSelector(() => ({
    type: SURFACE_ENQUETE_BETTERAVE,
    donnee: SURFACE_DONNEE_COMMUNE,
    maxCumulBetteraveSecteur: 0,
    maxCumulBetteraveUsine: 0,
    maxCumulLuzerneUsine: 0,
    min: -1,
    max: -1,
  }))
);

const setSurfaceType = (type) => {
  useSurfaceStore.setState((state) => ({
    type,
    min: -1,
    max: -1,
    donnee:
      type == SURFACE_ENQUETE_LUZERNE && state.donnee != SURFACE_DONNEE_COMMUNE
        ? SURFACE_DONNEE_COMMUNE
        : state.donnee,
  }));
};

const setSurfaceMinMax = (min, max) => {
  useSurfaceStore.setState(() => ({ min, max }));
};

const setSurfaceMaxCumul = (
  maxCumulBetteraveSecteur,
  maxCumulBetteraveUsine,
  maxCumulLuzerneUsine
) => {
  useSurfaceStore.setState(() => ({
    maxCumulBetteraveSecteur,
    maxCumulBetteraveUsine,
    maxCumulLuzerneUsine,
  }));
};

const setSurfaceDonnee = (donnee) => {
  useSurfaceStore.setState(() => ({ donnee }));
};

export {
  getSurfaceValue,
  useSurfaceStore,
  setSurfaceType,
  setSurfaceMinMax,
  setSurfaceMaxCumul,
  setSurfaceDonnee,
  SURFACE_ENQUETE_BETTERAVE,
  SURFACE_PLANNING_BETTERAVE,
  SURFACE_ENQUETE_LUZERNE,
  SURFACE_DONNEE_COMMUNE,
  SURFACE_DONNEE_SECTEUR,
  SURFACE_DONNEE_USINE,
};
