import { axios } from '../../../lib/axios';

const getContourCommunesSecteur = async () => {
  const response = await axios.get(
    `/parcelles-api/communes/secteur`
    // `http://localhost:8080/communes/secteur`
  );
  return response;
};

const getSurfaceCommunes = async (campagne) => {
  const response = await axios.get(`/agronomie/surfacescommunes?campagne=${campagne}`);
  return response?.agronomie;
};

export { getContourCommunesSecteur, getSurfaceCommunes };
