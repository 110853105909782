import { useState, useEffect } from 'react';
import { useLayerStore } from '../stores/layers';
import TileLayer from 'ol/layer/Tile';
import VectorLayer from 'ol/layer/Vector';
import VectorTileLayer from 'ol/layer/VectorTile';
import TileWMS from 'ol/source/TileWMS';
import TileGrid from 'ol/tilegrid/TileGrid';
import XYZ from 'ol/source/XYZ';
import { get as getProjection } from 'ol/proj';
import VectorImageLayer from 'ol/layer/VectorImage';

const useLayers = () => {
  const stateVectorLayers = useLayerStore((state) => state.vector);
  const stateRasterLayers = useLayerStore((state) => state.raster);
  const [vectorLayers, setVectorLayers] = useState([]); // maintain OpenLayers vector layers array
  const [rasterLayers, setRasterLayers] = useState([]); // maintain OpenLayers raster layers array

  const toWMS = (layer) => {
    return new TileWMS({
      url: layer.url,
      params: layer.params,
      serverType: layer.serverType,
    });
  };

  const toXYZ = (layer) => {
    return new XYZ({
      url: layer.url,
      tileGrid: new TileGrid({
        extent: getProjection('EPSG:3857').getExtent(),
        resolutions: layer.resolutions,
      }),
    });
  };

  const toTileLayer = ({ layer, source }) => {
    return new TileLayer({
      id: layer.id,
      parentId: layer.parentId,
      name: layer.title,
      state: layer.state,
      opacity: layer.opacity,
      visible: layer.visible,
      source: source(layer),
    });
  };

  const toVectorLayer = ({ layer }) => {
    return new VectorLayer({
      // zIndex: Infinity, // in order to be above Mapbox layers
      id: layer.id,
      parentId: layer.parentId,
      name: layer.title,
      state: layer.state,
      opacity: layer.opacity,
      visible: layer.visible,
      source: layer.source,
      style: layer.style, // in order to bind when set layer style (cf useMap, update vector layer)
    });
  };

  const toVectorImageLayer = ({ layer }) => {
    return new VectorImageLayer({
      // zIndex: Infinity, // in order to be above Mapbox layers
      id: layer.id,
      parentId: layer.parentId,
      name: layer.title,
      state: layer.state,
      opacity: layer.opacity,
      visible: layer.visible,
      source: layer.source,
      imageRatio: layer.imageRatio,
      style: layer.style, // in order to bind when set layer style (cf useMap, update vector layer)
    });
  };

  const toVectorTileLayer = ({ layer }) => {
    return new VectorTileLayer({
      // zIndex: Infinity, // in order to be above Mapbox layers
      id: layer.id,
      parentId: layer.parentId,
      name: layer.title,
      state: layer.state,
      opacity: layer.opacity,
      visible: layer.visible,
      source: layer.source,
      background: layer.background,
      renderMode: layer.renderMode,
      style: layer.style, // in order to bind when set layer style (cf useMap, update vector layer)
    });
  };

  // create OpenLayers raster layer instance
  useEffect(() => {
    const newLayers = stateRasterLayers
      .filter((layer) => layer.state !== 'ADDED') // because we filter 'ADDED' state from raster layers array store, this layer's state is setted in useMap
      .map((layer) => {
        switch (layer.type) {
          case 'WMS':
            return toTileLayer({ layer, source: toWMS });
          case 'XYZ':
            return toTileLayer({ layer, source: toXYZ });
          default:
            return undefined;
        }
      });
    if (newLayers.length > 0) {
      if (rasterLayers.length == 0) {
        setRasterLayers(newLayers);
      } else {
        const updatedRasterLayers = rasterLayers.map((l) => {
          const exists = newLayers.find((e) => e.get('id') === l.get('id'));
          return exists || l;
        });
        setRasterLayers(updatedRasterLayers);
      }
    }
    // eslint-disable-next-line
  }, [stateRasterLayers]);

  // create OpenLayers vector layer instance
  useEffect(() => {
    const newLayers = stateVectorLayers
      .filter((layer) => layer.state === 'ADD')
      .map((layer) => {
        switch (layer.type) {
          case 'Vector':
            return toVectorLayer({ layer });
          case 'VectorImage':
            return toVectorImageLayer({ layer });
          case 'VectorTile':
            return toVectorTileLayer({ layer });
          default:
            return undefined;
        }
      });
    if (newLayers.length > 0) {
      if (vectorLayers.length == 0) {
        setVectorLayers(newLayers);
      } else {
        const updatedVectorLayers = vectorLayers.map((l) => {
          const exists = newLayers.find((e) => e.get('id') === l.get('id'));
          return exists || l;
        });
        setVectorLayers(updatedVectorLayers);
      }
    }
    // eslint-disable-next-line
  }, [stateVectorLayers]);

  // update OpenLayers vector layer instance
  useEffect(() => {
    const newLayers = stateVectorLayers
      .filter((layer) => layer.state === 'SET')
      .map((layer) => {
        const exists = vectorLayers.find((e) => e.get('id') === layer.id);

        if (exists) {
          exists.setProperties({ ...layer });
          exists.setStyle(exists.get('style'));
          if (exists.get('background') != null) {
            exists.setBackground(exists.get('background'));
          }

          return exists;
        }
      });

    if (newLayers.length > 0) {
      const updatedVectorLayers = vectorLayers.map((l) => {
        const exists = newLayers.find((e) => e.get('id') === l.get('id'));
        return exists || l;
      });
      setVectorLayers(updatedVectorLayers);
    }
    // eslint-disable-next-line
  }, [stateVectorLayers]);

  return { vectorLayers, rasterLayers };
};

export default useLayers;
