import { axios, geoApiGouv } from '../../../lib/axios';

const getCommuneInfosFromXY = async ([x, y]) => {
  const [data] = await geoApiGouv.get(`/communes?lon=${x}&lat=${y}&fields=code,nom`);
  return data;
};

const getLieuDit = async ({ code }) => {
  const { divers } = await axios.get(`/divers/lieudit?codeInsee=${code}`);
  return divers?.dslieudit;
};

const updateAncienInsee = async (data) => {
  const response = await axios.put(`/divers/lieudit`, data);
  return response;
};

const getContourCommunesInExtent = async ({ extent }) => {
  const response = await axios.get(
    `/parcelles-api/communes/associee?bbox=${extent.join(',') || ''}`
    // `http://localhost:8080/communes/associee?bbox=${extent.join(',') || ''}`
  );
  return response;
};

const postCommuneLieuDit = async (data) => {
  const response = await axios.post(`/parcelles-api/communes/associee`, data);
  // const response = await axios.post(`http://localhost:8080/communes/associee`, data);
  return response;
};

const deleteCommuneLieuDit = async (data) => {
  const response = await axios.delete(
    `/parcelles-api/communes/associee?insee=${data.insee}&ancieninsee=${data.ancieninsee}&lieudit=${data.lieudit}`
  );
  return response;
};

export {
  getContourCommunesInExtent,
  getCommuneInfosFromXY,
  getLieuDit,
  postCommuneLieuDit,
  updateAncienInsee,
  deleteCommuneLieuDit,
};
