import { Divider, Paper, Typography } from '@mui/material';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import FormInputText from '../../../../components/FormInputText';

const defaultValues = {
  nom: '',
  codeinsee: '',
};

const DrawForm = ({ communeProps }) => {
  const { centroid = null, insee_com = '', nom = '', error = '' } = communeProps;
  const methods = useForm({ defaultValues });
  const { setValue, control } = methods;

  const [prevCentroid, setPrevCentroid] = useState([]);
  const [prevError, setPrevError] = useState('');

  if (centroid !== prevCentroid) {
    setValue('nom', nom, true);
    setValue('codeinsee', insee_com, true);

    setPrevCentroid(centroid);
  }

  if (error !== prevError) {
    setPrevError(error);
  }

  return (
    <Paper
      style={{
        display: 'grid',
        gridRowGap: '20px',
        padding: '20px',
      }}
    >
      <Typography variant="h6">Dessiner un lieu-dit</Typography>
      <Divider />
      <Typography variant="subtitle1">Infos commune</Typography>
      <FormInputText
        name="nom"
        control={control}
        label="Commune"
        InputProps={{
          readOnly: true,
        }}
      />
      <FormInputText
        name="codeinsee"
        control={control}
        label="Code insee"
        InputProps={{
          readOnly: true,
        }}
      />
      <Typography variant="inherit" color="red">
        {error}
      </Typography>
    </Paper>
  );
};

export default DrawForm;
