import React from 'react';
import { nanoid } from 'nanoid';
import { setVectorLayer, useLayerStore } from '../../stores/layers';
import VectorSource from 'ol/source/Vector';
import { style, textStyle } from './style';
import { addNotification, closeNotification } from '../../stores/notifications';
import { getSurface } from './api';
import { useMapStore } from '../../stores/map';
import { vectorTileSourceFromGeojsonVt } from '../../utils/vectortile';
import geojsonvt from 'geojson-vt';
import { GeoJSON } from 'ol/format';
import { useItemsStore } from '../../stores/menuItems';
import { selectionStyle } from './style';
import { MenuItem, Select, Slider, ToggleButton, ToggleButtonGroup } from '@mui/material';
import {
  getSurfaceValue,
  setSurfaceDonnee,
  setSurfaceMaxCumul,
  setSurfaceMinMax,
  setSurfaceType,
  SURFACE_DONNEE_COMMUNE,
  SURFACE_DONNEE_SECTEUR,
  SURFACE_DONNEE_USINE,
  SURFACE_ENQUETE_BETTERAVE,
  SURFACE_ENQUETE_LUZERNE,
  SURFACE_PLANNING_BETTERAVE,
  useSurfaceStore,
} from '../../stores/surface';
import { getCampagnes } from '../parcelle/api';

const id = nanoid();
const props = {
  title: 'Surface',
  type: 'VectorTile',
  visible: false,
  color: '#696969',
  opacity: 0.85,
  background: 'rgba(0, 0, 0, 0.35)',
  group: 'Commune',
};

const idSelection = nanoid();
const propsSelection = {
  title: 'Surface Sélection',
  type: 'VectorTile',
  visible: false,
  parentId: id,
};

const idText = nanoid();
const propsText = {
  title: 'Surface Texte',
  type: 'Vector',
  visible: false,
  parentId: id,
};

let geojsonVtTileIndex = null;
let surfaceStoreObserver = null;
let highlightItemObserver = null;
let minMaxTimer = null;
let campagnesList = null;
let currentCampagne = null;
const tileSize = 1024;

const show = async () => {
  const notif = addNotification({
    message: props.title + ' - ' + 'Chargement en cours',
    variant: 'loading',
    persist: true,
  });
  try {
    if (campagnesList == null) {
      var campagnes = await getCampagnes('ENQSUR');
      const campagnesLuzerne = await getCampagnes('LUZERNE');
      campagnesLuzerne.forEach((cl) => {
        if (campagnes.find((cb) => cb.campsur4 == cl.campsur4) == null) {
          campagnes.push(cl);
        }
      });
      campagnes.sort((a, b) => b.campsur4.localeCompare(a.campsur4));
      campagnesList = campagnes.map((i) => ({ label: i.libcamp, value: i.campsur4 }));

      currentCampagne = campagnesList[0].value;
    }

    const data = await getSurface(currentCampagne);

    // Calcul des cumuls par secteur/usine
    const secteurData = {};
    const usineData = {};
    let maxCumulBetteraveSecteur = 0;
    let maxCumulBetteraveUsine = 0;
    let maxCumulLuzerneUsine = 0;

    for (let feature of data.features) {
      const secteur = feature.properties.secteur;
      const usl = feature.properties.usl;

      if (feature.properties.lieudit) continue;

      if (secteur) {
        if (!(secteur in secteurData)) {
          secteurData[secteur] = {
            surfaceEnqueteBetterave: 0,
            surfacePlanningBetterave: 0,
            surfaceEnqueteLuzerne: 0,
          };
        }
        secteurData[secteur][SURFACE_ENQUETE_BETTERAVE] =
          secteurData[secteur][SURFACE_ENQUETE_BETTERAVE] +
          getSurfaceValue(feature.properties, SURFACE_ENQUETE_BETTERAVE, SURFACE_DONNEE_COMMUNE);
        secteurData[secteur][SURFACE_PLANNING_BETTERAVE] =
          secteurData[secteur][SURFACE_PLANNING_BETTERAVE] +
          getSurfaceValue(feature.properties, SURFACE_PLANNING_BETTERAVE, SURFACE_DONNEE_COMMUNE);
        secteurData[secteur][SURFACE_ENQUETE_LUZERNE] =
          secteurData[secteur][SURFACE_ENQUETE_LUZERNE] +
          getSurfaceValue(feature.properties, SURFACE_ENQUETE_LUZERNE, SURFACE_DONNEE_COMMUNE);

        maxCumulBetteraveSecteur = Math.max(
          maxCumulBetteraveSecteur,
          secteurData[secteur][SURFACE_ENQUETE_BETTERAVE]
        );
        maxCumulBetteraveSecteur = Math.max(
          maxCumulBetteraveSecteur,
          secteurData[secteur][SURFACE_PLANNING_BETTERAVE]
        );
      }

      if (usl) {
        if (!(usl in usineData)) {
          usineData[usl] = {
            surfaceEnqueteBetterave: 0,
            surfacePlanningBetterave: 0,
            surfaceEnqueteLuzerne: 0,
          };
        }

        usineData[usl][SURFACE_ENQUETE_BETTERAVE] =
          usineData[usl][SURFACE_ENQUETE_BETTERAVE] +
          getSurfaceValue(feature.properties, SURFACE_ENQUETE_BETTERAVE, SURFACE_DONNEE_COMMUNE);
        usineData[usl][SURFACE_PLANNING_BETTERAVE] =
          usineData[usl][SURFACE_PLANNING_BETTERAVE] +
          getSurfaceValue(feature.properties, SURFACE_PLANNING_BETTERAVE, SURFACE_DONNEE_COMMUNE);
        usineData[usl][SURFACE_ENQUETE_LUZERNE] =
          usineData[usl][SURFACE_ENQUETE_LUZERNE] +
          getSurfaceValue(feature.properties, SURFACE_ENQUETE_LUZERNE, SURFACE_DONNEE_COMMUNE);

        maxCumulBetteraveUsine = Math.max(
          maxCumulBetteraveUsine,
          usineData[usl][SURFACE_ENQUETE_BETTERAVE]
        );
        maxCumulBetteraveUsine = Math.max(
          maxCumulBetteraveUsine,
          usineData[usl][SURFACE_PLANNING_BETTERAVE]
        );
        maxCumulLuzerneUsine = Math.max(
          maxCumulLuzerneUsine,
          usineData[usl][SURFACE_ENQUETE_LUZERNE]
        );
      }
    }

    for (let feature of data.features) {
      const secteur = feature.properties.secteur;
      const usl = feature.properties.usl;

      if (secteur) {
        if (secteur in secteurData) {
          feature.properties['secteurData'] = secteurData[secteur];
        }
      }

      if (usl) {
        if (usl in usineData) {
          feature.properties['usineData'] = usineData[usl];
        }
      }
    }

    setSurfaceMaxCumul(maxCumulBetteraveSecteur, maxCumulBetteraveUsine, maxCumulLuzerneUsine);

    // Generate tiles
    geojsonVtTileIndex = geojsonvt(data, {
      extent: tileSize,
      debug: 0,
      tolerance: 2, // default 3 (2 pour avoir une meilleure définition des contours quand on dezoom)
      buffer: 16, // default 64 (64 pour tuile de 4096 donc 16 pour tuile de 1024)
      promoteId: 'codeinsee',
    });

    // Update layers
    const projection = useMapStore.getState().map.getView().getProjection();
    const source = vectorTileSourceFromGeojsonVt(geojsonVtTileIndex, projection, tileSize);
    setVectorLayer({ id, style, source: source, visible: true });
    setVectorLayer({
      id: idSelection,
      style: selectionStyle,
      source: source,
      visible: false,
    });
    const textFeatures = new GeoJSON({ featureProjection: projection }).readFeatures(data);
    setVectorLayer({
      id: idText,
      parentId: id,
      style: textStyle,
      source: new VectorSource({
        features: textFeatures,
      }),
      visible: true,
    });

    // Register meteo type/date observer
    if (!surfaceStoreObserver) {
      surfaceStoreObserver = useSurfaceStore.subscribe(
        (state) => [state.type],
        () => {
          if (useLayerStore.getState().activeLayer.get('id') == id) {
            updateLayer();
          }
        }
      );
    }

    // Setup highlightItem observer to display selection
    if (!highlightItemObserver) {
      highlightItemObserver = useItemsStore.subscribe(
        (state) => state.highlightItem,
        (highlightItem) => {
          const selectedFeature = highlightItem?.feature;
          const layer = highlightItem?.layer;
          setVectorLayer({
            id: idSelection,
            visible:
              selectedFeature && layer && (layer.get('id') == id || layer.get('id') == idText),
          });
        }
      );
    }

    addNotification({ message: props.title + ' - ' + 'Chargement terminé', variant: 'success' });
  } finally {
    closeNotification(notif);
  }
};

const hide = () => {
  if (surfaceStoreObserver) {
    surfaceStoreObserver();
    surfaceStoreObserver = null;
  }

  if (highlightItemObserver) {
    highlightItemObserver();
    highlightItemObserver = null;
  }

  const source = new VectorSource();
  setVectorLayer({ id, source, visible: false });
  setVectorLayer({ id: idSelection, visible: false });
  setVectorLayer({ id: idText, visible: false });
};

const updateLayer = () => {
  setVectorLayer({ id });
  setVectorLayer({ id: idSelection, visible: false });
  setVectorLayer({ id: idText });
};

const popup = (feature) => {
  const donnee = useSurfaceStore.getState().donnee;
  const type = useSurfaceStore.getState().type;
  const value = getSurfaceValue(feature);
  let name = `${feature.codeinsee} - ${feature.nom}`;

  if (donnee == SURFACE_DONNEE_SECTEUR) {
    name = feature.secteur;
  }

  if (donnee == SURFACE_DONNEE_USINE) {
    name = `${feature.usl} - ${feature.nomusl}`;
  }

  return (
    <div style={{ fontSize: '14px' }}>
      <strong>{name}</strong>
      <div>
        Surfaces: <strong>{value} Has</strong>
      </div>
      {donnee != SURFACE_DONNEE_USINE && type != SURFACE_ENQUETE_LUZERNE && (
        <div>
          Usine: <strong>{feature.nomusl}</strong>
        </div>
      )}
      {donnee == SURFACE_DONNEE_COMMUNE && type != SURFACE_ENQUETE_LUZERNE && (
        <div>
          Secteur: <strong>{feature.secteur}</strong>
        </div>
      )}
    </div>
  );
};

const menuItems = () => {
  const type = useSurfaceStore.getState().type;
  const donnee = useSurfaceStore.getState().donnee;
  const currentMin = useSurfaceStore.getState().min;
  const currentMax = useSurfaceStore.getState().max;
  const min = 0;
  let max = 1000;
  let step = 50;

  if (type == SURFACE_ENQUETE_LUZERNE) {
    max = 500;
    step = 25;
  }

  const slider = {
    key: nanoid(),
    text: '',
    selected: true,
    selectable: false,
    avatar: (
      <Slider
        min={min}
        max={max}
        step={step}
        defaultValue={[currentMin, currentMax == -1 ? max : currentMax]}
        valueLabelDisplay="on"
        marks
        onChange={(event, newValue) => {
          if (minMaxTimer) {
            clearTimeout(minMaxTimer);
          }
          minMaxTimer = setTimeout(() => {
            setSurfaceMinMax(newValue[0], newValue[1] == max ? -1 : newValue[1]);
          }, 500);
        }}
        sx={{
          margin: '18px 25px 0 10px',
        }}
      />
    ),
  };

  const actions = [
    {
      key: nanoid(),
      text: '',
      selectable: false,
      avatar: (
        <Select
          fullWidth={true}
          defaultValue={currentCampagne}
          sx={{
            height: '35px',
          }}
          onChange={(event) => {
            currentCampagne = event.target.value;
            show();
          }}
        >
          {campagnesList.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              Campagne {option.label}
            </MenuItem>
          ))}
        </Select>
      ),
    },
  ];

  if (type != SURFACE_ENQUETE_LUZERNE) {
    actions.push({
      key: nanoid(),
      text: '',
      selectable: false,
      avatar: (
        <ToggleButtonGroup
          value={donnee}
          exclusive
          fullWidth
          sx={{ height: '35px' }}
          onChange={(event, value) => {
            setSurfaceDonnee(value);
          }}
        >
          <ToggleButton value={SURFACE_DONNEE_COMMUNE}>Commune</ToggleButton>
          <ToggleButton value={SURFACE_DONNEE_SECTEUR}>Secteur</ToggleButton>
          <ToggleButton value={SURFACE_DONNEE_USINE}>Usine</ToggleButton>
        </ToggleButtonGroup>
      ),
    });
  }

  actions.push({
    key: nanoid(),
    text: "Voir les surfaces de l'enquête Betterave",
    icon: 'Dashboard',
    selected: type == SURFACE_ENQUETE_BETTERAVE,
    handleClick: () => {
      setSurfaceType(SURFACE_ENQUETE_BETTERAVE);
    },
  });

  if (type == SURFACE_ENQUETE_BETTERAVE && donnee == SURFACE_DONNEE_COMMUNE) actions.push(slider);

  actions.push({
    key: nanoid(),
    text: 'Voir les surfaces du planning Betterave',
    icon: 'Dashboard',
    selected: type == SURFACE_PLANNING_BETTERAVE,
    handleClick: () => {
      setSurfaceType(SURFACE_PLANNING_BETTERAVE);
    },
  });

  if (type == SURFACE_PLANNING_BETTERAVE && donnee == SURFACE_DONNEE_COMMUNE) actions.push(slider);

  actions.push({
    key: nanoid(),
    text: "Voir les surfaces de l'enquête Luzerne",
    icon: 'Dashboard',
    selected: type == SURFACE_ENQUETE_LUZERNE,
    handleClick: () => {
      setSurfaceType(SURFACE_ENQUETE_LUZERNE);
    },
  });

  if (type == SURFACE_ENQUETE_LUZERNE && donnee == SURFACE_DONNEE_COMMUNE) actions.push(slider);

  return actions;
};

export { id, props, idSelection, propsSelection, idText, propsText, show, hide, popup, menuItems };
