import { axios } from '../../../lib/axios';

const getZoneContournementApi = async ({ type, extent }) => {
    const response = await axios.get(
        `/parcelles-api/zones-contournement?bbox=${extent || ''}&type=${type || ''}`
    );
    return response;
};

const sendZoneContournementApi = async (zone) => {
    const response = await axios.post('/parcelles-api/zones-contournement', zone);
    return response;
  };

const deleteZoneContournementApi = async (gid) => {
  const response = await axios.delete(`/parcelles-api/zones-contournement/${gid}`);
  return response;
};

const updateZoneContournementApi = async (zone) => {
    const response = await axios.put('/parcelles-api/zones-contournement', zone);
    return response;
  };


export {
    getZoneContournementApi,
    sendZoneContournementApi,
    deleteZoneContournementApi,
    updateZoneContournementApi
};
