import React, { useState, useEffect } from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import { usePrefTechStore } from '../../../../stores/prefTech';
import FormSelect from '../../../../components/FormSelect';
import { Button, Paper, Typography, Divider, MenuItem, CircularProgress } from '@mui/material';
import { getUsines } from '../../api';
import { getMaterielAgricoleByType } from '../../../materiel-agricole/api';

const defaultValues = {
  usine: '',
  codegrue: '',
  setExtent: true,
};

const PlanChargementForm = ({ onSubmit }) => {
  const methods = useForm({ defaultValues: defaultValues });
  const {
    handleSubmit,
    formState: { isSubmitting, errors },
    getValues,
    setValue,
    setError,
    clearErrors,
    reset,
    watch,
    control,
  } = methods;

  const [usines, setUsines] = useState([]);
  const [materiels, setMateriels] = useState([]);
  const watchUsine = watch('usine');

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      },
    },
  };

  // init usines list
  useEffect(() => {
    (async () => {
      const data = await getUsines();
      const mapUsines = data.map((u) => ({ label: u.nom, value: u.code_usine }));
      setUsines(mapUsines);

      const usl = usePrefTechStore.getState().prefs.USL;
      if (usl) {
        setValue('usine', usl);
      }
    })();
  }, [setValue]);

  useEffect(() => {
    setValue('codegrue', '');

    (async () => {
      if (watchUsine != '') {
        const materiels = await getMaterielAgricoleByType('GRU');
        setMateriels(materiels.dsmatagri.filter((mat) => mat.usg == watchUsine));
      } else {
        setMateriels([]);
      }
    })();
  }, [watchUsine, setValue]);

  return (
    <Paper
      style={{
        display: 'grid',
        gridRowGap: '17px',
        padding: '10px 20px 20px 20px',
      }}
    >
      <FormProvider {...methods}>
        <Typography variant="h6">Plan de chargement</Typography>
        <FormSelect name="usine" control={control} label="Usine" MenuProps={MenuProps}>
          {usines.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </FormSelect>
        <FormSelect
          name="codegrue"
          control={control}
          label="Grue"
          disableClear={true}
          MenuProps={MenuProps}
        >
          {materiels.map((option) => (
            <MenuItem key={option.codemateriel} value={option.codemateriel}>
              {option.codemateriel}
            </MenuItem>
          ))}
        </FormSelect>
        <Divider />
        {errors?.form?.message && (
          <Typography variant="inherit" color="red">
            {errors?.form?.message}
          </Typography>
        )}
        <Button
          onClick={() => {
            // data validation before submit
            const atLeastOneMandatoryValues = getValues(['codegrue']).join('').length > 0;
            if (!atLeastOneMandatoryValues) {
              setError('form', {
                type: 'manual',
                message: 'Veuillez sélectionner une grue !',
              });
            } else {
              clearErrors();
              return handleSubmit(onSubmit)();
            }
          }}
          // onClick={handleSubmit(onSubmit)}
          variant="contained"
          disabled={isSubmitting}
        >
          {isSubmitting ? <CircularProgress color="inherit" size={20} /> : 'OK'}
        </Button>
        <Button onClick={() => reset()} variant="outlined" disabled={isSubmitting}>
          Effacer
        </Button>
      </FormProvider>
    </Paper>
  );
};

export default PlanChargementForm;
