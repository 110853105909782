import React, { useState, useEffect } from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import FormSelect from '../../../../components/FormSelect';
import { Button, Paper, Typography, Divider, MenuItem, CircularProgress } from '@mui/material';
import { getMaterielAgricoleByType } from '../../../materiel-agricole/api';

const PlanRecolteForm = ({ onSubmit }) => {
  const typesMateriels = [
    { value: 'RLZ', label: 'Récolteuse' },
    { value: 'ALZ', label: 'Andaineuse' },
    { value: 'FLZ', label: 'Faucheuse' },
  ];

  const defaultValues = {
    typemateriel: typesMateriels[0].value,
    materiel: '',
    setExtent: true,
  };

  const methods = useForm({ defaultValues: defaultValues });
  const {
    handleSubmit,
    formState: { isSubmitting, errors },
    watch,
    getValues,
    setValue,
    setError,
    clearErrors,
    reset,
    control,
  } = methods;

  const [materiels, setMateriels] = useState([]);
  const watchTypeMateriel = watch('typemateriel');

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      },
    },
  };

  useEffect(() => {
    setValue('materiel', '');

    (async () => {
      if (watchTypeMateriel != '') {
        const materiels = await getMaterielAgricoleByType(watchTypeMateriel);
        setMateriels(materiels.dsmatagri);
      } else {
        setMateriels([]);
      }
    })();
  }, [watchTypeMateriel, setValue]);

  return (
    <Paper
      style={{
        display: 'grid',
        gridRowGap: '17px',
        padding: '10px 20px 20px 20px',
      }}
    >
      <FormProvider {...methods}>
        <Typography variant="h6">Plan de récolte luzerne</Typography>
        <FormSelect
          name="typemateriel"
          control={control}
          label="Type de matériel"
          disableClear={true}
          MenuProps={MenuProps}
        >
          {typesMateriels.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </FormSelect>
        <FormSelect
          name="materiel"
          control={control}
          label="Matériel"
          disableClear={true}
          MenuProps={MenuProps}
        >
          {materiels.map((option) => (
            <MenuItem key={option.codemateriel} value={option.codemateriel}>
              {option.codemateriel}
            </MenuItem>
          ))}
        </FormSelect>
        <Divider />
        {errors?.form?.message && (
          <Typography variant="inherit" color="red">
            {errors?.form?.message}
          </Typography>
        )}
        <Button
          onClick={() => {
            // data validation before submit
            const atLeastOneMandatoryValues = getValues(['materiel']).join('').length > 0;
            if (!atLeastOneMandatoryValues) {
              setError('form', {
                type: 'manual',
                message: 'Veuillez sélectionner un matériel !',
              });
            } else {
              clearErrors();
              return handleSubmit(onSubmit)();
            }
          }}
          // onClick={handleSubmit(onSubmit)}
          variant="contained"
          disabled={isSubmitting}
        >
          {isSubmitting ? <CircularProgress color="inherit" size={20} /> : 'OK'}
        </Button>
        <Button onClick={() => reset()} variant="outlined" disabled={isSubmitting}>
          Effacer
        </Button>
      </FormProvider>
    </Paper>
  );
};

export default PlanRecolteForm;
