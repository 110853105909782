import React from 'react';
import { List, ListItemButton, ListItemIcon, ListItemText, Divider, Box } from '@mui/material';
import * as tools from '../../../features/tools';
import can from '../../../utils/duckTyping';
import MaterialIcon from '../../MaterialIcon';
import InfosCard from '../../InfosCard';

export default function SideMenuList({ ...props }) {
  let {
    showMenuTools,
    stateMenuTools,
    showTool,
    menuTools,
    showToolListItems,
    menuToolsItemsList,
  } = props;
  const keys = Object.keys(tools);

  const SideMenuList = () => {
    if (showMenuTools) {
      return (
        <List>
          {stateMenuTools.map((l) => {
            return keys.map((key) => {
              if (tools[key].id === l.id) {
                return (
                  <ListItemButton
                    key={l.id}
                    onClick={() => {
                      if (!l.visible && can(tools[key], 'show')) {
                        tools[key].show();
                      }
                    }}
                  >
                    {l.icon !== undefined ? (
                      l.icon !== '' ? (
                        <ListItemIcon>
                          <MaterialIcon icon={l.icon} />
                        </ListItemIcon>
                      ) : null
                    ) : null}
                    <ListItemText primary={l.title} />
                  </ListItemButton>
                );
              }
            });
          })}
        </List>
      );
    } else if (showTool) {
      return (
        <>
          <List>{menuTools}</List>
          <Divider />
          {showToolListItems && (
            <Box>
              <List>
                {menuToolsItemsList.map((item, index) => {
                  const { key, ...props } = item;
                  return (
                    <ListItemButton
                      sx={{
                        p: 0,
                        m: 0,
                        cursor: 'auto',
                        backgroundColor: 'transparent !important',
                        '&:hover': {
                          backgroundColor: 'transparent !important',
                        },
                      }}
                      component="div"
                      disableRipple={true}
                      disableTouchRipple={true}
                      key={item.itemId}
                      id={'card-' + item.itemId + '-anchor'}
                    >
                      <InfosCard key={key} layerName={(index + 1).toString()} {...props} />
                    </ListItemButton>
                  );
                })}
              </List>
            </Box>
          )}
        </>
      );
    }
  };

  return <SideMenuList />;
}
