import React from 'react';
import { ListItemText, ListItemIcon, ListSubheader, ListItemButton } from '@mui/material';
import MaterialIcon from '../../MaterialIcon';

const LayerMenuActions = ({ menuActions, actionSectionStatus, toggleActionSectionStatus }) => {
  if (React.isValidElement(menuActions)) {
    return menuActions;
  } else {
    if (menuActions && menuActions.length > 0) {
      {
        return menuActions.map((i) => {
          if (i.childs && i.childs.length > 0) {
            return (
              <div key={i.key}>
                <ListSubheader sx={{ p: 0, color: '#000', fontWeight: 'bold' }} component="div">
                  <ListItemButton onClick={toggleActionSectionStatus.bind(this, i.key)}>
                    <ListItemIcon>
                      <MaterialIcon
                        icon={actionSectionStatus[i.key].open ? 'ExpandLess' : 'ExpandMore'}
                      />
                    </ListItemIcon>
                    <ListItemText primary={i.text} />
                  </ListItemButton>
                  {i.avatar}
                </ListSubheader>
                {actionSectionStatus[i.key].open &&
                  i.childs.map((child) => {
                    return (
                      <ListItemButton
                        key={child.key}
                        onClick={child.handleClick}
                        selected={child.selected}
                        disableRipple={i.selectable == false}
                        sx={
                          i.selectable == false
                            ? {
                                '&.MuiListItemButton-root:hover': {
                                  backgroundColor: 'inherit',
                                },
                                '&.MuiListItemButton-root.Mui-selected:hover': {
                                  backgroundColor: 'rgba(25, 118, 210, 0.08)',
                                },
                              }
                            : null
                        }
                      >
                        {child.icon && (
                          <ListItemIcon>
                            <MaterialIcon icon={child.icon} />
                          </ListItemIcon>
                        )}
                        <ListItemText
                          primary={child.text}
                          slotProps={{ primary: { fontWeight: child.selected ? 'bold' : '400' } }}
                          secondary={child.subtitle}
                        />
                        {child.avatar}
                      </ListItemButton>
                    );
                  })}
              </div>
            );
          } else {
            return (
              <ListItemButton
                key={i.key}
                onClick={i.handleClick}
                selected={i.selected}
                disableRipple={i.selectable == false}
                sx={
                  i.selectable == false
                    ? {
                        '&.MuiListItemButton-root:hover': {
                          backgroundColor: 'inherit',
                        },
                        '&.MuiListItemButton-root.Mui-selected:hover': {
                          backgroundColor: 'rgba(25, 118, 210, 0.08)',
                        },
                      }
                    : null
                }
              >
                {i.icon && (
                  <ListItemIcon sx={{ minWidth: 40 }}>
                    <MaterialIcon icon={i.icon} />
                  </ListItemIcon>
                )}
                <ListItemText
                  primary={i.text}
                  slotProps={{ primary: { fontWeight: i.selected ? 'bold' : '400' } }}
                  secondary={i.subtitle}
                />
                {i.avatar}
              </ListItemButton>
            );
          }
        });
      }
    } else {
      return null;
    }
  }
};

export default LayerMenuActions;
