import React, { useState, useEffect } from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import { Button, CircularProgress, Paper, Typography, Divider, MenuItem } from '@mui/material';
import FormInputText from '../../../../components/FormInputText';
import FormSelect from '../../../../components/FormSelect';
import FormInputDate from '../../../../components/FormInputDate';
import { typeAire, typeAcces, typeRoute, emplSecu, typeVoie } from '../../enums';

const defaultValues = {
  commune: '',
  codeinsee: '',
  nom: '',
  typeaire: '',
  typeacces: '',
  typeroute: '',
  emplasecu: '',
  typeaccesvoie: '',
  tonnage: '',
  usl: '',
  ordreenlev: '',
  kmsusl: '',
  kmsrusl: '',
  latitude: '',
  longitude: '',
  datefin: null,
};

const CreateForm = ({ onSubmit, communeInfos, lastOnSubmit, disabledProps }) => {
  useEffect(() => {
    if (communeInfos) {
      setValue('commune', communeInfos?.nomCommune);
      setValue('codeinsee', communeInfos?.codeInsee.substring(0, 5));
      setValue('kmsusl', communeInfos?.kms);
      setValue('usl', communeInfos?.usl);
      setValue('latitude', communeInfos?.latitude);
      setValue('longitude', communeInfos?.longitude);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [communeInfos]);

  useEffect(() => {
    if (disabledProps) {
      setDisabled(true);
    }
  }, [disabledProps]);

  useEffect(() => {
    async function submitLastOnSubmit() {
      if (lastOnSubmit) {
        await handleSubmit(lastOnSubmit)();
      }
    }
    submitLastOnSubmit();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lastOnSubmit]);

  const methods = useForm({ defaultValues: defaultValues });
  const {
    handleSubmit,
    formState: { isSubmitting, errors },
    reset,
    setValue,
    control,
  } = methods;

  const [typeaires] = useState(typeAire);
  const [typeacceslist] = useState(typeAcces);
  const [typeroutelist] = useState(typeRoute);
  const [emplaseculist] = useState(emplSecu);
  const [typeaccesvoielist] = useState(typeVoie);
  const [disabled, setDisabled] = useState(false);

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      },
    },
  };

  const displayError = (errors) => {
    let message = 'Veuillez renseigner ';
    let champMessage = [];
    if (errors.nom) {
      champMessage.push('Nom');
    }
    if (errors.emplasecu) {
      champMessage.push('Emplacement de sécurité');
    }

    if (champMessage.length > 0) {
      if (champMessage.length === 1) {
        message += 'le champ suivant ( ';
        champMessage.forEach((element) => {
          message += element;
        });
      } else if (champMessage.length > 1) {
        message += 'les champs suivant ( ';
        champMessage.forEach((element, index) => {
          message += element;
          if (champMessage.length - 1 != index) {
            message += ', ';
          } else {
            message += ' ';
          }
        });
      }
      return message + ' )';
    } else {
      return '';
    }
  };

  return (
    <Paper
      style={{
        display: 'grid',
        gridRowGap: '20px',
        padding: '20px',
      }}
    >
      <FormProvider {...methods}>
        <Typography variant="h6">Créer une aire de stockage</Typography>
        <Divider />
        <Typography variant="subtitle1">Emplacement</Typography>
        <FormInputText
          name="commune"
          control={control}
          label="Commune"
          inputProps={{ readOnly: true }}
        />
        <FormInputText
          name="codeinsee"
          control={control}
          label="Code insee"
          inputProps={{ readOnly: true }}
        />
        <FormInputText
          name="nom"
          control={control}
          label="Nom"
          rules={{ required: true }}
          inputProps={{ readOnly: disabled }}
        />
        <Typography variant="subtitle1">Caracteristiques</Typography>
        <FormSelect
          name="typeaire"
          control={control}
          label="Type d'aire"
          MenuProps={MenuProps}
          inputProps={{ readOnly: disabled }}
        >
          {typeaires.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </FormSelect>
        <FormSelect
          name="typeacces"
          control={control}
          label="Type d'accès"
          MenuProps={MenuProps}
          inputProps={{ readOnly: disabled }}
        >
          {typeacceslist.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </FormSelect>
        <FormSelect
          name="typeroute"
          control={control}
          label="Type de route"
          MenuProps={MenuProps}
          inputProps={{ readOnly: disabled }}
        >
          {typeroutelist.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </FormSelect>
        <FormSelect
          name="emplasecu"
          control={control}
          label="Emplacement de sécurité"
          MenuProps={MenuProps}
          rules={{ required: true }}
          error={errors.emplasecu ? true : false} //Permet de gérer si la zone est en erreur
          inputProps={{ readOnly: disabled }}
        >
          {emplaseculist.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </FormSelect>
        <FormSelect
          name="typeaccesvoie"
          control={control}
          label="Type d'accès voie"
          inputProps={{ readOnly: disabled }}
        >
          {typeaccesvoielist.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </FormSelect>
        <FormInputText
          name="tonnage"
          control={control}
          label="Tonnage stockable"
          inputProps={{ readOnly: disabled }}
        />
        <Typography variant="subtitle1">Usine de livraison</Typography>
        <FormInputText name="usl" control={control} label="USL" inputProps={{ readOnly: true }} />
        <FormInputText
          name="ordreenlev"
          control={control}
          label="Ordre d'enlèvement"
          inputProps={{ readOnly: disabled }}
        />
        <FormInputText
          name="kmsusl"
          control={control}
          label="Kms jusqu'à USL"
          inputProps={{ readOnly: true }}
        />
        <FormInputText
          name="kmsrusl"
          control={control}
          label="Kms réels jusqu'à USL"
          inputProps={{ readOnly: true }}
        />
        <Typography variant="subtitle1">Localisation</Typography>
        <FormInputText
          name="latitude"
          control={control}
          label="Latitude"
          inputProps={{ readOnly: true }}
        />
        <FormInputText
          name="longitude"
          control={control}
          label="Longitude"
          inputProps={{ readOnly: true }}
        />
        <FormInputDate
          name="datefin"
          control={control}
          label="Date de fin d'affichage (facultatif)"
          inputProps={{ readOnly: true }}
        />
        <Typography variant="inherit" color="red">
          {displayError(errors)}
        </Typography>
        <Button
          onClick={() => {
            return handleSubmit(onSubmit)();
          }}
          variant="contained"
          disabled={isSubmitting}
        >
          {isSubmitting ? <CircularProgress color="inherit" size={20} /> : 'Ok'}
        </Button>
        <Button onClick={() => reset()} variant="outlined" disabled={isSubmitting}>
          Effacer
        </Button>
      </FormProvider>
    </Paper>
  );
};

export default CreateForm;
