import { nanoid } from 'nanoid';
import React, { useEffect, useState } from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import { Button, Paper, Typography, Divider, CircularProgress, MenuItem } from '@mui/material';
import FormSelect from '../../../../components/FormSelect';
import FormInputText from '../../../../components/FormInputText';
import { getCommuneInfosFromXY, getPlanteurByCode } from '../../api';
import { isNumber } from '../../../../utils/number';

const limit = (val, max) => {
  if (val.length === 1 && val[0] > max[0]) {
    val = '0' + val;
  }

  if (val.length === 2) {
    if (Number(val) === 0) {
      val = '00';

      //this can happen when user paste number
    } else if (val > max) {
      val = max;
    }
  }

  return val;
};

const EditForm = ({ onSubmit, parcelleProps, culturesArray, canChangeCultureType }) => {
  const {
    centroid = null,
    area = 0,
    nomcommunep = '',
    communeparc = '',
    nomplanteur = '',
    codeplanteur = '',
    codeparcelle = '',
    nomparcelle = '',
    numeroilot = '',
    culture = '',
    surfreelle = '',
    communeemps = '',
    emps = '',
  } = parcelleProps;
  const defaultValues = {
    commune: nomcommunep,
    codeinsee: communeparc,
    nomplanteur,
    codeplanteur,
    codeparcelle,
    nomparcelle,
    numilot: numeroilot,
    culture,
    surfreelle,
    surfcalc: area,
    communeemps,
    emps,
  };

  const methods = useForm({ defaultValues });
  const {
    handleSubmit,
    formState: { isSubmitting, errors },
    setValue,
    control,
  } = methods;

  const [cultures, setCultures] = useState([]);
  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;

  // init commune name and code insee
  useEffect(() => {
    (async () => {
      if (centroid) {
        const { code, nom } = await getCommuneInfosFromXY(centroid);
        setValue('commune', nom, true);
        setValue('codeinsee', code, true);
      }
    })();
  }, [centroid, setValue]);

  useEffect(() => {
    (async () => {
      if (codeplanteur) {
        const [{ nom }] = await getPlanteurByCode({ text: codeplanteur });
        setValue('nomplanteur', nom, true);
      }
    })();
  }, [codeplanteur, setValue]);

  // init cultures
  useEffect(() => {
    const mapCultures = culturesArray.map((e) => ({
      label: e.libelle,
      value: e.code,
    }));
    setCultures(mapCultures);
  }, [culturesArray]);

  return (
    <Paper
      style={{
        display: 'grid',
        gridRowGap: '20px',
        padding: '20px',
      }}
    >
      <FormProvider {...methods}>
        <Typography variant="h6">Modifier les infos parcelle</Typography>
        <Divider />
        <Typography variant="subtitle1">Emplacement</Typography>
        <FormInputText
          name="commune"
          control={control}
          label="Commune"
          inputProps={{ readOnly: true }}
        />
        <FormInputText
          name="codeinsee"
          control={control}
          label="Code insee"
          inputProps={{ readOnly: true }}
        />
        <Typography variant="subtitle1">Informations planteur</Typography>
        <FormInputText
          name="nomplanteur"
          control={control}
          label="Nom planteur"
          inputProps={{ readOnly: true }}
        />
        <FormInputText
          name="codeplanteur"
          control={control}
          label="Code planteur"
          inputProps={{ readOnly: true }}
        />
        <Typography variant="subtitle1">Informations parcelle</Typography>
        {!isNumber(parcelleProps.id_parcel) && (
          <Typography variant="caption" sx={{ fontStyle: 'italic' }}>
            Cette parcelle a été dessinée et ne fait pas partie du registre parcellaire.
          </Typography>
        )}
        <FormInputText
          name="nomparcelle"
          control={control}
          label="Nom parcelle"
          inputProps={{ maxLength: 30 }}
        />
        <FormInputText
          name="numilot"
          control={control}
          label="Numéro d'ilot"
          rules={{
            pattern: {
              value: /^[0-9]{3}$/,
              message: 'Veuillez entrer un numéro ilot valide (3 chiffres)',
            },
          }}
        />
        {canChangeCultureType && (
          <FormSelect
            name="culture"
            control={control}
            label={
              "Culture de l'année (" +
              parcelleProps.campagne.substr(0, 4) +
              '/' +
              parcelleProps.campagne.substr(4, 4) +
              ')'
            }
            disableClear={true}
            MenuProps={{
              PaperProps: {
                style: {
                  maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                },
              },
            }}
          >
            {cultures.map((option) => (
              <MenuItem key={nanoid()} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </FormSelect>
        )}
        <FormInputText
          name="surfcalc"
          control={control}
          label="Surface calculée"
          inputProps={{ readOnly: true }}
        />
        <FormInputText
          name="surfreelle"
          control={control}
          label="Surface réelle"
          number={true}
          format={(val) => {
            let int = limit(val.substring(0, 3), '999');
            let dec = limit(val.substring(3, 5), '99');

            return (
              (int.length ? int.padEnd(3, '_') : '___') +
              (dec.length ? ',' + dec.padEnd(2, '_') : ',__') +
              'ha'
            );
          }}
          allowLeadingZeros={true}
          allowNegative={false}
        />
        <Typography variant="inherit" color="red">
          {errors?.form?.message}
        </Typography>
        <Button
          onClick={() => handleSubmit(onSubmit)().catch((error) => console.log(error))}
          variant="contained"
          disabled={isSubmitting}
        >
          {isSubmitting ? <CircularProgress color="inherit" size={20} /> : 'Ok'}
        </Button>
      </FormProvider>
    </Paper>
  );
};

export default EditForm;
