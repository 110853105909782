/* eslint-disable no-unused-vars */

import { ToggleButton, ToggleButtonGroup } from '@mui/material';
import chroma from 'chroma-js';
import geojsonvt from 'geojson-vt';
import { nanoid } from 'nanoid';
import { Feature } from 'ol';
import { LineString } from 'ol/geom';
import VectorSource from 'ol/source/Vector';
import React from 'react';
import { setVectorLayer, useLayerStore } from '../../stores/layers';
import { useMapStore } from '../../stores/map';
import { addNotification, closeNotification } from '../../stores/notifications';
import {
  SECTEUR_DONNEE_COMMUNE,
  SECTEUR_DONNEE_SECTEUR,
  setSecteurDonnee,
  useSecteurStore,
} from '../../stores/secteur';
import { getViewCenter, useViewStore } from '../../stores/view';
import { vectorTileSourceFromGeojsonVt } from '../../utils/vectortile';
import { getContourCommunesSecteur, getSurfaceCommunes } from './api';
import CommuneInfo from './components/CommuneInfo';
import { selectionStyle, style, styleCineticOrdre } from './style';
import {
  removeTimeline,
  setTimelineVisible,
  showTimeline,
  useTimelineStore,
} from '../../stores/timeline';
import { useItemsStore } from '../../stores/menuItems';

const id = nanoid();
const props = {
  title: 'Secteur',
  type: 'VectorTile',
  visible: false,
  color: '#696969',
  highlightColor: '#005ca9',
  opacity: 0.85,
  background: 'rgba(0, 0, 0, 0.35)',
  group: 'Commune',
};

// This second layer is used for VectorTile selection
// https://openlayers.org/en/latest/examples/vector-tile-selection.html
const idSelection = nanoid();
const propsSelection = {
  title: 'Secteur sélection',
  type: 'VectorTile',
  //renderMode: 'vector',
  visible: false,
  parentId: id,
  opacity: 0.2,
};

const idCineticOrdre = nanoid();
const propsCineticOrdre = {
  title: 'Secteur cinetic ordre',
  type: 'Vector',
  visible: false,
  parentId: id,
};

let highlightItemUnsub = null;
let cineticOrdreUnsub = null;
let timelineValuesUnsub = null;
let geojsonVtTileIndex = null;
const tileSize = 1024;
let geoData = null;
let data = null;
let secteurSelected = '';

// public methods
const show = async () => {
  const notif = addNotification({
    message: props.title + ' - ' + 'Chargement en cours',
    variant: 'loading',
    persist: true,
  });

  try {
    // Get FeatureCollection
    if (!geoData) {
      geoData = await getContourCommunesSecteur();
      useSecteurStore.getState().secteurs = [...geoData.secteurs.sort()];

      useViewStore.getState().setCenter(getViewCenter());
      useViewStore.getState().setZoom(8);
    }

    // Get business data
    if (!data) {
      data = await getSurfaceCommunes('');
    }

    data.dslieudit.map((d) => {
      addFeatureProperties(d);
    });

    // Generate secteurs color's
    if (useSecteurStore.getState().secteursColor.length == 0) {
      useSecteurStore.getState().secteursColor[''] = chroma(props.color);

      for (const s of geoData.secteurs) {
        if (!useSecteurStore.getState().secteursColor[s]) {
          useSecteurStore.getState().secteursColor[s] = chroma.random();
        }
      }
    }

    // Generate tiles
    geojsonVtTileIndex = geojsonvt(geoData, {
      extent: tileSize,
      debug: 0,
      tolerance: 2, // default 3 (2 pour avoir une meilleure définition des contours quand on dezoom)
      buffer: 16, // default 64 (64 pour tuile de 4096 donc 16 pour tuile de 1024)
      generateId: true,
    });

    // Update layer
    const projection = useMapStore.getState().map.getView().getProjection();
    const source = vectorTileSourceFromGeojsonVt(geojsonVtTileIndex, projection, tileSize);
    setVectorLayer({ id, style, source: source, visible: true });
    setVectorLayer({
      id: idSelection,
      style: selectionStyle,
      source: source,
      visible: false,
    });

    if (!cineticOrdreUnsub) {
      cineticOrdreUnsub = useSecteurStore.subscribe(
        (state) => state.selected,
        (selected) => {
          if (secteurSelected !== selected) {
            onSecteurSwitchChange(false);
          }
          secteurSelected = selected;
        }
      );
    }

    if (!timelineValuesUnsub) {
      timelineValuesUnsub = useTimelineStore.subscribe(
        (state) => state.valuesForLayerId,
        (valuesForLayerId) => {
          if (useLayerStore.getState().activeLayer.get('id') == id) {
            const bounds = valuesForLayerId[id];
            onSecteurSwitchChange(true, bounds);
          }
        }
      );
    }

    // Setup highlightItem observer to display selection
    if (!highlightItemUnsub) {
      highlightItemUnsub = useItemsStore.subscribe(
        (state) => state.highlightItem,
        (highlightItem) => {
          const selectedFeature = highlightItem?.feature;
          const layer = highlightItem?.layer;
          setVectorLayer({
            id: idSelection,
            visible: selectedFeature && layer && layer.get('id') == id,
          });
        }
      );
    }

    addNotification({ message: props.title + ' - ' + 'Chargement terminé', variant: 'success' });
  } finally {
    closeNotification(notif);
  }
};

const hide = () => {
  if (highlightItemUnsub) {
    highlightItemUnsub();
    highlightItemUnsub = null;
  }

  if (cineticOrdreUnsub) {
    cineticOrdreUnsub();
    cineticOrdreUnsub = null;
  }

  removeTimeline(id);
  if (timelineValuesUnsub) {
    timelineValuesUnsub();
    timelineValuesUnsub = null;
  }

  const source = new VectorSource();
  setVectorLayer({ id, source, visible: false });
  setVectorLayer({ id: idSelection, source, visible: false });
  setVectorLayer({ id: idCineticOrdre, source, visible: false });
};

const popup = (props) => {
  return (
    <div>
      <div style={{ fontSize: '14px' }}>
        <strong>
          {props.insee_com} {props.nom} {props.secteur !== '' ? ` - ${props.secteur}` : ''}
        </strong>
      </div>
    </div>
  );
};

const highlightedItemComponent = (feature) => {
  return (
    <CommuneInfo
      selected={feature}
      dscommune={data.dscommune}
      onPreview={onPreview}
      onSecteurSwitchChange={onSecteurSwitchChange}
    />
  );
};

const onPreview = (commune, lieudit, secteur) => {
  const updatedFeat = geoData.features.map((f) =>
    f.properties.insee_com === commune && f.properties.lieudit === lieudit
      ? { ...f, properties: { ...f.properties, secteur: secteur } }
      : f
  );
  geoData = { ...geoData, features: updatedFeat };

  const updatedData = data.dscommune.map((d) =>
    d.codeinsee === commune ? { ...d, secteur: secteur } : d
  );

  data = { ...data, dscommune: updatedData };

  show();
};

const onSecteurSwitchChange = (checked, bounds) => {
  if (checked) {
    const source = new VectorSource();
    const coordinates = geoData.features
      .filter((f) => f.properties.secteur === secteurSelected)
      .sort(
        (a, b) =>
          a.properties.groupe - b.properties.groupe || a.properties.ordre - b.properties.ordre
      )
      .map((f) => f.properties.centroid.coordinates);

    if (bounds) {
      source.addFeatures([
        new Feature({
          geometry: new LineString(coordinates.slice(bounds[0], bounds[1] + 1)).transform(
            'EPSG:4326',
            'EPSG:3857'
          ),
        }),
      ]);
    } else {
      source.addFeatures([
        new Feature({
          geometry: new LineString(coordinates).transform('EPSG:4326', 'EPSG:3857'),
        }),
      ]);
    }

    if (!useTimelineStore.getState().visible) {
      const labels = geoData.features
        .filter((f) => f.properties.secteur === secteurSelected)
        .sort(
          (a, b) =>
            a.properties.groupe - b.properties.groupe || a.properties.ordre - b.properties.ordre
        )
        .map((f) => `${f.properties.secteur} - ${f.properties.groupe}/${f.properties.ordre}`);

      setTimelineVisible(true);
      showTimeline(id, 0, labels.length - 1, true, labels);
    }

    setVectorLayer({
      id: idCineticOrdre,
      style: styleCineticOrdre,
      source: source,
      visible: true,
    });
  } else {
    setTimelineVisible(false);
    const source = new VectorSource();
    setVectorLayer({ id: idCineticOrdre, source, visible: false });
  }
};

const addFeatureProperties = ({ codeinsee, lieudit, groupe, ordre }) => {
  const updatedFeat = geoData.features.map((f) =>
    f.properties.insee_com === codeinsee && f.properties.lieudit === lieudit
      ? {
          ...f,
          properties: {
            ...f.properties,
            groupe: parseInt(groupe),
            ordre: parseInt(ordre),
          },
        }
      : f
  );
  geoData = { ...geoData, features: updatedFeat };
};

const menuItems = () => {
  const items = [];

  items.push({
    key: nanoid(),
    text: '',
    selectable: false,
    avatar: (
      <ToggleButtonGroup
        exclusive
        fullWidth
        sx={{ height: '35px' }}
        onChange={(event) => {
          setSecteurDonnee(event.target.value);
        }}
      >
        <ToggleButton value={SECTEUR_DONNEE_COMMUNE}>Commune</ToggleButton>
        <ToggleButton value={SECTEUR_DONNEE_SECTEUR}>Secteur</ToggleButton>
      </ToggleButtonGroup>
    ),
  });

  return items;
};

export {
  hide,
  highlightedItemComponent,
  id,
  idSelection,
  idCineticOrdre,
  menuItems,
  popup,
  props,
  propsSelection,
  propsCineticOrdre,
  show,
};
