import React from 'react';
import { nanoid } from 'nanoid';
import { setVectorLayer, useLayerStore } from '../../stores/layers';
import VectorSource from 'ol/source/Vector';
import { style, textStyle } from './style';
import { addNotification, closeNotification } from '../../stores/notifications';
import { getMeteoParCommune } from './api';
import { useMapStore } from '../../stores/map';
import {
  METEO_TYPE_HYGRO,
  METEO_TYPE_PRECIP,
  METEO_TYPE_TEMP,
  METEO_TYPE_VENT,
  setDateDebutCumul,
  setMaxPrecipCumul,
  setMeteoCumul,
  setMeteoDate,
  setMeteoType,
  useMeteoStore,
} from '../../stores/meteo';
import { vectorTileSourceFromGeojsonVt } from '../../utils/vectortile';
import {
  removeTimeline,
  setTimelineVisible,
  showTimeline,
  useTimelineStore,
} from '../../stores/timeline';
import { format, parse } from 'date-fns';
import geojsonvt from 'geojson-vt';
import { GeoJSON } from 'ol/format';
import { Switch } from '@mui/material';
import CommuneInfo from './components/CommuneInfo';
import { useItemsStore } from '../../stores/menuItems';
import { selectionStyle } from './style';

const id = nanoid();
const props = {
  title: 'Météo',
  type: 'VectorTile',
  visible: false,
  color: '#696969',
  opacity: 0.85,
  background: 'rgba(0, 0, 0, 0.35)',
  group: 'Commune',
};

const idSelection = nanoid();
const propsSelection = {
  title: 'Météo Sélection',
  type: 'VectorTile',
  visible: false,
  parentId: id,
};

const idText = nanoid();
const propsText = {
  title: 'Météo Texte',
  type: 'Vector',
  visible: false,
  parentId: id,
};

let days = [];
let geojsonVtTileIndex = null;
let meteoStoreObserver = null;
let timelineValuesObserver = null;
let highlightItemObserver = null;
const tileSize = 1024;

const show = async () => {
  const notif = addNotification({
    message: props.title + ' - ' + 'Chargement en cours',
    variant: 'loading',
    persist: true,
  });
  try {
    const meteoData = await getMeteoParCommune();
    days = meteoData.jours;
    setMaxPrecipCumul(meteoData.maxcumulprecip);
    setDateDebutCumul(meteoData.datedebutcumul);

    // Init date
    let currentDateValue = format(new Date(), 'yyyyMMdd');
    if (days.indexOf(currentDateValue) < 0) {
      currentDateValue = days[0];
    }
    setMeteoDate(currentDateValue);

    // Generate tiles
    geojsonVtTileIndex = geojsonvt(meteoData, {
      extent: tileSize,
      debug: 0,
      tolerance: 2, // default 3 (2 pour avoir une meilleure définition des contours quand on dezoom)
      buffer: 16, // default 64 (64 pour tuile de 4096 donc 16 pour tuile de 1024)
      promoteId: 'codgeo',
    });

    // Update layer
    const projection = useMapStore.getState().map.getView().getProjection();
    const source = vectorTileSourceFromGeojsonVt(geojsonVtTileIndex, projection, tileSize);
    setVectorLayer({ id, style, source: source, visible: true });
    setVectorLayer({
      id: idSelection,
      style: selectionStyle,
      source: source,
      visible: false,
    });
    const textFeatures = new GeoJSON({ featureProjection: projection }).readFeatures(meteoData);
    setVectorLayer({
      id: idText,
      style: textStyle,
      source: new VectorSource({
        features: textFeatures,
      }),
      visible: true,
    });

    // Register meteo type/date observer
    if (!meteoStoreObserver) {
      meteoStoreObserver = useMeteoStore.subscribe(
        (state) => [state.type, state.date, state.cumul],
        (_newState) => {
          if (useLayerStore.getState().activeLayer.get('id') == id) {
            setTimelineVisible(!_newState[2]);
            updateLayer();
          }
        }
      );
    }

    // Setup timeline and observer
    const startDate = parse(days[0], 'yyyyMMdd', new Date());
    const endDate = parse(days[days.length - 1], 'yyyyMMdd', new Date());
    showTimeline(id, startDate, endDate, false, [new Date()]);
    if (!timelineValuesObserver) {
      timelineValuesObserver = useTimelineStore.subscribe(
        (state) => state.valuesForLayerId,
        (valuesForLayerId) => {
          if (useLayerStore.getState().activeLayer.get('id') == id) {
            const values = valuesForLayerId[id];
            setMeteoDate(format(values[0], 'yyyyMMdd'));
          }
        }
      );
    }

    // Setup highlightItem observer to display selection
    if (!highlightItemObserver) {
      highlightItemObserver = useItemsStore.subscribe(
        (state) => state.highlightItem,
        (highlightItem) => {
          const selectedFeature = highlightItem?.feature;
          const layer = highlightItem?.layer;
          setVectorLayer({
            id: idSelection,
            visible: selectedFeature && layer && layer.get('id') == id,
          });
        }
      );
    }

    addNotification({ message: props.title + ' - ' + 'Chargement terminé', variant: 'success' });
  } finally {
    closeNotification(notif);
  }
};

const hide = () => {
  if (meteoStoreObserver) {
    meteoStoreObserver();
    meteoStoreObserver = null;
  }

  removeTimeline(id);
  if (timelineValuesObserver) {
    timelineValuesObserver();
    timelineValuesObserver = null;
  }

  if (highlightItemObserver) {
    highlightItemObserver();
    highlightItemObserver = null;
  }

  setMeteoCumul(false);

  const source = new VectorSource();
  setVectorLayer({ id, source, visible: false });
  setVectorLayer({ id: idSelection, visible: false });
  setVectorLayer({ id: idText, visible: false });
};

const updateLayer = () => {
  setVectorLayer({ id });
  setVectorLayer({ id: idSelection, visible: false });
  setVectorLayer({ id: idText });
};

const popup = (feature) => {
  let weatherDate = useMeteoStore.getState().date;
  if (useMeteoStore.getState().cumul) {
    weatherDate = '99999999';
  }
  const weatherType = useMeteoStore.getState().type;
  const valueDesc = feature[weatherDate][weatherType + '_description'];
  return `${feature.libgeo} - ${valueDesc}`;
};

const highlightedItemComponent = (feature) => {
  return <CommuneInfo feature={feature} days={days} />;
};

const menuItems = () => {
  const dateCumul = parse(useMeteoStore.getState().dateDebutCumul, 'yyyyMMdd', new Date());

  const actions = [
    {
      key: nanoid(),
      text: 'Voir les données cumulées depuis le ' + format(dateCumul, 'dd/MM/yyyy'),
      icon: 'Functions',
      avatar: <Switch checked={useMeteoStore.getState().cumul} />,
      handleClick: () => {
        setMeteoCumul(!useMeteoStore.getState().cumul);
      },
    },
    {
      key: nanoid(),
      text: 'Voir la pluviométrie',
      icon: 'WaterDrop',
      selected: useMeteoStore.getState().type == METEO_TYPE_PRECIP,
      handleClick: () => {
        setMeteoType(METEO_TYPE_PRECIP);
      },
    },
    {
      key: nanoid(),
      text: "Voir l'hygrométrie",
      icon: 'Percent',
      selected: useMeteoStore.getState().type == METEO_TYPE_HYGRO,
      handleClick: () => {
        setMeteoType(METEO_TYPE_HYGRO);
      },
    },
    {
      key: nanoid(),
      text: 'Voir les températures',
      icon: 'Thermostat',
      selected: useMeteoStore.getState().type == METEO_TYPE_TEMP,
      handleClick: () => {
        setMeteoType(METEO_TYPE_TEMP);
      },
    },
    {
      key: nanoid(),
      text: 'Voir la vitesse du vent',
      icon: 'Air',
      selected: useMeteoStore.getState().type == METEO_TYPE_VENT,
      handleClick: () => {
        setMeteoType(METEO_TYPE_VENT);
      },
    },
  ];
  return actions;
};

export {
  id,
  props,
  idSelection,
  propsSelection,
  idText,
  propsText,
  show,
  hide,
  popup,
  highlightedItemComponent,
  menuItems,
};
