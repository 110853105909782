import chroma from 'chroma-js';
import { METEO_TYPE_HYGRO, METEO_TYPE_PRECIP, METEO_TYPE_TEMP } from '../../../stores/meteo';

const maxPrecipInScale = 125;
const zeroColor = '#A8B4A6';

const hygroScale = chroma
  .scale(['#AD6045', '#E0C041', '#D8E9CB', '#76B5DE', '#495B99'])
  .domain([0, 10, 30, 60, 100]);
const precipScale = chroma
  .scale([
    'rgba(255,255,255,0.0)',
    '#9DF9F6',
    '#87A9FD',
    '#7B95F9',
    '#3496FE',
    '#3586DD',
    '#35AC9F',
    '#35D14D',
    '#BEFF35',
    '#E1FF5D',
    '#FFBD35',
    '#FF8134',
    '#FD6335',
    '#FC4335',
  ])
  .domain([0, 1, 3, 6, 12, 15, 20, 30, 40, 50, 60, 80, 100, maxPrecipInScale]);
const tempScale = chroma
  .scale([
    '#5E3B71',
    '#493DD9',
    '#B3EEF9',
    '#33E975',
    '#338E54',
    '#C4FF5C',
    '#FFF833',
    '#FD7233',
    '#FF3433',
    '#F545CE',
    '#AA354D',
  ])
  .domain([-20, -15, 0, 5, 11, 19, 21, 30, 35, 40, 50]);
const ventScale = chroma
  .scale([
    '#6975AB',
    '#6389B3',
    '#5BB2B8',
    '#3BB283',
    '#43BB81',
    '#4DC57D',
    '#8DCD6C',
    '#AEC357',
    '#C9B942',
    '#B29961',
    '#B4897D',
    '#B56D81',
    '#AB4D85',
    '#9645A2',
    '#895CAB',
    '#9C49D5',
  ])
  .domain([0, 5, 10, 15, 20, 25, 30, 35, 40, 50, 55, 60, 70, 80, 90, 100]);

/**
 * Returns scale for the given meteo type
 * @param {String} type Meteo layer type
 * @returns {chroma}
 */
const getScale = (type) => {
  if (type == METEO_TYPE_HYGRO) {
    return hygroScale;
  }
  if (type == METEO_TYPE_PRECIP) {
    return precipScale;
  }
  if (type == METEO_TYPE_TEMP) {
    return tempScale;
  }
  return ventScale;
};

export { getScale, maxPrecipInScale, zeroColor };
