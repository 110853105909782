import React, { useState, useEffect } from 'react';
import { AppBar, Toolbar, IconButton, Typography, Menu, MenuItem } from '@mui/material';
import SearchBox from '../../SearchBox';
import MaterialIcon from '../../MaterialIcon';
import { useInteractionsStore, removeInteraction } from '../../../stores/interactions';
import {
  useNotificationStore,
  clearNotifications,
  closeNotification,
} from '../../../stores/notifications';
import { useItemsStore, highlightItem, addItems } from '../../../stores/menuItems';
import {
  getFunctionRecorderByLayerId,
  removeFunctionRecorderByLayerId,
  useFunctionRecorderStore,
} from '../../../stores/functionRecorder';
import { getViewExtent } from '../../../stores/view';
import can from '../../../utils/duckTyping';
import { isMobile } from 'react-device-detect';
import * as features from '../../../features';

const TopBar = ({
  toggleSideBar,
  menuLayers,
  activeLayer,
  buildMenuItems,
  buildMenuListItems,
  setShowMenuListItems,
}) => {
  const stateItems = useItemsStore((state) => state.items);
  const stateHighlightItem = useItemsStore((state) => state.highlightItem);

  const [anchorEl, setAnchorEl] = useState(null);

  const removeInteractions = () => {
    const interactions = useInteractionsStore.getState().interactions;
    const filteredInteraction = interactions.filter((i) => i.type !== 'DragRotateAndZoom');
    if (filteredInteraction.length > 0) {
      filteredInteraction.map((fi) => removeInteraction({ id: fi.id }));
    }
  };

  const handleMenuLayers = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuLayersClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    const unsubscribe = useNotificationStore.subscribe(
      (state) => state.clear,
      (clear) => {
        if (clear) {
          const keys = Object.keys(features);
          menuLayers.map((l) => {
            const key = keys.filter((key) => features[key].id === l.get('id'));
            if (!l.visible && can(features[key], 'show')) {
              const functionRecorderForLayer = getFunctionRecorderByLayerId(features[key].id);
              if (functionRecorderForLayer.length > 0) {
                removeFunctionRecorderByLayerId(features[key].id);
              }
              features[key].show(getViewExtent());
            }
          });
        }
      }
    );

    return () => {
      unsubscribe();
    };
    // eslint-disable-next-line
  }, []);

  return (
    <AppBar position="sticky">
      <Toolbar id="back-to-top-anchor">
        <IconButton
          onClick={() => {
            if (stateItems && Object.keys(stateItems).length === 3) {
              removeInteractions();
              addItems({});
              const functionRecorder = useFunctionRecorderStore.getState().functions;
              const notifications = useNotificationStore.getState().notifications;

              if (functionRecorder.length > 0) {
                notifications.forEach((notification) => {
                  if (
                    !functionRecorder.some(
                      (replayFunction) => replayFunction.notifId === notification.id
                    )
                  ) {
                    closeNotification(notification.id);
                  }
                });
              } else {
                clearNotifications();
              }
              buildMenuItems(activeLayer);
              buildMenuListItems(activeLayer);
              setShowMenuListItems(true);
            } else {
              toggleSideBar(false);
            }
          }}
          size="large"
          edge="start"
          color="inherit"
        >
          <MaterialIcon icon={'ArrowBack'} />
        </IconButton>
        {!isMobile && (
          <SearchBox sx={{ position: 'unset', width: `calc(75%)`, maxWidth: 'unset' }} />
        )}
        <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}></Typography>
        <IconButton size="large" onClick={handleMenuLayers} color="inherit">
          <MaterialIcon icon={'Layers'} />
        </IconButton>
        <Menu
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          keepMounted
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          open={Boolean(anchorEl)}
          onClose={handleMenuLayersClose}
        >
          {menuLayers
            .filter((l) => !l.get('parentId'))
            .map((l) => (
              <MenuItem
                key={l.get('id')}
                onClick={() => {
                  highlightItem({ ...stateHighlightItem, layer: l, feature: null }); // change layer through store
                  handleMenuLayersClose();
                }}
              >
                {l.get('name')}
              </MenuItem>
            ))}
        </Menu>
      </Toolbar>
    </AppBar>
  );
};

export default TopBar;
