import React, { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import {
  Button,
  Paper,
  Typography,
  Divider,
  CircularProgress,
  Box,
  Card,
  CardHeader,
  Avatar,
  List,
} from '@mui/material';
import { blue } from '@mui/material/colors';
import { nanoid } from 'nanoid';
import { Reorder, useMotionValue } from 'motion/react';

const LisItem = ({ item, index }) => {
  const y = useMotionValue(0);

  return (
    <Reorder.Item value={item} style={{ y }}>
      <Card sx={{ maxWidth: 420, mt: 1, cursor: 'grab' }}>
        <CardHeader
          avatar={<Avatar sx={{ position: 'static', bgcolor: blue[500] }}>{index + 1}</Avatar>}
          title={item.COUCHECARTO ? item.COUCHECARTO : 'Préférence non définie'}
        />
      </Card>
    </Reorder.Item>
  );
};

const EditForm = ({ onSubmit, prefTech }) => {
  const defaultValues = {
    prefTech: [],
  };
  const methods = useForm({ defaultValues: defaultValues });
  const {
    handleSubmit,
    formState: { isSubmitting },
    setValue,
  } = methods;
  const [dataList, setDataList] = useState([]);

  // hook for give nanoid for render in list
  useEffect(() => {
    if (prefTech.length > 0) {
      prefTech.map((p) => {
        p.key = nanoid();
      });
      setDataList(prefTech);
    }
  }, [prefTech]);

  return (
    <Paper
      style={{
        display: 'grid',
        gridRowGap: '20px',
        padding: '20px',
      }}
    >
      <Typography variant="h6">Mettre à jour l&apos;ordre des préférences</Typography>
      <Divider />
      <Box>
        <Reorder.Group as="div" axis="y" values={dataList} onReorder={setDataList}>
          <List>
            {dataList.map((item, index) => (
              <LisItem key={item.key} item={item} index={index} />
            ))}
          </List>
        </Reorder.Group>
      </Box>

      <Button
        onClick={() => {
          setValue('prefTech', dataList);
          return handleSubmit(onSubmit)();
        }}
        variant="contained"
        disabled={isSubmitting}
      >
        {isSubmitting ? <CircularProgress color="inherit" size={20} /> : "MODIFIER L'ORDRE"}
      </Button>
    </Paper>
  );
};

export default EditForm;
