import React from 'react';
import { Controller } from 'react-hook-form';
import frLocale from 'date-fns/locale/fr';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFnsV3';

const FormInputDate = ({ name, control, label, rules, inputProps, ...props }) => {
  return (
    <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={frLocale}>
      <Controller
        name={name}
        control={control}
        rules={rules}
        render={({ field: { onChange, value }, fieldState: { error } }) => (
          <DatePicker
            {...props}
            label={label}
            value={value}
            onChange={onChange}
            slotProps={{
              textField: {
                error: !!error,
                helperText: error ? error.message : null,
                inputProps: { ...inputProps },
              },
            }}
          />
        )}
      />
    </LocalizationProvider>
  );
};

export default FormInputDate;
