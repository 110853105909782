import React, { useEffect, useState } from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import { Button, Paper, Typography, Divider, CircularProgress, MenuItem } from '@mui/material';
import FormSelect from '../../../../components/FormSelect';
import { typezone } from '../../enums';
import FormInputText from '../../../../components/FormInputText';
import FormInputDate from '../../../../components/FormInputDate';
import FormInputTime from '../../../../components/FormInputTime';
import { parse } from 'date-fns';
import { fr } from 'date-fns/locale';
import FormInputWeekDays from '../../../../components/FormInputWeekDays';
import { validateDefaultSchedule, validateSchedule } from '../../rules/validate-schedule';
import { displayDefaultSchedule, displaySchedule } from '../../rules/display-schedule';

const EditForm = ({ onSubmit, zoneProps }) => {
    const dateFin = zoneProps.date_fin ? parse(zoneProps.date_fin, 'yyyy-MM-dd', new Date(), { locale: fr }) : null;
    const dateDebut = zoneProps.date_debut ? parse(zoneProps.date_debut, 'yyyy-MM-dd', new Date(), { locale: fr }) : null; 
    const heureDebut = zoneProps.heure_debut ? parse(zoneProps.heure_debut, 'HH:mm:ss', new Date(), { locale: fr }) : null; 
    const heureFin = zoneProps.heure_fin ? parse(zoneProps.heure_fin, 'HH:mm:ss', new Date(), { locale: fr }) : null; 
    const typeZone = zoneProps.type_zone != null ? zoneProps.type_zone : '';
    const commentaire = zoneProps.commentaire != null ? zoneProps.commentaire : '';

    const defaultValues = {
        typezone: typeZone,
        datedebut: dateDebut,
        datefin: dateFin,
        heurefin: heureFin,
        heuredebut: heureDebut,
        comment: commentaire,
        period: zoneProps.jours_affichage
    };

    const methods = useForm({ defaultValues: defaultValues });
    const {
        handleSubmit,
        formState: { isSubmitting, errors },
        setValue,
        watch,
        clearErrors,
        setError,
        control
    } = methods;

    const [typezones] = useState(typezone);
    const watchTypeZone = watch('typezone');

    const watchDateDebut = watch('datedebut');
    const watchDateFin = watch('datefin');
    const watchHeureDebut = watch('heuredebut');
    const watchHeureFin = watch('heurefin');
    const watchJourAffichage = watch('period');
    const [messageDay, setMessageDay] = useState("");

    useEffect(() => {
        if(watchTypeZone == '') {
            setValue('typezone', 'LDX')
        }
    }, [setValue, watchTypeZone]);

    useEffect(() => {
        // Affichage par défault 
        if(validateDefaultSchedule(watchDateDebut, watchDateFin, watchHeureDebut, watchHeureFin, watchJourAffichage)) {
            clearErrors();
            const message = displayDefaultSchedule();
            setMessageDay(message);
            return;
        }

        const erreur = validateSchedule(watchDateDebut, watchDateFin, watchHeureDebut, watchHeureFin, watchJourAffichage);
        if (erreur != "") {
            setMessageDay("");
            setError('form', {
                type: 'manual',
                message: erreur,
              });        
            } else {
            clearErrors()
            const message = displaySchedule(watchDateDebut, watchDateFin, watchHeureDebut, watchHeureFin, watchJourAffichage);
            setMessageDay(message);
        }

    }, [watchDateDebut, watchDateFin, watchHeureDebut, watchHeureFin, watchJourAffichage]);



    const ITEM_HEIGHT = 48;
    const ITEM_PADDING_TOP = 8;

    const MenuProps = {
        PaperProps: {
            style: {
                maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            },
        },
    };
    return (
        <Paper
            style={{
                display: 'grid',
                gridRowGap: '20px',
                padding: '20px',
            }}
        >
            <FormProvider {...methods}>
                <Typography variant="h6">Modifier les informations de la zone de contournement</Typography>
                <Divider />
                <FormSelect
                    name="typezone"
                    control={control}
                    label="Type de zone"
                    MenuProps={MenuProps}
                >
                    {typezones.map((option) => (
                        <MenuItem key={option.value} value={option.value}>
                            {option.label}
                        </MenuItem>
                    ))}
                </FormSelect>

                <FormInputText name="comment" control={control} label="Commentaire" multiline maxRows={4} 
                rules={{             
                maxLength: {
                    value: 250,
                    message: 'La taille du commentaire ne doit pas dépasser 250 caractères',
                  }, }} />

                <Typography variant="subtitle1">Plannifer l&apos;affichage de la zone</Typography>

                <Divider />

                <FormInputDate 
                name="datedebut" 
                control={control} 
                label="Date de début d'affichage (facultatif)"
                minDate={new Date()}
                inputProps={{ readOnly: true }} />

                <FormInputTime 
                name="heuredebut" 
                control={control} 
                label="heure de début d'affichage (facultatif)"
                inputProps={{ readOnly: true }} />

                <FormInputDate name="datefin" control={control} 
                label="Date de fin d'affichage (facultatif)"    
                minDate={new Date()}             
                inputProps={{ readOnly: true }} />

                <FormInputTime name="heurefin" control={control} label="heure de fin d'affichage (facultatif)"                 
                inputProps={{ readOnly: true }} />

                <FormInputWeekDays label="Période d'affichage (facultatif)" name="period" control={control} setValue={setValue} value={watchJourAffichage} />

                <Typography variant="inherit" >
                    {messageDay}
                </Typography>

                <Typography variant="inherit" color="red">
                    {errors?.form?.message}
                </Typography>

                <Button onClick={handleSubmit(onSubmit)} variant="contained" disabled={isSubmitting}>
                    {isSubmitting ? <CircularProgress color="inherit" size={20} /> : 'Modifier'}
                </Button>
            </FormProvider>
        </Paper>
    );
};

export default EditForm;
