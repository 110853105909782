import { Divider, Paper, Typography } from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';
import { Bar } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { getScale } from '../../scale';
import {
  getStatistiqueValue,
  STATISTIQUE_DONNEE_COMMUNE,
  STATISTIQUE_DONNEE_SECTEUR,
  STATISTIQUE_DONNEE_USINE,
  STATISTIQUE_TYPE_RENDEMENT_A_16,
  STATISTIQUE_TYPE_RENDEMENT_UTILE,
  STATISTIQUE_TYPE_RICHESSE,
  STATISTIQUE_TYPE_TARE_DECHET,
  useStatistiqueStore,
} from '../../../../stores/statistique';

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

const CommuneInfo = ({ feature }) => {
  const chartOptions = {
    responsive: true,
    plugins: {
      legend: {
        display: false,
      },
      title: {
        display: false,
      },
    },
  };

  const [richesseData, setRichesseData] = useState({ labels: [], datasets: [] });
  const [tareDechetData, setTareDechetData] = useState({ labels: [], datasets: [] });
  const [rendementUtileData, setRendementUtileData] = useState({ labels: [], datasets: [] });
  const [rendement16Data, setRendement16Data] = useState({ labels: [], datasets: [] });
  const donnee = useStatistiqueStore((state) => state.donnee);
  const passages = useRef([
    { key: '0', label: 'Passage 0' },
    { key: '1', label: 'Passage 1' },
    { key: '2', label: 'Passage 2' },
    { key: '3', label: 'Passage 3' },
    { key: '4', label: 'Passage 4' },
    { key: '5', label: 'Passage 5' },
  ]);

  useEffect(() => {
    const richesseScale = getScale(STATISTIQUE_TYPE_RICHESSE);
    const tareDechetScale = getScale(STATISTIQUE_TYPE_TARE_DECHET);
    const rendementUtileScale = getScale(STATISTIQUE_TYPE_RENDEMENT_UTILE);
    const rendement16Scale = getScale(STATISTIQUE_TYPE_RENDEMENT_A_16);

    setRichesseData({
      labels: passages.current.map((passage) => passage.label),
      datasets: [
        {
          data: passages.current.map((passage) =>
            getStatistiqueValue(feature.getProperties(), STATISTIQUE_TYPE_RICHESSE, passage.key)
          ),
          backgroundColor: passages.current.map((passage) => {
            return richesseScale(
              getStatistiqueValue(feature.getProperties(), STATISTIQUE_TYPE_RICHESSE, passage.key)
            ).css();
          }),
        },
      ],
    });

    setTareDechetData({
      labels: passages.current.map((passage) => passage.label),
      datasets: [
        {
          data: passages.current.map((passage) =>
            getStatistiqueValue(feature.getProperties(), STATISTIQUE_TYPE_TARE_DECHET, passage.key)
          ),
          backgroundColor: passages.current.map((passage) => {
            return tareDechetScale(
              getStatistiqueValue(
                feature.getProperties(),
                STATISTIQUE_TYPE_TARE_DECHET,
                passage.key
              )
            ).css();
          }),
        },
      ],
    });

    setRendementUtileData({
      labels: passages.current.map((passage) => passage.label),
      datasets: [
        {
          data: passages.current.map((passage) =>
            getStatistiqueValue(
              feature.getProperties(),
              STATISTIQUE_TYPE_RENDEMENT_UTILE,
              passage.key
            )
          ),
          backgroundColor: passages.current.map((passage) => {
            return rendementUtileScale(
              getStatistiqueValue(
                feature.getProperties(),
                STATISTIQUE_TYPE_RENDEMENT_UTILE,
                passage.key
              )
            ).css();
          }),
        },
      ],
    });

    setRendement16Data({
      labels: passages.current.map((passage) => passage.label),
      datasets: [
        {
          data: passages.current.map((passage) =>
            getStatistiqueValue(
              feature.getProperties(),
              STATISTIQUE_TYPE_RENDEMENT_A_16,
              passage.key
            )
          ),
          backgroundColor: passages.current.map((passage) => {
            return rendement16Scale(
              getStatistiqueValue(
                feature.getProperties(),
                STATISTIQUE_TYPE_RENDEMENT_A_16,
                passage.key
              )
            ).css();
          }),
        },
      ],
    });
  }, [feature, passages]);

  let name = `${feature.get('commune')} - ${feature.get('nomcommune')}`;
  if (donnee == STATISTIQUE_DONNEE_SECTEUR) {
    name = feature.get('secteur');
  }
  if (donnee == STATISTIQUE_DONNEE_USINE) {
    name = `${feature.get('usl')} - ${feature.get('nomusl')}`;
  }

  return (
    <Paper
      style={{
        display: 'grid',
        gridRowGap: '10px',
        padding: '10px',
      }}
    >
      <Typography variant="h6">{name}</Typography>
      <Typography variant="subtitle2">Moyenne sur l&apos;ensemble des passages :</Typography>
      <Typography variant="body2" component="div">
        <ul style={{ margin: '0 0 10px 0' }}>
          {donnee == STATISTIQUE_DONNEE_COMMUNE && (
            <li>
              Secteur : <strong>{feature.get('secteur')}</strong>
            </li>
          )}
          {donnee != STATISTIQUE_DONNEE_USINE && (
            <li>
              Usine :{' '}
              <strong>
                {feature.get('usl')} - {feature.get('nomusl')}
              </strong>
            </li>
          )}
          <li>
            Richesse :{' '}
            <strong>
              {getStatistiqueValue(feature.getProperties(), STATISTIQUE_TYPE_RICHESSE, 'all')}
            </strong>
            %
          </li>
          <li>
            Tare déchet :{' '}
            <strong>
              {getStatistiqueValue(feature.getProperties(), STATISTIQUE_TYPE_TARE_DECHET, 'all')}
            </strong>
            %
          </li>
          <li>
            Rendement utile :{' '}
            <strong>
              {getStatistiqueValue(
                feature.getProperties(),
                STATISTIQUE_TYPE_RENDEMENT_UTILE,
                'all'
              )}
            </strong>
            T/Ha
          </li>
          <li>
            Rendement à 16 :{' '}
            <strong>
              {getStatistiqueValue(feature.getProperties(), STATISTIQUE_TYPE_RENDEMENT_A_16, 'all')}
            </strong>
            T/Ha
          </li>
        </ul>
      </Typography>
      <Divider />
      <Typography variant="subtitle1">Richesse (%)</Typography>
      <Bar options={chartOptions} data={richesseData} />
      <Divider />
      <Typography variant="subtitle1">Tare déchet (%)</Typography>
      <Bar options={chartOptions} data={tareDechetData} />
      <Divider />
      <Typography variant="subtitle1">Rendement utile (T/Ha)</Typography>
      <Bar options={chartOptions} data={rendementUtileData} />
      <Divider />
      <Typography variant="subtitle1">Rendement à 16 (T/Ha)</Typography>
      <Bar options={chartOptions} data={rendement16Data} />
    </Paper>
  );
};

export default CommuneInfo;
