import { Fill, Stroke, Style, Text } from 'ol/style';
import { METEO_TYPE_PRECIP, useMeteoStore } from '../../../stores/meteo';
import { getScale, maxPrecipInScale, zeroColor } from '../scale';
import { useItemsStore } from '../../../stores/menuItems';
import { useMapStore } from '../../../stores/map';

const font = 'bold' + ' ' + '12px' + '/' + '1.2' + ' ' + 'Arial';

const baseStyle = new Style({
  fill: new Fill({
    color: zeroColor,
  }),
  stroke: new Stroke({
    color: '#696969',
    width: 1,
  }),
});

const style = (feature) => {
  let weatherDate = useMeteoStore.getState().date;

  if (useMeteoStore.getState().cumul) {
    weatherDate = '99999999';
  }
  const weatherType = useMeteoStore.getState().type;

  if (!feature.get(weatherDate)) {
    return null;
  }

  let value = feature.get(weatherDate)[weatherType + '_value'];
  if (weatherType == METEO_TYPE_PRECIP) {
    if (useMeteoStore.getState().cumul) {
      value = (maxPrecipInScale * value) / useMeteoStore.getState().maxPrecipCumul;
    }
  }

  const scale = getScale(weatherType);
  baseStyle.getFill().setColor(scale(value));
  //baseStyle.getStroke().setColor(scale(value));

  return baseStyle;
};

const baseSelectionStyle = new Style({
  fill: new Fill({
    color: 'rgba(0, 0, 0, 0.1)',
  }),
  stroke: new Stroke({
    color: 'rgba(0, 0, 0, 1.0)',
    width: 1,
  }),
});

const selectionStyle = (feature) => {
  const selectedFeature = useItemsStore.getState().highlightItem?.feature;
  if (selectedFeature && feature.get('codgeo') == selectedFeature.get('codgeo')) {
    return baseSelectionStyle;
  }
  return null;
};

const baseTextStyle = new Style({
  text: new Text({
    font: font,
    overflow: true,
    fill: new Fill({
      color: '#000',
    }),
    stroke: new Stroke({
      color: '#fff',
      width: 3,
    }),
  }),
});

const textStyle = (feature, resolution) => {
  const zoom = useMapStore.getState().map.getView().getZoomForResolution(resolution);

  let weatherDate = useMeteoStore.getState().date;

  if (useMeteoStore.getState().cumul) {
    weatherDate = '99999999';
  }
  const weatherType = useMeteoStore.getState().type;

  if (!feature.get(weatherDate)) {
    return null;
  }

  const value = feature.get(weatherDate)[weatherType + '_description'];

  if (zoom > 11) {
    baseTextStyle.getText().setText(`${feature.get('libgeo')}\n${value}`);
    return baseTextStyle;
  } else {
    return null;
  }
};

export { style, selectionStyle, textStyle };
