const createBackendEntry = ({ data, feature }) => {
  let resultAncieninsee = '';
  const {
    codeinsee: insee,
    ancieninsee = '00000',
    lieudit = '',
    nom = '',
    secteur = '',
  } = data?.lieudit || {};

  if (ancieninsee === '00000' && data.ancieninsee !== '') {
    resultAncieninsee = data.ancieninsee;
  } else {
    resultAncieninsee = ancieninsee;
  }

  if (feature) {
    return {
      insee,
      ancieninsee: resultAncieninsee,
      lieudit,
      nom,
      secteur,
      feature,
    };
  } else {
    return {
      insee,
      ancieninsee: resultAncieninsee,
      lieudit,
      nom,
      secteur,
    };
  }
};

const deleteBackendEntry = ({ data }) => {
  const { insee_com = '', insee_cad = '', lieudit = '' } = data;

  return {
    insee: insee_com,
    ancieninsee: insee_cad,
    lieudit,
  };
};

export { createBackendEntry, deleteBackendEntry };
