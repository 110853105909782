import { Circle as CircleStyle, Stroke, Fill, Style, Text } from 'ol/style';
import { getColorCode as getColorCodeCouvertMellifere } from '../rules/couvert-mellifere';
import hex2rgba from '../../../utils/hex2rgba';
import { props } from '../index';
import { isNumber } from '../../../utils/number';
import { LineString } from 'ol/geom';
import { distance } from 'ol/coordinate';

const font = 'bold' + ' ' + '14px' + '/' + '1' + ' ' + 'Arial';

const labelStyle = new Style({
  zIndex: 3,
  text: new Text({
    font: font,
    overflow: true,
    fill: new Fill({
      color: '#000',
    }),
    stroke: new Stroke({
      color: '#fff',
      width: 3,
    }),
  }),
});

// zIndex: 1 ensure that couvert mellifere features are always on top
const couvertMellifereStyle = new Style({
  fill: new Fill({
    color: 'rgba(255, 255, 255, 0.5)',
  }),
  stroke: new Stroke({
    color: '#319FD3',
    width: 1,
  }),
  zIndex: 1000,
});

const parcelleStyle = new Style({});

const style = (feature) => {
  const features = feature.get('features');

  if (features && features.length > 1) {
    // cluster
    // couvert mellifere only
    const surfSum = features
      .filter((f) => {
        const cultureCode = f.get('culture') || f.get('code_cultu') || '';
        return cultureCode.includes('SC');
      })
      .map((f) => parseFloat(f.get('surf_calc')))
      .reduce((acc, surf) => acc + surf, 0);

    return new Style({
      image: new CircleStyle({
        radius: 20,
        stroke: new Stroke({
          color: '#fff',
        }),
        fill: new Fill({
          color: '#FF5E33',
        }),
      }),
      text: new Text({
        text: surfSum.toFixed(2),
        fill: new Fill({
          color: '#fff',
        }),
      }),
    });
  }

  const opacity = feature.get('opacity') ? feature.get('opacity') : 1.0;

  // simple feature
  const styles = [labelStyle];
  const nomParc = feature.get('nomparcelle') ? `${feature.get('nomparcelle')}` : '';
  const codeParc = feature.get('codeparcelle') ? `(${feature.get('codeparcelle')})` : '';
  let label = '';
  if (feature.get('ordre')) {
    label += parseInt(feature.get('ordre')) + ' - ';
  }
  label += nomParc + ' ' + codeParc;
  if (feature.get('codeplanteur')) {
    label += '\n' + feature.get('codeplanteur');
  }

  // label différent si vinasse
  if (feature.get('culture') == 'vinasse') {
    label += feature.get('num_cde') + '\n' + feature.get('nom_client_liv');
  }

  const cultureCode = feature.get('culture') || feature.get('code_cultu') || '';
  labelStyle.getText().setText(label ? label : '');
  labelStyle
    .getText()
    .getFill()
    .setColor('rgba(0, 0, 0, ' + opacity + ')');
  labelStyle
    .getText()
    .getStroke()
    .setColor('rgba(255, 255, 255, ' + opacity + ')');

  if (cultureCode.includes('SC')) {
    // couvert mellifere
    // const rgba = hex2rgba(getColorCodeCouvertMellifere(cultureCode), 0.5);
    couvertMellifereStyle.setFill(
      new Fill({
        color: hatchTile(getColorCodeCouvertMellifere(cultureCode)),
      })
    );
    styles.push(couvertMellifereStyle);
  } else {
    //  parcelle Style
    if (feature.get('coulculture')) {
      const rgba = hex2rgba(
        feature.get('highlight') ? props.highlightColor : feature.get('coulculture'),
        Math.min(0.5, opacity)
      );
      const colorStroke = hex2rgba(
        feature.get('highlight') ? props.highlightColor : '#' + feature.get('coulculture'),
        opacity
      );
      parcelleStyle.setFill(
        new Fill({
          color: rgba,
        })
      );
      parcelleStyle.setStroke(
        new Stroke({
          color: colorStroke,
          width: 2,
        })
      );
    } else if (feature.get('codeplanteur') && feature.get('codeparcelle')) {
      const rgba = hex2rgba(
        feature.get('highlight') ? props.highlightColor : props.color,
        Math.min(0.5, opacity)
      );
      const colorStroke = hex2rgba(
        feature.get('highlight') ? props.highlightColor : props.color,
        opacity
      );
      parcelleStyle.setFill(
        new Fill({
          color: rgba,
        })
      );
      parcelleStyle.setStroke(
        new Stroke({
          color: colorStroke,
          width: 2,
        })
      );
    } else {
      const rgba = hex2rgba(
        feature.get('highlight') ? props.highlightColor : props.defaultGeomColor,
        Math.min(0.5, opacity)
      );
      const colorStroke = hex2rgba(
        feature.get('highlight') ? props.highlightColor : props.defaultGeomColor,
        opacity
      );
      parcelleStyle.setFill(
        new Fill({
          color: rgba,
        })
      );
      parcelleStyle.setStroke(
        new Stroke({
          color: colorStroke,
          width: 2,
        })
      );
    }
    parcelleStyle.setZIndex(!isNumber(feature.get('id_parcel')) ? 2 : 1);

    styles.push(parcelleStyle);
  }

  return styles;
};

const hatchTile = (color) => {
  const tile = document.createElement('canvas');
  tile.width = tile.height = 10;
  const ctx = tile.getContext('2d');
  const gradient = ctx.createLinearGradient(0, 0, tile.width, tile.height);
  const colorStops = [
    [0, color],
    [0.35, color],
    [0.35, 'white'],
    [0.5, 'white'],
    [0.5, color],
    [0.85, color],
    [0.85, 'white'],
    [1, 'white'],
  ];
  colorStops.forEach((element) => {
    gradient.addColorStop(element[0], element[1]);
  });

  ctx.fillStyle = gradient;
  ctx.fillRect(0, 0, tile.width, tile.height);

  return ctx.createPattern(tile, 'repeat');
};

const circuitLineStyleBorder = new Style({
  zIndex: 1,
  stroke: new Stroke({
    width: 6,
    color: 'rbga(0,0,0,0.4)',
  }),
});

const circuitLineStyle = new Style({
  zIndex: 2,
  stroke: new Stroke({
    width: 5,
    color: '#FBC117',
  }),
});

const styleCircuit = (feature, resolution) => {
  const geometry = feature.getGeometry();
  const styles = [circuitLineStyleBorder, circuitLineStyle];
  let lastArrowCoordinates = null;

  geometry.forEachSegment(function (start, end) {
    const dx = end[0] - start[0];
    const dy = end[1] - start[1];
    const tan2 = Math.atan2(dy, dx);
    const rotation = tan2 < 0 ? Math.abs(tan2) : Math.PI + (Math.PI - tan2);

    const arrowSize = Math.max(Math.min(16 * resolution, 900), 30); // Taille de la flèche dépendant de la résolution

    // Branche 1 de la flèche
    const arrowSx = arrowSize * Math.cos(rotation + (2 * Math.PI) / 10); // flèche inclinée à 30°
    const arrowSy = arrowSize * Math.sin(rotation + (2 * Math.PI) / 10); // flèche inclinée à 30°
    // Branche 2 de la flèche
    const arrowEx = arrowSize * Math.cos(rotation - (2 * Math.PI) / 10); // flèche inclinée à 30°
    const arrowEy = arrowSize * Math.sin(rotation - (2 * Math.PI) / 10); // flèche inclinée à 30°
    // Pointe de la flèche
    const line = new LineString([start, end]);
    const middle = line.getCoordinateAt(0.5);

    if (lastArrowCoordinates) {
      const dist = distance(middle, lastArrowCoordinates);
      const distWithResolution = (dist * 1.0) / resolution;
      if (distWithResolution < 70) {
        return; // Point trop proche du précédent, on n'affiche pas la flèche
      }
    }

    lastArrowCoordinates = middle;

    // arrows
    styles.push(
      new Style({
        geometry: new LineString([
          [middle[0] - arrowSx, middle[1] + arrowSy],
          middle,
          [middle[0] - arrowEx, middle[1] + arrowEy],
        ]),
        stroke: new Stroke({
          color: 'rbga(0,0,0,0.4)',
          width: 5,
        }),
        zIndex: 0,
      }),
      new Style({
        geometry: new LineString([
          [middle[0] - arrowSx, middle[1] + arrowSy],
          middle,
          [middle[0] - arrowEx, middle[1] + arrowEy],
        ]),
        stroke: new Stroke({
          color: '#FBC117',
          width: 4,
        }),
        zIndex: 1,
      })
    );
  });

  return styles;
};

export { style, styleCircuit };
