import { create } from 'zustand';
import { subscribeWithSelector } from 'zustand/middleware';

/**
 * Utiliser ce store pour charger les composants React à la racine de l'App dynamiquement (uniquement en cas de besoin, cf App.js).
 * Cela évite de surcharger l'arbre de composants qui ne sont pas utilisés.
 * Une fois chargé(s), le ou les composant(s) le restent jusqu'à la fermeture de l'onglet du navigateur.
 *
 * Exemple d'utilisation: addRootComponent(AreaSearchButton, {}); // charge le composant AreaSearchButton avec des props vide ({})
 */

const useRootComponentsStore = create(
  subscribeWithSelector(() => ({
    items: [],
  }))
);

const addRootComponent = (comp, props) => {
  const index = useRootComponentsStore.getState().items.findIndex((c) => c.Comp.name === comp.name);

  if (index >= 0) {
    return;
  }

  useRootComponentsStore.setState((state) => ({
    items: [...state.items, { Comp: comp, props: { ...props } }],
  }));
};

export { useRootComponentsStore, addRootComponent };
