import React, { useState, useEffect } from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import FormInputText from '../../../../components/FormInputText';
import FormInputDate from '../../../../components/FormInputDate';
import FormSelect from '../../../../components/FormSelect';
import FormInputCheckBox from '../../../../components/FormInputCheckBox';
import { Button, Paper, Typography, Divider, MenuItem, CircularProgress } from '@mui/material';
import { getPictosImagesApi } from '../../api';
import { usePrefTechStore } from '../../../../stores/prefTech';
import { getGrue } from '../../../circuit-champ/api';
import { nanoid } from 'nanoid';
import { typepicto } from '../../enums';

const defaultValues = {
  pictoId: '',
  label: '',
  grue: '',
  typepicto: '',
  comment: '',
  enddisplaydate: null,
  supprimable: false
};

const CreateForm = ({ onSubmit }) => {
  const methods = useForm({ defaultValues: defaultValues });
  const {
    handleSubmit,
    formState: { isSubmitting, errors },
    getValues,
    setValue,
    watch,
    setError,
    clearErrors,
    reset,
    control,
  } = methods;

  const watchGrue = watch('grue');
  const watchTypePicto = watch('typepicto');

  const [typepictos] = useState(typepicto);
  const [pictos, setPictos] = useState([]);
  const [grues, setGrues] = useState([]);
  const [disabledGrue, setDisabledGrue] = useState(false);

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      },
    },
  };

  // init pictos list
  useEffect(() => {
    (async () => {
      const data = await getPictosImagesApi();
      const mapPictos = data.map((item) => ({
        id: item.id,
        src: item.properties.data,
        label: item.properties.name,
      }));
      setPictos(mapPictos);
    })();
  }, []);

  //init grues list
  useEffect(() => {
    const usl = usePrefTechStore.getState().prefs.USL;
    (async () => {
      const data = await getGrue(usl);
      const mapGrue = data.map((item) => ({
        id: nanoid(),
        value: item.numerogrue,
        label: item.numerogrue,
      }));
      setGrues(mapGrue);
    })();
  }, []);

  useEffect(() => {
    if (watchGrue != '') {
      (async () => {
        setValue('typepicto', 'B');
      })();
    }
  }, [setValue, watchGrue]);

  // Disabled select of grue & silo if 'typecircuit' is not equal to B
  useEffect(() => {
    if (watchTypePicto == 'B') {
      setDisabledGrue(false);
    } else if (watchTypePicto != 'B') {
      setValue('grue', '');
      setDisabledGrue(watchTypePicto && watchTypePicto != 'B');
    }
  }, [setValue, watchTypePicto]);

  return (
    <Paper
      style={{
        display: 'grid',
        gridRowGap: '20px',
        padding: '20px',
      }}
    >
      <FormProvider {...methods}>
        <Typography variant="h6">Créer un picto sur la carte</Typography>
        <Divider />
        <FormSelect name="pictoId" control={control} label="Pictos" MenuProps={MenuProps}>
          {pictos.map((option) => (
            <MenuItem key={option.id} value={option.id}>
              <img src={`data:image/png;base64,${option.src}`} loading="lazy" alt={option.label} />
            </MenuItem>
          ))}
        </FormSelect>
        <FormInputText name="label" control={control} label="Etiquette" />
        <FormInputText name="comment" control={control} label="Commentaire" />
        <FormSelect
          name="typepicto"
          control={control}
          label="Type de picto (facultatif)"
          MenuProps={MenuProps}
        >
          {typepictos.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </FormSelect>
        <FormSelect
          name="grue"
          control={control}
          label="Grue (facultatif)"
          MenuProps={MenuProps}
          inputProps={{ readOnly: disabledGrue }}
        >
          {grues.map((option) => (
            <MenuItem key={option.id} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </FormSelect>
        <FormInputDate name="enddisplaydate" control={control} label="Date de fin d'affichage" />
        <FormInputCheckBox name="supprimable" control={control} label="Bloquer au TG la suppression du picto" />
        <Divider />
        <Typography variant="inherit" color="red">
          {errors?.form?.message}
        </Typography>
        <Button
          onClick={() => {
            const picto = getValues('pictoId');
            if (picto === '') {
              setError('form', {
                type: 'manual',
                message: 'Veuillez sélectionner un picto avant de valider',
              });
            } else {
              clearErrors();
              return handleSubmit(onSubmit)();
            }
          }}
          variant="contained"
          disabled={isSubmitting}
        >
          {isSubmitting ? <CircularProgress color="inherit" size={20} /> : 'Positionner le picto'}
        </Button>
        <Button onClick={() => reset()} variant="outlined" disabled={isSubmitting}>
          Effacer
        </Button>
      </FormProvider>
    </Paper>
  );
};

export default CreateForm;
