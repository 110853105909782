import React, { useEffect, useState } from 'react';
import { FormControl, FormGroup, FormLabel, FormControlLabel, Switch } from '@mui/material';
import { Controller } from 'react-hook-form';


const daysOfWeek = [
  { label: 'Lundi', value: '0' },
  { label: 'Mardi', value: '1' },
  { label: 'Mercredi', value: '2' },
  { label: 'Jeudi', value: '3' },
  { label: 'Vendredi', value: '4' },
  { label: 'Samedi', value: '5' },
  { label: 'Dimanche', value: '6' },
];

const FormInputWeekDays = ({ name, control, label, setValue, value, ...props }) => {
  const [localPeriod, setLocalperiod] = useState(value);

  // Synchronise l'état local avec les props en cas de changement
  useEffect(() => {
    setLocalperiod(value);
  }, [value]);

  const handleSelect = (index) => {
    setLocalperiod((prevState) => {
      let updatedState = prevState.split('');
      updatedState[index] = updatedState[index] === 'X' ? '_' : 'X';
      return updatedState.join('');
    });
  };

  // we are setting form value manually here
  useEffect(() => {
    setValue(name, localPeriod);
  }, [name, localPeriod, setValue]);

  return (
    <FormControl>
      <FormLabel component="legend">{label}</FormLabel>
      <FormGroup row>
        {daysOfWeek.map((day) => {
          return (
            <FormControlLabel
              control={
                <Controller
                  name={name}
                  render={() => {
                    return (
                      <Switch
                        checked={localPeriod[day.value] === "X"}
                        onChange={() => handleSelect(day.value)}
                        {...props}
                      />
                    );
                  }}
                  control={control}
                />
              }
              label={day.label}
              key={day.value}
              sx={{ width: 120 }}
            />
          )
        })}
      </FormGroup>
    </FormControl>
  )

};

export default FormInputWeekDays;
