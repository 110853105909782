/* eslint-disable no-unused-vars */

import {
  Button,
  CircularProgress,
  Divider,
  MenuItem,
  Paper,
  Switch,
  Typography,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import FormSelect from '../../../../components/FormSelect';
import {
  SECTEUR_DONNEE_COMMUNE,
  SECTEUR_DONNEE_SECTEUR,
  setSecteurSelected,
  useSecteurStore,
} from '../../../../stores/secteur';
import { toDecimalString } from '../../../../utils/number';

const defaultValues = {
  secteur: '',
};

const CommuneInfo = ({ dscommune, selected, onPreview, onSecteurSwitchChange, onSubmit }) => {
  const methods = useForm({ defaultValues });
  const {
    handleSubmit,
    formState: { isSubmitting, errors },
    getValues,
    setValue,
    clearErrors,
    setError,
    control,
  } = methods;
  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;

  let name = '';
  const donnee = useSecteurStore((state) => state.donnee);
  const secteurs = useSecteurStore((state) => state.secteurs);

  const [prevSecteur, setPrevSecteur] = useState(
    <CircularProgress color="primary" thickness={6} size={24} />
  );
  const [prevCommune, setPrevCommune] = useState('');
  const [prevDonnee, setPrevDonnee] = useState(donnee);
  const [surface, setSurface] = useState(
    <CircularProgress color="primary" thickness={6} size={24} />
  );
  const [communes, setCommunes] = useState([]);
  const [showSecteurCircuit, setShowSecteurCircuit] = useState(false);

  // title
  if (donnee == SECTEUR_DONNEE_COMMUNE) {
    name = `${selected.get('nom')} (${selected.get('insee_com')})`;
  } else {
    name = selected.get('secteur') !== '' ? `${selected.get('secteur')}` : 'Pas de secteur';
  }

  if (
    selected.get('secteur') !== prevSecteur ||
    selected.get('insee_com') !== prevCommune ||
    donnee !== prevDonnee
  ) {
    if (donnee == SECTEUR_DONNEE_COMMUNE) {
      const surfaceTotalCommune = dscommune
        .filter((d) => d.codeinsee === selected.get('insee_com'))
        .map((d) => parseFloat(toDecimalString(d.surfaceEnqueteBetterave, 2)))
        .reduce((acc, obj) => acc + obj, 0);

      setCommunes([]);
      setSurface(surfaceTotalCommune.toFixed(2));
    } else if (donnee == SECTEUR_DONNEE_SECTEUR) {
      communes.length = 0;
      const surfaceTotalSecteur = dscommune
        .filter((d) => d.secteur === selected.get('secteur'))
        .map((d) => {
          const surface = parseFloat(toDecimalString(d.surfaceEnqueteBetterave, 2));
          communes.push({ nom: d.nom, surface, codeinsee: d.codeinsee });

          return surface;
        })
        .reduce((acc, obj) => acc + obj, 0);

      setCommunes(communes.sort((a, b) => a.nom.localeCompare(b.nom)));
      setSurface(surfaceTotalSecteur.toFixed(2));
    }

    if (selected.get('secteur') !== prevSecteur) {
      setShowSecteurCircuit(false);
    }

    setPrevSecteur(selected.get('secteur'));
    setPrevCommune(selected.get('insee_com'));
    setPrevDonnee(donnee);
  }

  useEffect(() => {
    setSecteurSelected(selected.get('secteur'));
  }, [selected]);

  return (
    <Paper
      style={{
        display: 'grid',
        gridRowGap: '10px',
        padding: '10px',
      }}
    >
      {donnee == SECTEUR_DONNEE_COMMUNE && (
        <>
          <Typography variant="h6">{name}</Typography>
          <Typography variant="body2" component="div">
            <ul style={{ margin: '0 0 10px 0' }}>
              <li>
                Secteur : <strong>{prevSecteur}</strong>
              </li>
              <li>
                Surface (enquête betterave): <strong>{surface}</strong> Ha
              </li>
            </ul>
          </Typography>
          <Divider />
          <FormProvider {...methods}>
            <FormSelect
              name="secteur"
              control={control}
              label="Changer de secteur"
              MenuProps={{
                PaperProps: {
                  style: {
                    maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                  },
                },
              }}
            >
              {secteurs.map((s) => (
                <MenuItem key={s} value={s}>
                  {s}
                </MenuItem>
              ))}
            </FormSelect>
            <Typography variant="inherit" color="red">
              {errors?.form?.message}
            </Typography>
            <Button
              onClick={() => {
                const secteur = getValues('secteur');

                if (secteur !== '' && secteur !== prevSecteur) {
                  clearErrors();

                  // update feature data
                  selected.set('secteur', secteur);

                  // update cached data
                  onPreview(selected.get('insee_com'), selected.get('lieudit'), secteur);
                } else {
                  setError('form', {
                    type: 'manual',
                    message: 'Veuillez choisir un secteur différent',
                  });
                }
              }}
              variant="contained"
            >
              {'Prévisualiser'}
            </Button>
            {/* <Button
              onClick={() => {
                const secteur = getValues('secteur');

                if (secteur !== '' && secteur !== prevSecteur) {
                  delete cache.data[campagne];
                  clearErrors();
                  return handleSubmit(onSubmit)();
                } else {
                  setError('form', {
                    type: 'manual',
                    message: 'Veuillez choisir un secteur différent',
                  });
                }
              }}
              variant="contained"
              disabled={isSubmitting}
            >
              {isSubmitting ? <CircularProgress color="inherit" size={20} /> : 'Valider'}
            </Button> */}
          </FormProvider>
        </>
      )}
      {donnee == SECTEUR_DONNEE_SECTEUR && (
        <>
          <Typography variant="h6">Afficher le circuit du secteur</Typography>
          <Switch
            checked={showSecteurCircuit}
            onChange={(event, checked) => {
              setShowSecteurCircuit(checked);
              onSecteurSwitchChange(checked);
            }}
          />
          <Typography variant="h6">
            {name} {surface} Ha
          </Typography>
          <Typography variant="body2" component="div">
            <ul style={{ margin: '0 0 10px 0' }}>
              {communes.map((c, idx) => (
                <li key={idx}>
                  <strong>
                   {c.nom} ({c.codeinsee}) {c.surface} Ha
                  </strong>
                </li>
              ))}
            </ul>
          </Typography>
        </>
      )}
    </Paper>
  );
};

export default CommuneInfo;
