import React, { useState } from 'react';
import { setLayerSelectorOpened, useLayerStore } from '../../stores/layers';
import {
  AppBar,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Fab,
  IconButton,
  Stack,
  Toolbar,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';

import { useLayers } from '../../hooks';
import * as features from '../../features';
import can from '../../utils/duckTyping';
import { getViewExtent } from '../../stores/view';
import CloseIcon from '@mui/icons-material/Close';

const LayerSelectorDialog = () => {
  const { vectorLayers } = useLayers();
  const keys = Object.keys(features);
  const open = useLayerStore((state) => state.selectorOpened);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
  const [layerToShow, setLayerToShow] = useState([]);
  const [layerToHide, setLayerToHide] = useState([]);

  const onClose = (event, reason) => {
    if (reason == 'escapeKeyDown' || reason == 'backdropClick') {
      handleClose();
    } else {
      return;
    }
  };

  const handleClose = () => {
    layerToShow.forEach((key) => features[key].show(getViewExtent()));
    layerToHide.forEach((key) => features[key].hide());

    setLayerSelectorOpened(false);
    setLayerToShow([]);
    setLayerToHide([]);
  };

  return (
    <Dialog open={open} fullScreen={fullScreen} scroll="paper" onClose={onClose}>
      {fullScreen && (
        <AppBar sx={{ position: 'relative' }}>
          <Toolbar>
            <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close">
              <CloseIcon />
            </IconButton>
            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
              Couches de données
            </Typography>
          </Toolbar>
        </AppBar>
      )}
      {!fullScreen && <DialogTitle>Couches de données</DialogTitle>}
      <DialogContent dividers={true}>
        {['Campagne', 'Navigation', 'Commune'].map((group, indexGroup) => {
          return (
            <Box key={group}>
              {indexGroup > 0 && (
                <>
                  <Divider sx={{ margin: '20px 0 10px' }} />
                  <Typography sx={{ marginBottom: '10px' }}>{group}</Typography>
                </>
              )}
              <Stack direction="row" spacing={1} useFlexGap sx={{ flexWrap: 'wrap' }}>
                {vectorLayers.map((l) => {
                  return keys
                    .filter((key) => {
                      const featureGroup = features[key].props.group;
                      return featureGroup == group;
                    })
                    .map((key) => {
                      if (features[key].id === l.get('id')) {
                        const layerVisible =
                          (l.get('visible') || layerToShow.indexOf(key) >= 0) &&
                          layerToHide.indexOf(key) < 0;

                        return (
                          <Fab
                            sx={{ textTransform: 'none' }}
                            key={l.get('id')}
                            variant="extended"
                            size="small"
                            color={layerVisible ? 'secondary' : 'primary'}
                            onClick={() => {
                              if (layerVisible) {
                                if (layerToShow.indexOf(key) >= 0) {
                                  setLayerToShow(layerToShow.filter((k) => k != key));
                                } else {
                                  setLayerToHide([...layerToHide, key]);
                                }
                              } else if (!l.get('visible') && can(features[key], 'show')) {
                                if (layerToHide.indexOf(key) >= 0) {
                                  setLayerToHide(layerToHide.filter((k) => k != key));
                                } else {
                                  setLayerToShow([...layerToShow, key]);
                                }
                              }
                            }}
                          >
                            {l.get('name')}
                          </Fab>
                        );
                      }
                    });
                })}
              </Stack>
            </Box>
          );
        })}
      </DialogContent>
      {!fullScreen && (
        <DialogActions>
          <Button autoFocus onClick={handleClose}>
            Fermer
          </Button>
        </DialogActions>
      )}
    </Dialog>
  );
};

export default LayerSelectorDialog;
